import React from 'react'
import { connect } from "react-redux";
import moment from 'moment'
import { Result } from 'antd';
import { Link } from 'react-router-dom';

import { E404 } from 'Layout_v1'
// import { CheckoutSuccess } from "Layout_v1/Pages";
import { DevBlock, Button } from 'Common/components';
import { convertTo12Hrs, mongoToDate } from 'Common/scripts/Functions'


export const SuccessContainer = props => {
    React.useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        //   return () => { };
    })

    const { timeslot, address } = props.order;
    // const timeslot = { 
    //     date: "2022-09-28T14:53:27+05:00",
    //     day: "wed",
    //     start_time: 2300,
    //     end_time: 0,
    //     time_range_utc: JSON.parse(`["2022-03-05T23:00:00Z","2022-03-06T00:00:00Z"]`)
    // }

    let time_range_utc = timeslot ? JSON.parse(timeslot.time_range_utc) : "";
    
    let target_date = timeslot ? mongoToDate(timeslot.date).format('DD-MM-YYYY') : "";
    let start_time = timeslot ? mongoToDate(time_range_utc[0]).format('HHmm') : "";
    let end_time = timeslot ? mongoToDate(time_range_utc[1]).format('HHmm') : "";

    return (
        <Result
            status="success"
            title="Your Order Has Been Submitted Successfully"
            subTitle={<span style={{ color:"#3D3D3D"}}>Thank you for your order you will be informed before the delivery at your selected time slot 
                {timeslot && (<b> {target_date} - {start_time} - {end_time}</b> )}
                {/* {timeslot && ( <b> {moment(timeslot.date).format('MMMM Do YYYY')} - {convertTo12Hrs(timeslot.start_time)} - {convertTo12Hrs(timeslot.end_time)}</b> )} */}
            </span>}
            extra={[
                <div key={1}><img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/order-conformation.png`} width="200" /></div>,
                <Button key={2} size="large"><Link to="/">Home</Link></Button>,
            ]}
        />
    )

    // return (
    //     <div>
    //         {/* <CheckoutSuccess timeslot={`( Monday 13:00 - 15:00 )`} /> */}
    //         <CheckoutSuccess timeslot={`( ${moment(timeslot.date).format('MMMM Do YYYY')} - ${convertTo12Hrs(timeslot.start_time)} - ${convertTo12Hrs(timeslot.end_time)} )`} />
    //         <DevBlock obj={props.order} />
    //     </div>
    // )
}


const mapStateToProps = state => {
    return ({ order: state.order, user: (state.user && state.user.user) || false });
}
export default connect(mapStateToProps)(SuccessContainer);
// export default SuccessContainer;
