import React from 'react';
import { addModule, addReducers } from '../connector';
// import Home from './Home';
import List from './containers/orders_list';
// import List from './containers/list2';
// import {Icon} from 'common/components'


export const ROOT = '/testing';
export const MODULE = 'TESTING';

// export const SubMenu = props => ([
//   { path: `${ROOT}/countries`, exact: true, title: 'Countries', component: Countries },
//   { path: `${ROOT}/agreement`, exact: true, title: 'Agreement', component: Agreement },
//   { path: `${ROOT}/tags`, exact: true, title: 'Tags', component: Tags },
// ])

// addReducers({ admins: reducers });
addModule(
  {
    path: `${ROOT}`, component: List, title: "Testing", exact: 0, hideNav: 0,
    // subMenu: SubMenu()
  },
)
