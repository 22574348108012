import React from 'react';
import { addModule, addReducers } from '../connector';
import Home from './Home';
// import UserProfile from './containers/user-profile';
import reducers from './redux/reducer';
import { ROOT, MODULE } from './constants'


addReducers(reducers, { whitelist: ['user'] });
addModule(
    { path: `${ROOT}`, component: Home, title: "User", exact: 0, hideNav: 1 },
)
// addModule([
//     { path: `${ROOT}`, component: Home, title: "User", exact: 0, hideNav: 1 },
//     { path: `/profile`, component: UserProfile, title: "UserProfile", exact: 1, hideNav: 1 },
//     ]
// )
