import React from 'react'
import { loader } from 'graphql.macro';
import { graphql, withApollo } from 'react-apollo';
import compose from 'lodash.flowright';
import { __error } from 'Common/scripts/consoleHelper'
import Home from '../components/Home';
import { Loader } from 'Common/components'

const RECORD = loader('src/graphqls/page_settings/pageSettingByKey.graphql');


export const MainPage = props => {
  const { loading, pageSettingByKey } = props;

  var pageConfigs = {
    homePageHeader: { banners: [{}, {}, {}] },
    // topBanner: `${process.env.REACT_APP_TEMPLATE_ASSETS}/banner1.png`,
    // midBanner: `${process.env.REACT_APP_TEMPLATE_ASSETS}/banner1.png`,
    // bottomBanner: `${process.env.REACT_APP_TEMPLATE_ASSETS}/banner1.png`,
    // featuredProducts: { title: "Featured Products", data: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}] },
    // topProducts: { title: "BBQ Top Picks", data: [{}, {}, {}, {}, {}, {}, {}] },
  }

  if (pageSettingByKey && pageSettingByKey.banners)
    pageConfigs = Object.assign(pageConfigs, { banners: pageSettingByKey.banners });
  if (pageSettingByKey && pageSettingByKey.products)
    pageConfigs = Object.assign(pageConfigs, { products: pageSettingByKey.products });

  return (<>
    {/* {loading && <p>Loading page setting</p>} */}
    {loading && <Loader loading={true} />}
    <Home {...pageSettingByKey} loading={loading} />
  </>)
}


const WithApollo = compose(
  graphql(RECORD, {
    options: () => {
      return { 
        variables: { key: "home-page" },
        fetchPolicy: "no-cache", //no-cache, cache-first, network-only, cache-only, cache-and-network
      };
    },
    props: ({ ownProps, data }) => {
      const { loading, pageSettingByKey, error } = data;

      if (error) console.log(__error("error"), error);
      
      return { loadingEditNode: loading, pageSettingByKey, queryErrors: error, }
    },
  }),
)(MainPage);

export default WithApollo;