import React from 'react'
import { Icon, Button, Avatar } from 'Common/components'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export const ProductGalleryItem = props => {
    // console.log("ProductGalleryItem.props: ", props);
    const link = "/"

    return (
        <div className={`product-gallery-item-wrapper ${props.className}`}>
            <div className="product-gallery-item">
                <div className="li-img" onClick={() => props.onClick(props)}>
                    <Avatar style={{ zIndex: 0 }} shape="square" size={65} src={`${process.env.REACT_APP_DATA_URL}/${props.thumb}`} />
                </div>
            </div>
        </div>
    )
}

ProductGalleryItem.propTypes = {
    onClick: PropTypes.func.isRequired,
    // labelA: PropTypes.oneOfType([
    //             PropTypes.string,
    //             PropTypes.object
    //         ]).isRequired,
}
export default ProductGalleryItem;
