import React, { Component } from 'react'
// import { graphql } from '@apollo/client/react/hoc';
import { graphql, withApollo } from 'react-apollo';

import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { connect } from "react-redux";

import { Button, Icon } from 'Common/components'
import { FormComponent, FormField, rules, composeValidators } from 'Common/components/Form'
import { message, Alert, Row, Col } from 'antd'
import { __error } from 'Common/scripts/consoleHelper';
import { couponSelect } from '../redux/actions'

const VALIDATE_VOUCHER = loader('src/graphqls/discount_voucher/validateVoucher.graphql');


const _CouponDiscountForm = props => {
    const [loading, setLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);

    if (!props.user || !props.user._id) return <div>Invalid User</div>

    const onSubmit = values => {
        setLoading(true);
        setErrorMessage(null);

        props.validateVoucher({ code: values.coupon_code, customer_id: props.user._id })
            .then(r => {
                // console.log("validateVoucher: ", r.data.validateVoucher);
                setLoading(false)
                // if (r.data.validateVoucher.error) {
                //     // message.error(r.data.validateVoucher.error.message)
                //     setErrorMessage(r.data.validateVoucher.error.message)
                //     return;
                // }
                handelResponse(r.data.validateVoucher);
                // props.onSuccess(r.data.validateVoucher);
                return r.data.validateVoucher;
            })
            .catch(err => {
                console.log(__error(""), err);
                message.error("Unable to complete your request at the moment.")
                setLoading(false)
            })

    }

    const handelResponse = async (res) => {
        console.log("handelResponse() ", res);

        const { error } = res;
        props.couponSelect(res);

        if (!res) {
            setErrorMessage("Empty response!"); return;
        }
        if (error) {
            setErrorMessage(error.message || "No response Data available"); return;
        }

    }


    return (<>
        <FormComponent onSubmit={onSubmit} id='ProdCatForm' loading={loading} hideDevBlock={true} style={{ width: "350px" }}
            form_render={formProps => {
                const { values, dirty, errors } = formProps;

                return (<>
                    {errorMessage && <Alert showIcon type="error" message={errorMessage} description={null} />}

                    <Row>
                        <Col flex="auto">
                            <FormField type="text" wrapperStyle={{ margin: "0 0 5px 0" }}
                                prefix={<Icon icon={props.order.coupon ? "check" : "times"} color={props.order.coupon ? "#60A52C" : "red"} />}
                                label="Got a Discount Coupon!" placeholder="Enter the coupon code to reveal advantages" name="coupon_code"
                                validate={composeValidators(rules.required, rules.minChar(4))} />
                        </Col>
                        <Col>
                            <label>&nbsp;</label>
                            <Button type="primary" htmlType="submit" theme="gray" size="large" disabled={(!dirty || Object.keys(errors).length > 0)}>Reveal</Button>
                        </Col>
                    </Row>

                    {/* <FormField type="text" wrapperStyle={{ margin: "0 0 5px 0" }}
                        prefix={<Icon icon={props.order.coupon ? "check" : "times"} color={props.order.coupon ? "#60A52C" : "red"} />}
                        label="Got a Discount Coupon!" placeholder="Enter the coupon code and reveal advantages" name="coupon_code"
                        validate={composeValidators(rules.required, rules.minChar(4))} /> */}

                    {/* <div style={{ textAlign: "right" }}>
                        <Button type="primary" htmlType="submit" theme="gray" size="large" disabled={(!dirty || Object.keys(errors).length > 0)}>Reveal Couopon</Button>
                    </div> */}
                </>)
            }}
        />

        {/* <Button className="gray" size="large" disabled>Reveal Couopon</Button> */}

    </>)
}
const CouponDiscountForm = compose(
    graphql(VALIDATE_VOUCHER, {
        props: ({ mutate }) => ({
            validateVoucher: (args) => mutate({
                variables: { ...args }
            }),
        })
    }),
)(_CouponDiscountForm);

const mapDispatchToProps = (dispatch, ownProps) => ({
    couponSelect: (payload) => dispatch(couponSelect(payload)),
})
const mapStateToProps = state => {
    return ({ order: state.order, user: (state.user && state.user.user) || false });
}
export default connect(mapStateToProps, mapDispatchToProps)(CouponDiscountForm);
// export default CouponDiscountForm;