import React, { Component } from 'react'
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { Row, Col, Modal, message, Button } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { __error } from 'Common/scripts/consoleHelper'
import { Table } from 'Common/components';
import { getTimeDifference, mongoToDate } from 'Common/scripts/Functions'
import { defaultPageSize } from 'configs'
import { ROOT, MODULE } from '../constants'
import { E404 } from "Layout_v1";

const LIST_DATA = loader('src/graphqls/order/query_all.graphql');


export class OrdersList extends Component {
    state = {
        pagination: { current: 1, pageSize: defaultPageSize, },
        // filter: defaultFilter,
        busy: false,
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.loading && !nextProps.loading) {
            this.setState({
                pagination: {
                    ...this.state.pagination,
                    total: nextProps?.ordersQuery?.totalCount || 0
                }
            })
        }

    }

    columns = [
        { title: 'Order', dataIndex: 'serial', render: (text, row) => {
            let disable_reorder = !['refunded', 'declined', 'canceled', 'delivered', 'completed'].includes(row.status);

            return (<Row align="middle" className='nowrap'>
                <Col flex="auto">
                    <div><b>Order #</b> <Link to={`${ROOT}/order/${text}`}>{text}</Link> (<b>{String(row.status).toUpperCase()}</b>)</div>
                    <div><b>Date:</b> {mongoToDate(row.created_at).format("ddd Do MMM YYYY ~ hh:mm A")}</div>
                    <div><b>Total items:</b> {row?.items?.length || "0"}</div>
                    <div><b>Total amount: </b>{this.props.currency} {row.grandTotal}</div>
                </Col>
                <Col flex="100px"><Button disabled={disable_reorder}><Link to={`${ROOT}/re-order/${row.serial}`}>Re-Order</Link></Button></Col>
            </Row>)
        } },
        // { title: 'Date', dataIndex: 'created_at', align: "center", render: (text, row) => (mongoToDate(text).format("Do MMM YYYY ~ hh:mm A")), width:'170px' },
        // { title: 'Items', dataIndex: 'items', align: "center", width: '70px', render:(__, rec) => (rec?.items?.length || "0") },
        // { title: 'Total', dataIndex: 'grandTotal', align: "center", width: '100px' },
        // { title: 'Status', dataIndex: 'status', align: "center", width: '100px' },
        // {
        //     title: ' ', dataIndex: 'actions', align: "center", width: '120px', render: (text, row) => {
        //         return (<>
        //             <Button><Link to={`${ROOT}/re-order/${row.serial}`}>Re-Order</Link></Button>
        //         </>)
        //     }
        // },
    ];

    handleTableChange = (pagination, filters, sorter) => {
        this.setState({
            ...this.state,
            busy: true,
            pagination: {
                ...this.state.pagination,
                pageSize: pagination.pageSize,
                current: pagination.current
            }
        })
        this.props.loadMoreRows(
            pagination.pageSize,
            pagination.pageSize * (pagination.current - 1),
            JSON.stringify({ "customer._id": this.props.user._id }),
        ).then(r => {
            this.setState({ busy: false })
            return r;
        })
    };


    render() {
        const { loading, ordersQuery, queryErrors } = this.props;
        const { pagination, busy } = this.state;

        return (<>
            <h4>Orders ({ordersQuery && ordersQuery.totalCount || 0})</h4>

            <Table loading={loading || busy}
                columns={this.columns}
                dataSource={ordersQuery ? ordersQuery.edges : null}
                pagination={pagination}
                onChange={this.handleTableChange}
            />
        </>)
    }
}



const WithApollo = compose(

    graphql(LIST_DATA, {
        options: props => {
            return { 
                // variables: { first: defaultPageSize, after: 0, filter: JSON.stringify({ "customer._id": props.user._id }) },
                variables: { first: defaultPageSize, after: 0, filter: JSON.stringify({}) },
                // fetchPolicy: "no-cache", //no-cache, cache-first, network-only, cache-only, cache-and-network
                notifyOnNetworkStatusChange: true,
            };
            // return { variables: { first: defaultPageSize, after: 0, filter: JSON.stringify({ "customer._id": props.user._id || 0 }) } };
        },
        props: ({ ownProps, data }) => {
            const { loading, ordersQuery, error, fetchMore, subscribeToMore } = data;

            if (error) console.log(__error("error"), error);

            const loadMoreRows = (count, startFrom, filter, others) => {

                let vars = { first: count, after: startFrom, filter: filter, others: others };

                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    const totalCount = fetchMoreResult.ordersQuery.totalCount
                    const newEdges = fetchMoreResult.ordersQuery.edges
                    const pageInfo = fetchMoreResult.ordersQuery.pageInfo
                    return {
                        // By returning `cursor` here, we update the `fetchMore` function to the new cursor.
                        ordersQuery: {
                            totalCount,
                            // edges: [...previousResult.branchesQuery.edges, ...newEdges],
                            edges: newEdges,
                            pageInfo,
                            __typename: 'OrderQuery'
                        }
                    }
                }

                return fetchMore({ variables: vars, updateQuery: updateQuery });
            }

            return { loading, ordersQuery, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),

)(OrdersList);


// const ModuleCheck = props => {
//     if(!props.user || !props.user._id) return <Redirect to="/" />
//     return <WithApollo {...props} />
//     // return withApollo(WithApollo)
// }

const ModuleCheck = (props) => {
    if (!props.user || !props.user._id) return <E404 />
    return <WithApollo {...props} />
}
const mapStateToAvatarPopProps = state => {
    return ({ 
        currency: state.box_storefront.currency,
        user: (state.user && state.user.user) || {} 
    });
}
const WithRedux = connect(mapStateToAvatarPopProps)(ModuleCheck);
export default WithRedux;
