import React from 'react'
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { Divider, message, Alert } from 'antd';
import axios from 'axios';
import { connect } from "react-redux";
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { __error } from 'Common/scripts/consoleHelper'
import { mergeCarts } from 'Common/scripts/Functions';
import { Icon, Button, Loader } from 'Common/components'
import { FormComponent, FormField, rules, composeValidators } from 'Common/components/Form'
import { loginUser, logoutUser } from '../redux/actions'
import GetLoggedInUserData from '../containers/GetLoggedInUserData';
import PhoneVerification from '../containers/PhoneVerification'

const MERGE_CART = loader('src/graphqls/cart/mergeCart.graphql');



export const SignInForm = props => {
    const [loading, setLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [doAutologin, setDoAutologin] = React.useState(false);
    const [loggedInUser, setLoggedInUser] = React.useState(false);

    React.useEffect(() => {
        // console.log("handelResponse: ", handelResponse);
        if (props.autoLoginInfo){
            setDoAutologin(props.autoLoginInfo)
            onSubmit(props.autoLoginInfo);
        }
        
        return () => {
            return false;
        };
    }, [props.autoLoginInfo])


    const handelResponse = async (res) => {
        console.log("handelResponse() ", res);
        // {TokenData:response.data.TokenData, LoggedUser:response.data.LoggedUser}
        // const { description, status, email, name, password, type, _id } = res.LoggedUser;

        if (!res) {
            setLoading(false);
            setErrorMessage("Empty response!")
            return;
        }
        if (res.error || res.warning || !res.data) {
            setLoading(false)
            setErrorMessage(res.error || res.warning || "No response Data available")
            return;
        }

        const { error, status, TokenData, LoggedUser } = res.data;

        if (error) {
            setLoading(false)
            setErrorMessage(error.message)
            return;
        }

        if (status == 'LoginFailed') {
            setLoading(false)
            setErrorMessage("Login fail")
            return;
        }

        if (status != 'LoggedIn') {
            setLoading(false)
            setErrorMessage("User not logged in")
            return;
        }

        if (TokenData && LoggedUser) {
            // if (LoggedUser.user.type !== 'super-admin' && (!LoggedUser.rights || LoggedUser.rights.length < 5)) {
            //     this.setState({ error: "You have no rights!", busy: false });
            //     return false;
            // }

            if (props.cart.items && props.cart.items.length > 0) {
                let mearged = await mergeCarts(props.cart, LoggedUser.user._id, props.mergeCart);
                if (!mearged || mearged.error){
                    setLoading(false);
                    setErrorMessage(mearged.error ? mearged.error.message : "Unable to merge carts.");
                    console.log(__error("ERROR: Unable to merge carts."));
                    return;
                }
            }

            // message.success("Login successful");
            // props.loginUser({ token: TokenData, user: LoggedUser.user });
            // setLoading(false);
            if (props.onSuccessCallback){
                // props.loginUser({ token: TokenData, user: LoggedUser.user });
                props.onSuccessCallback({ token: TokenData, user: LoggedUser.user });
            }
            else setLoggedInUser({ token: TokenData, user: LoggedUser.user });
            // if (props.onCancel) props.onCancel();
            // return;
        }
        // setLoading(false)
    }

    const onSubmit = values => {
        let filteredValues = { username: values.username, pwd: values.pwd, app:"client" };
        setLoading(true);
        setErrorMessage(null);

        axios({
            method: 'put',
            url: process.env.REACT_APP_API_URL + '/user/login',
            data: { ...filteredValues }
        })
        .then(async function (response) {
            console.log("RESPONSE: ", response);
            handelResponse(response);
        })
        .catch(function (error) {
            console.log("ERROR: ", error);
            handelResponse({ error: { message: 'Netowrk Error' } });
        });
    }

    if (props.autoLoginInfo){
        if (!loggedInUser && doAutologin) return (<>
            <Loader loading={true}><p style={{ padding: "50px" }}>Logging in</p></Loader>
            {errorMessage && <Alert message={errorMessage} type="error" showIcon />}
        </>)
        
        return (<>
            <Loader loading={true}><p style={{ padding: "50px" }}>Requesting auto login</p></Loader>
            {errorMessage && <Alert message={errorMessage} type="error" showIcon />}
        </>)
    }

    // return <div>test</div>



    // if (props.autoLoginInfo && !loggedInUser && !errorMessage){
    //     return <Loader loading={true}>Logging in</Loader>
    // }
    // if (props.autoLoginInfo && loggedInUser && !errorMessage){
    //     props.loginUser(loggedInUser);
    // }


    if (props.autoLoginInfo && !errorMessage && !loggedInUser){
        // if (!loggedInUser) onSubmit(props.autoLoginInfo);
        // return <Loader loading={true}>{loggedInUser ? "Collecting user data" : "Loggin in"}</Loader>
    }
    // else if (loggedInUser)(
    //     props.loginUser(loggedInUser);
    // )


    // if (props.autoLoginInfo) {
    //     if (!doAutologin) {
    //         onSubmit(props.autoLoginInfo);
    //         setDoAutologin(props.autoLoginInfo);
    //     }
    //     // if (!props.user && !loading){
    //     //     onSubmit(props.autoLoginInfo);
    //     // }

    //     return <div>
    //         <Loader loading={true}>Loggin in</Loader>
    //     </div>
    //     // return <div>{JSON.stringify(props.autoLoginInfo)}</div>
    // }

    // return <div>auto sign active</div>

    // if ((!props.user || !props.user._id) && props.doAutologin && !loading){
    //     return <div>Do auto login</div>
    // }

    // if (!props.user && props.doAutologin && !doAutologin && !loading) {
    //     setDoAutologin(props.doAutologin);
    //     onSubmit(props.doAutologin);
    //     return;
    // }
    
    // if (!props.user && doAutologin && loading) return <Loader loading={true}>Loggin in</Loader>
    
    if (props.user && loading) return <GetLoggedInUserData onSuccess={() => setLoading(false)} />
    if (props.user && !loading) return <PhoneVerification onCancel={props.onCancel} />

    
    // if (props.user && !loading) return <div>You are already logged in</div>
    // if (props.user && !loading) return <Redirect to={props.redirectTo || props.user.phone_confirmed<1 ? "/user/verify" : "/"} />

    // return <div>testing</div>


    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign:"left", ...props.style }}>
            <div style={{ maxWidth: "350px", width:"100%" }}>
                <FormComponent onSubmit={onSubmit} id='ProdCatForm' loading={loading} hideDevBlock={true} style={{ width: "100%" }}>
                    <h3>Sign in</h3>
                    {errorMessage && <Alert showIcon type="error" message={errorMessage} description={null} /> }

                    <FormField type="text" name="username" wrapperStyle={{ margin: "15px 0" }} label="Email" validate={composeValidators(rules.required, rules.isEmail)} />
                    <FormField type="password" name="pwd" wrapperStyle={{ margin: "15px 0" }} label="Password" validate={composeValidators(rules.required, rules.minChar(4))} />

                    <p align="right"    >
                        <Link to="/user/forgot-password" onClick={props.onCancel} style={{ color:"#0073b1"}}>Forgot password?</Link>
                    </p>


                    <div style={{ textAlign: "right" }}><Button type="primary" htmlType="submit" theme="blue" block size="large">Sign in</Button></div>
                </FormComponent>

                {!props.hideRegister && <>
                    {/* <Divider style={{margin:"15px 0 10px 0"}} />
                    <div style={{marginBottom:"10px"}}>
                        <b>New customer?</b><br />
                        Registering is quick and easy
                    </div>
                    <Button type="primary" theme="blue" block size="large"><Link to="/user/register">Register</Link></Button> */}
                    {/* <div><h3>Not a member yet? &nbsp; <Link to="/user/register" className="green-link">Create an account</Link> &nbsp; here.</h3></div> */}
                </>}

            </div>
        </div>
    )
}

SignInForm.propTypes = {
    autoLoginInfo: PropTypes.object,
    onSuccessCallback: PropTypes.func.isRequired,
}


const WithApollo = compose(
    graphql(MERGE_CART, {
        props: ({ mutate }) => ({
            mergeCart: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
)(SignInForm);


const mapStateToProps = state => {
    // console.log("state: ", state);
    return ({ user: (state.user && state.user.user) || false, cart:state.cart });
    // return {}
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    loginUser: (payload) => dispatch(loginUser(payload)),
    logoutUser: (payload) => dispatch(logoutUser(payload)),
})
export default connect(mapStateToProps, mapDispatchToProps)(WithApollo);

// export default SignInForm;