import React from 'react'
import { Route, Switch } from 'react-router-dom';
import { E404, LayoutWrapper } from "Layout_v1";
import RegisterPage from './containers/register';
import RegisterSuccess from './containers/register-success';
import SignInPage from './containers/sign-In';
import ProfilePage from './containers/ProfilePage';
import OrdersList from './components/order-list';
import UserOrderDetails from './components/user-orders-details';
import ReOrder from './components/re-reorder';
import PhoneVerification from './containers/PhoneVerification';
import ForgotPassword from './containers/ForgotPassword';
import { ROOT, MODULE } from './constants'
import 'Layout_v1/styles/home.scss'




const menuArray = props => ([
    // { path: `${ROOT}`, exact: true, title: 'Innter Home', component: MainPage},
    { path: `${ROOT}/register`, exact: true, title: 'RegisterPage', component: RegisterPage},
    { path: `${ROOT}/register/success`, exact: true, title: 'RegisterSuccess', component: RegisterSuccess},
    { path: `${ROOT}/verify`, exact: true, title: 'PhoneVerification', component: PhoneVerification},
    { path: `${ROOT}/sign-in`, exact: true, title: 'SignInPage', component: SignInPage},
    { path: `${ROOT}/forgot-password/:step?`, exact: true, title: 'Forgot Password', component: ForgotPassword},
    // { path: `${ROOT}/orders`, exact: true, title: 'UserOrders', component: UserOrders},
    // { path: `${ROOT}/order/:serial`, exact: true, title: 'UserOrderDetails', component: UserOrderDetails},
    // { path: `${ROOT}/profile`, exact: false, title: 'UserProfile', component: ProfilePage},
    // { path: `${ROOT}/order-history`, exact: false, title: 'OrderHistory', component: OrderHistory},
]);

export const Home = props => {
    let routs = menuArray();

    // if (!props.user || !props.user._id) return <E404 />

    return (
        <LayoutWrapper topMenu={null} menuOverride={null} className={String(MODULE).toLowerCase()}>
            <Switch>
                <Route exact={true} path={`${ROOT}/my-orders`} render={() => (<OrdersList />)} />
                <Route exact={true} path={`${ROOT}/order/:serial`} component={UserOrderDetails} />
                <Route exact={true} path={`${ROOT}/re-order/:serial`} component={ReOrder} />
                <Route exact={false} path={`${ROOT}/profile`} component={ProfilePage} />
                {/* {path: `${ROOT}/profile`, exact: false, title: 'UserProfile', component: ProfilePage} */}
                {routs.map((item, i) => {
                    if (!item.component) return null;
                    return <Route exact={item.exact} path={item.path} component={item.component} key={i} />
                })}
                <Route key={999} component={E404} />
            </Switch>
        </LayoutWrapper>
    )
}

// const mapStateToAvatarPopProps = state => {
//     return ({ user: state.user && state.user.user || {} });
// }
// export default connect(mapStateToAvatarPopProps)(Home);

export default Home;
