import React from 'react'
import PropTypes from 'prop-types';
import moment from 'moment';
import { Timeline } from 'antd';
import { Heading, Icon } from 'Common/components'
import { getTimeDifference, mongoToDate, sortArrayByVal } from 'Common/scripts/Functions'

function getConditionalStyle(note) {
    let style = {  };
    if (note.indexOf("Status Updated") > -1) style = Object.assign(style, { fontWeight:"bold"});
    return style
}
function getConditionalIcon(note) {
    if (String(note).toLowerCase().indexOf("status updated to collection") > -1)
        return <Icon icon="dolly" />

    if (String(note).toLowerCase().indexOf("status updated to dispatched") > -1)
        return <Icon icon="shipping-fast" />

    if (String(note).toLowerCase().indexOf("status updated to completed") > -1)
        return <Icon icon="check" color="green" />

    if (String(note).toLowerCase().indexOf("status updated to delivered") > -1)
        return <Icon icon="check" />

    if (String(note).toLowerCase().indexOf("status updated to canceled") > -1)
        return <Icon icon="times" color="red" />

    if (String(note).toLowerCase().indexOf("status updated to refunded") > -1)
        return <Icon icon="donate" color="orange" />

    if (String(note).toLowerCase().indexOf("status updated to pending") > -1)
        return <Icon icon="clock" />

    return null; // <Icon icon="check" />
}

export const RenderHistory = props => {

    const status_notes = sortArrayByVal(props.order.status_notes, 'created_at', 'desc');

    return (<>
        <div className="grid-block">
            <Heading>Order History</Heading>
            <div style={{ padding: "0 10px" }}>
                <Timeline>
                    {/* <Timeline.Item dot={<Icon icon="plus" />} color="blue"><StatusForm order={props.order} /></Timeline.Item> */}
                    {status_notes.reverse().map((item, i) => {
                        return <Timeline.Item key={i} style={getConditionalStyle(item.note)} dot={getConditionalIcon(item.note)}>
                            {/* <span style={{color:"#999"}}>{moment(item.created_at).format("YYYY-MM-DD HH:mm")}</span> */}
                            <span style={{color:"#999"}}>{mongoToDate(item.created_at).format("YYYY-MM-DD HH:mm")}</span>
                            <div><Icon icon="comment" style={{ color: "#ccc" }} /> {item.note}</div>
                        </Timeline.Item>
                    })}
                    <Timeline.Item dot={<Icon icon="shopping-cart" />} color="blue">
                        {/* <span style={{ color: "#999" }}>{moment(props.order.created_at).format("YYYY-MM-DD HH:mm")}</span> */}
                        <span style={{ color: "#999" }}>{mongoToDate(props.order.created_at).format("YYYY-MM-DD HH:mm")}</span>
                        {/* <div>Order placed {getTimeDifference({ before: props.order.created_at })}</div> */}
                        <div>Order placed {mongoToDate(props.order.created_at).fromNow()}</div>
                    </Timeline.Item>
                </Timeline>
            </div>
        </div>
    </>)
}

RenderHistory.propTypes = {
    // onClose: PropTypes.func.isRequired,
    order: PropTypes.object.isRequired,
}

export default RenderHistory;
