import React from 'react'
import { connectSearchBox } from 'react-instantsearch-dom';
import { SearchField } from 'Common/components/Form';


const SearchBox = ({ currentRefinement, isSearchStalled, refine, style }) => (
    <div style={{ width: "100%", paddingLeft: 20, ...style }}>
        <SearchField enterButton style={{ maxWidth: "800px", width: "100%" }} size="large"

            onSearch={text => { refine(text); }}
            onChange={event => { 
                refine(event.currentTarget.value);
            }}
            defaultValue={currentRefinement}
            value={currentRefinement}

            name="keywords" placeholder="Search 5000+ products..." />
    </div>
);

export const AlgoliaSearchBox = connectSearchBox(SearchBox);
