import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Avatar, Button, NotifyMeButton } from 'Common/components'
import AddToCartButton from './AddToCartButton'
import { connect } from "react-redux";

import { useMediaQuery } from 'react-responsive'
import { isMobile, isTablet, withOrientationChange } from 'react-device-detect';
import { __error } from 'Common/scripts/consoleHelper';


export const ProductListItem = props => {
    const { prod, settings } = props;
    const isSmallDevice = useMediaQuery({ query: '(max-device-width: 365px)' })
    // const isTabletOrMobileDevice = useMediaQuery({ query: '(max-device-width: 365px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const _isMobile = (isMobile || isSmallDevice) && !isTablet;

    if (!prod) {
        console.log(__error("Product not found"), prod)
        return null;
    }


    const attributes_v2 = prod.attributes_v2 ? prod?.attributes_v2?.filter(o => o.show_in_store>0) : [];

    const { currency } = settings;

    if (!prod || !prod._id) {
        console.log("Invalid product > props: ", props)
        return (
            <div className={`product-list-item-wrapper ${props.className}`} style={props.style}>
                <div className="product-list-item">Invalid Product</div>
            </div>
        )
    }

    let was = (prod.price_was && prod.price_was > prod.price) ? <div className="price-was">{currency} {prod.price_was}</div> : null;
    let link = "/product/" + prod.slug;

   
    return (<div className={`product-list-item-wrapper ${props.className} ${_isMobile ? "isMobile" : ""} ${_isMobile && isPortrait ? "isPortrait" : ""}`} style={props.style}>
        {/* <p>{prod._id}</p> */}
        <div className={`product-list-item`}>
            <div className="li-prod-header">
                <div className="li-img"><Link to={link}>
                    {prod.available_qty < 1 && <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', position: 'absolute', width: '100%', top: '-0px', left: '-0px', height: '135px', zIndex: 1, display: 'flex', alignItems: "center", justifyContent: "center" }}>
                        <div style={{ backgroundColor: '#CCC', borderRadius: '10px', margin: '10px', fontSize: '18px', padding: '0px 10px' }}>Sold Out</div>
                    </div>}
                    <Avatar shape="square" size={130} src={prod.picture_thumb ? `${process.env.REACT_APP_DATA_URL}/${prod.picture_thumb}` : null} style={{ zIndex: 0 }} />
                </Link>
                </div>
                <div className="attributes">{attributes_v2 && attributes_v2.map((o, i) => (<span key={i}>{i > 0 && ', '}{o.value}{o.code}</span>))}</div>
            </div>
            <div className="li-prod-body">
                <div className="li-details">
                    {/* {attributes && <div className="attributes">{attributes}</div>} */}
                    <div className="name"><Link to={link}>{prod.title}</Link></div>
                    {/* <div className="special-attributes"><ProductSpecialAttribute type="7-days-typical-life" /></div> */}
                </div>
                <div className="li-footer">
                    {was && <div className="price-cut">Price Cut</div>}
                    <div className="li-price">{was}{currency} {prod.price}</div>
                    { prod.available_qty > 0 && <div className="li-order-button"><AddToCartButton prod={prod} /></div>}
                    {prod.available_qty < 1 && <NotifyMeButton prod={prod} user={props.user} />}
                </div>
            </div>
        </div>

        {/* <div className="special-attributes"><ProductSpecialAttribute type="7-days-typical-life" /></div> */}

    </div>)

}

ProductListItem.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    // cart: PropTypes.object.isRequired,
    // prod: PropTypes.shape({
    //     _id: PropTypes.string.isRequired,
    //     title: PropTypes.string.isRequired,
    //     price: PropTypes.number.isRequired,
    //     price_was: PropTypes.number,
    //     // tax_value: PropTypes.number,
    //     // tax_unit: PropTypes.string,
    //     tax_total: PropTypes.number,
    //     limit_max_qty_in_cart: PropTypes.number,
    //     picture_thumb: PropTypes.string,
    //     qty: PropTypes.number,

    //     attributes: PropTypes.arrayOf(
    //         PropTypes.shape({
    //             _id: PropTypes.string,
    //             title: PropTypes.string,
    //             show_in_store: PropTypes.number,
    //             value: PropTypes.string,
    //             displayAs: PropTypes.string,
    //         })
    //     ),
    // }).isRequired
}

const mapStateToProps = state => {
    return ({ settings: state.box_storefront, user: (state.user && state.user.user) });
}
export default connect(mapStateToProps)(ProductListItem);

// export default ProductListItem;
