// import { persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage'
import { USER_LOGIN, USER_LOGOUT, USER_UPDATE } from './actions';
// import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';


const INITIAL_STATE = { user: null, token: null } //{ items: [{_id:1}, {_id:2}] };

const loginUser = (state, payload) => {
  // console.log("loginUser()", payload);
  return { ...payload }
}

const logoutUser = (state, payload) => {
  // console.log("logoutUser()");
  return { ...INITIAL_STATE }
}

const updateUser = (state, payload) => {
  // console.log("updateUser()");
  let _payload = { ...payload}
  delete _payload._id; // do not update user ID
  let user = Object.assign({ ...state.user, ..._payload });
  return { ...state, user: user };
}

export const user_reducer = (state = INITIAL_STATE, {type, payload} ) => {
  switch (type) {
    case USER_LOGIN:
      return loginUser(state, payload);
      break;
    case USER_LOGOUT:
      return logoutUser(state, payload);
      break;
    case USER_UPDATE:
      return updateUser(state, payload);
      break;
    default:
      return state;
  }
}

export default { user: user_reducer};

// const pConfig = {
//   key: 'cart',
//   storage,
//   blacklist: ['somethingTemporary'],
//   whitelist: ['cart'],
//   // stateReconciler: autoMergeLevel2,
//   // stateReconciler: autoMergeLevel2 // see "Merge Process" section for details.
// }
// export default persistReducer(pConfig, user_reducer);
