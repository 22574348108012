import React from 'react'
import PropTypes from 'prop-types';
import { Divider } from 'antd';
import { Button } from 'Common/components';
import { Link, useHistory } from 'react-router-dom';
import { connect } from "react-redux";
import SignInForm from '../components/SignInForm';
import { loginUser, logoutUser } from '../redux/actions'


const SignInPage = props => {
    let history = useHistory();
    const [loggedInUser, setLoggedInUser] = React.useState(false);

    const { user } = props;
    if (user && user._id){
        history.push(props.redirectTo || '/')
        return null;
    }

    const onSigninCallback = args => {
        console.log("onSigninCallback()", args);
        // setLoggedInUser(args);
        props.loginUser(args);
    }

    if (props.user && props.user._id) history.push('/')
   
    return (<>
        <SignInForm {...props} style={{ marginTop: "20px", ...props.style }} onSuccessCallback={onSigninCallback} />

        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "left", margin: "0 0 40px 0" }}>
            <div style={{ maxWidth: "350px", width: "100%" }}>
                <Divider style={{ margin: "15px 0 10px 0" }} />
                <p><b>New customer?</b><br />Registering is quick and easy</p>
                {props.onRegisterClick && <Button onClick={props.onRegisterClick} type="primary" theme="blue" block size="large">Register</Button>}
                {!props.onRegisterClick && <Button type="primary" theme="blue" block size="large"><Link to="/user/register">Register</Link></Button>}
                
            </div>
        </div>

    </>)
}
SignInPage.propTypes = {
    isPopup: PropTypes.bool,
}

const mapStateToProps = state => {
    return ({ user: (state.user && state.user.user) });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    loginUser: (payload) => dispatch(loginUser(payload)),
    logoutUser: (payload) => dispatch(logoutUser(payload)),
})
export default connect(mapStateToProps, mapDispatchToProps)(SignInPage);

// export default SignInPage;