import React, { useState } from 'react'
// import PropTypes from 'prop-types';
// import { connect } from "react-redux";
import { Link, useRouteMatch } from 'react-router-dom';
import { Row, Col, Dropdown } from 'antd';

// import { __error } from 'Common/scripts/consoleHelper';
import { Icon, Button, Loader } from 'Common/components';
import { constructCategoryArray } from 'Common/scripts/Functions';
import Cart from './Cart';
import SiteSearch from './SiteSearch';


const LayoutTopMenu = props => {
    const [parents, set_parents] = useState(["root"])
    const isCheckoutPage = useRouteMatch({ path: "/checkout", strict: false, sensitive: true });

    const onMouseOver = node => {

        let p = parents.slice();
        if (node.children && node.children.length > 0) {
            let index = p.findIndex(o => o == node._id);
            if (index == -1) {
                p.splice(node.level + 1); // remove same level
                p.push(node._id); // add new level
                set_parents(p)
            }
        } else {
            // remove child nodes after this level when there are no more children
            if (parents.length > node.level) {
                p.splice(node.level + 1);
                set_parents(p)
            }
        }

    }

    const RenderMenus = () => {
        const { loading, productCats } = props;
        if (loading || !productCats) return null;
        if (!parents) return null;
        console.log("parents: ", parents)

        return parents?.map((parentIds, i1) => {
            let row = constructCategoryArray(productCats, parentIds == "root" ? null : parentIds);

            return (
                <Col className="dd-menu-col" key={i1}>
                    {row.map((item, i) => {
                        let _item = { ...item, level: i1 }
                        return <Link to={`/category/${item.slug}`} className={`dd-menu-item level_${i1} ${parents.indexOf(item._id) > -1 ? 'selected' : ''}`}
                            onMouseOver={() => onMouseOver({ ..._item })}
                            key={i}>{`${item.title}`} {item.children && item.children.length > 0 && <span style={{ fontSize: "12px" }}><Icon icon="chevron-right" /></span>}</Link>
                    })}
                </Col>
            )

        })

    }

    const ddMenu = () => {
        const { loading, productCats } = props;

        return (<div className="dropdown-menu">
           <Loader loading={loading} style={{ padding: "25px", }} />
           {!loading && productCats &&
               <Row className="dd-menu-row">
                   {RenderMenus()}
               </Row>
           }
       </div>)
    }

    const { loading, productCats, visible } = props;
    if (visible) return <span />;

    return (<div className="site-layout-topmenu-affixed-wrapper AffixedMenuAnimation animated">
        <div className="site-layout-topmenu-affixed ">
            <Row style={{ width: "100%" }} gutter={[12, 0]}>
                <Col align='center'><Link to="/"><img style={{ width: "135px", margin: "5px 0" }} src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/logo-190.png`} /></Link></Col>
                <Col flex="auto" />
                {!isCheckoutPage && 
                    <Col align='center'>
                        <Dropdown overlay={ddMenu()} overlayClassName="dropdown-menu-wrapper affixed" trigger={['click']}>
                            <Button size="large" className="gray-button">Shop <Icon style={{marginLeft:'5px', paddingTop:'2px'}} className="_icon" icon="chevron-down" /></Button>
                        </Dropdown>
                    </Col>
                }
                <Col align='center'>
                    <Cart hidden={isCheckoutPage!==false} />
                    {isCheckoutPage && <Link to="/"><Button size="large">Back to shop</Button></Link>}
                </Col>
            </Row>

    </div></div>)

}
export default LayoutTopMenu;
