import React, { Component } from 'react'
import { Row, Col, Modal, message, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { Route, Switch, Redirect } from 'react-router-dom';
import { E404, LayoutWrapper } from "Layout_v1";
import { connect } from "react-redux";
import Profile from '../components/user-profile';
import OrderAddress from '../components/user-address';
import UserPassword from '../components/user-password';
import { ROOT, MODULE } from '../constants'
import '../styles.scss'



const ProfilePage = props => {
    if (!props.user || !props.user._id) return <Redirect to="/" />

    return (<div className="profile-page">
        
        {/* <Row gutter={[0,0]} style={{display:"flex", flexDirection:"row", flexWrap:"nowrap"}}> */}
        <Row gutter={[20]}>
            <Col flex="200px">
                <Menu className="vertical-menu" mode="inline" style={{marginBottom:"20px"}}>
                    <Menu.Item><Link to={`${ROOT}/profile`}>Personal Details</Link></Menu.Item>
                    <Menu.Item><Link to={`${ROOT}/profile/addresses`}>Address</Link></Menu.Item>
                    <Menu.Item><Link to={`${ROOT}/profile/password`}>Pasword</Link></Menu.Item>
                </Menu>
            </Col>
            <Col flex="auto">
                <Switch>
                    {/* <Route exact={true} path={`${ROOT}/profile`} component={Profile} /> */}
                    <Route exact={true} path={`${ROOT}/profile`} render={() => (<Profile user_id={props.user._id} />)} />
                    {/* <Route exact={true} path={`${ROOT}/profile/order-history`} render={() => (<OrderHistory user_id={props.user._id} />)} /> */}
                    {/* <Route exact={true} path={`${ROOT}/profile/order/:serial`} component={UserOrderDetails} /> */}
                    <Route exact={true} path={`${ROOT}/profile/addresses`} render={() => (<OrderAddress user_id={props.user._id} />)} />
                    <Route exact={true} path={`${ROOT}/profile/password`} render={() => (<UserPassword user_id={props.user._id} />)} />
                    <Route component={E404} />
                </Switch>
            </Col>
        </Row>
    </div>)

}

// const mapStateToAvatarPopProps = state => {
//     return ({ user: (state.user && state.user.user) || {} });
// }
// // const mapAvatarPopDispatchToProps = (dispatch, ownProps) => ({
// //     // loginUser: (payload) => dispatch(loginUser(payload)),
// //     flushCart: () => dispatch(flushCart()),
// //     logoutUser: () => dispatch(logoutUser()),
// //     updateUser: (payload) => dispatch(updateUser(payload)),
// // })
// export const WithRedux = connect(mapStateToAvatarPopProps)(ProfilePage);

// export default WithRedux;






const ModuleCheck = (props) => {
    if (!props.user || !props.user._id) return <E404 />
    return <ProfilePage {...props} />
}
const mapStateToAvatarPopProps = state => {
    return ({ user: (state.user && state.user.user) || {} });
}
const WithRedux = connect(mapStateToAvatarPopProps)(ModuleCheck);
export default WithRedux;

