import React from 'react'
import PropTypes from 'prop-types';
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { connect } from "react-redux";
import { Row, Col, Modal, message, Alert, Steps } from 'antd';
import { Icon, Button, Heading } from 'Common/components'
import { FormComponent, FormField, FormFieldGroup, rules, composeValidators } from 'Common/components/Form'
import { genders } from 'configs/constants';
import { __error } from 'Common/scripts/consoleHelper'
import { ROOT, MODULE } from '../constants'
import { timestamp } from 'Common/scripts/Functions';

const REG_CLIENT = loader('src/graphqls/user/registerClient.graphql');


export const RegisterForm = props => {
    const [loading, setLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);

    const handelResponse = async (registrationResponse) => {
        // const { error, Status, TokenData, LoggedUser, autoLoginInfo } = registrationResponse;

        const {
            error,
            // user: User
            // address: Address
            autoLoginInfo
        } = registrationResponse;

        if (error) {
            console.log(__error("ERROR: "), registrationResponse);
            setErrorMessage(error.message)
            // message.error(error.message);
            setLoading(false);
            return;
        }

        message.success("Registration successful");

        props.onSuccessCallback(autoLoginInfo ? JSON.parse(autoLoginInfo) : autoLoginInfo);

    }

    const onSubmit = values => {
        // console.log("onSubmit: ", values);
        setErrorMessage(null);

        setLoading(true);

        let filteredValues = {
            fname: values.fname,
            gender: values.gender,
            email: String(values.email).trim(),
            password: String(values.password).trim(),
            phone: String(values.phone).replace("+92", "").trim(),
            receive_offers: values.receive_offers ? 1 : 0,
            address: props.locationInfo,
            hash: `T${timestamp()}`
        }

        props.registerClient(filteredValues).then(r => {
            handelResponse(r.data.registerClient);
        }).catch(erro => {
            console.log(__error("Request Failed!"));
            setErrorMessage("Request Failed!")
            setLoading(false);
        })

    }


    return (<Row><Col align="center" flex="auto">
        <FormComponent onSubmit={onSubmit} id='RegistrationForm' loading={loading} hideDevBlock={true} style={{ maxWidth: "350px", width: "100%", textAlign: "left", marginBottom: "20px" }}
            fields={{ gender: "male", }}><>

            <h3>Registration is quick and easy</h3>
            {errorMessage && <Alert message={errorMessage} type={"error"} showIcon />}

            <Row>
                {genders.map((item, i) => (<Col key={i}><FormField name='gender' type="radio" value={item._id} label={item.title} /></Col>))}
            </Row>

            {/* <Row>
                <Col flex="80px" style={{ padding: 0 }}><FormField name='gender' type="select" compact data={genders} validate={composeValidators(rules.required)} /></Col>
                <Col flex="auto" style={{ padding: 0 }}><FormField name='fname' type="text" compact placeholder="Name" validate={composeValidators(rules.required)} /></Col>
            </Row> */}

            <FormField type="text" name="fname" label="Name" validate={composeValidators(rules.required)} compact />
            <FormField type="text" name="email" label="Email Address (Login Use)" validate={composeValidators(rules.required, rules.isEmail)} compact />
            <FormField type="password" name="password" label="Password" validate={composeValidators(rules.required, rules.minChar(8))} compact />

            <FormField
                // placeholder="+92 (3XX) XXX XX XX"
                placeholder="(03XX) XXX XX XX"
                name="phone"
                type="mask"
                // mask="+\92 (999) 999-99-99"
                mask="(0999) 999-99-99"
                label="Mobile Number (verification will require)"
                validate={composeValidators(
                    rules.required,
                    rules.minChar(11, (val) => val.replace(/_/, "")),
                    rules.maxChar(11, (val) => val.replace(/_/, "")),
                )} 
            />

            {/* <FormField type="text" name="phone" label="Mobile Number (verification will require)"
                addonBefore={<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Icon icon="mobile" color="#CCC" />
                </div>}
                validate={composeValidators(rules.required)} compact /> */}

            <FormField type="checkbox" name="agree" label={<>I've read and accepted the <a href="/p/terms_and_conditions" className="green-link">Terms & Conditions</a></>} validate={rules.required} compact />
            <Button type="primary" htmlType="submit" theme="blue" block size="large">Send SMS Verification Code</Button>

        </></FormComponent>
    </Col></Row>)
}

RegisterForm.propTypes = {
    onSuccessCallback: PropTypes.func.isRequired,
    locationInfo: PropTypes.object.isRequired
    // prop: PropTypes.type.isRequired
    // onEditRecord: PropTypes.func.isRequired
}


const WithApollo = compose(

    graphql(REG_CLIENT, {
        props: ({ mutate }) => ({
            registerClient: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),

)(RegisterForm);

const mapStateToProps = state => {
    return ({ user: (state.user && state.user.user) || false });
}
export default connect(mapStateToProps)(WithApollo);

// export default WithApollo;