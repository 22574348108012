import React from 'react'
// import { Route, Switch } from 'react-router-dom';
import { E404, LayoutWrapper } from "Layout_v1";
import 'Layout_v1/styles/home.scss'

import MainPage from './containers';

// import SuccessContainer from 'Modules/Checkout/containers/CheckoutSuccess'

// import { ROOT } from './'; 


// const menuArray = props => ([
//     { path: `${ROOT}`, exact: true, icon: 'caret-right', title: 'Innter Home', component: MainPage},
// ]);


export const Home = props => {
    // let routs = menuArray();
    // return <SuccessContainer />
    
    return (
        <LayoutWrapper topMenu={null} menuOverride={null}>
            <MainPage />
            {/* <Switch>
                {routs.map((item, i) => {
                    if (!item.component) return null;
                    return <Route exact={item.exact} path={item.path} component={item.component} key={i} />
                })}
                <Route key={999} component={E404} />
            </Switch> */}
        </LayoutWrapper>
    )
}

export default Home;
