/* eslint-disable eqeqeq */
import _ from 'lodash';
import base64 from 'base-64';
import moment from 'moment';
import moment_tz from 'moment-timezone';

import { __error, __yellow } from './consoleHelper'

export const mergeCarts = async (cart, customer_id, gql_mergeCart) => {
  if (!cart.items || cart.items.length < 1) return cart;

  let items = cart.items.map(item => ({
    customer_id: customer_id,
    product_id: item._id,
    qty: item.qty
  }));

  let mearged = await gql_mergeCart({ items, customer_id })
    .then(r => {
      if (r.data.mergeCart.error) console.log(__error("Error in mearning redux cart"), r.data.mergeCart);
      return r.data.mergeCart;
    }).catch(err => {
      console.log(__error("Unable to merge carts."));
      return { error: { message: "Unable to merge carts" } };
    });

  console.log("mearged: ", mearged);
  
  return mearged;
}


export const formToFilter = (fields) => {
  let temp;
  let filter = {}
  let keys = Object.keys(fields);

  const translateFilterValue = (val) => {
    if (val instanceof moment) return dateToMongo(val);
    if (_.isString(val)) return String(val);
    if (_.isInteger(val)) return Number(val);
    if (val.keywords) return val;
    return false;
  }

  keys.forEach(element => {
    let val = fields[element];

    if (!val) { }
    // else if (val.keywords) filter[element] = val
    else if (translateFilterValue(val)) filter[element] = translateFilterValue(val);
    else {
      temp = {};
      if (val.gt) temp = Object.assign(temp, { "$gt": translateFilterValue(val.gt) });
      else if (val.gte) temp = Object.assign(temp, { "$gte": translateFilterValue(val.gte) });
      else if (val.lt) temp = Object.assign(temp, { "$lt": translateFilterValue(val.lt) });
      else if (val.lte) temp = Object.assign(temp, { "$lte": translateFilterValue(val.lte) });
      else {
        console.log(__error("Invalid filter element"), val);
      }
      if (Object.entries(temp).length > 0) filter[element] = temp;
    }
  });
  // console.log("filter: ", filter);
  return filter;
}



/*************************** GENERAL FUNCTIONS *******************
 *
*/
export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const string_to_slug = str => {
  if (!str || str.length < 1) return "";

  //# Ver 1
  // return String(str).replace(/[^a-z0-9]/gi, '-').replace(/-+/g, '-').replace(/^-|-$/g, '').toLowerCase();

  //# Ver 2
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaaaeeeeiiiioooouuuunc------";

  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .toString()
    .normalize('NFD')
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // replace whitespace by -
    .replace(/-+/g, '-') // replace dashes
    .replace(/&/g, '-y-') // Replace & with 'and'
    // .replace(/\-\-+/g, '-') // Replace multiple - with single -
    // .replace(/^-+/, "") // trim - from start of text
    // .replace(/-+$/, "") // trim - from end of text
    .trim() // Remove whitespace from both sides of a string

  return str;
}



/*************************** ARRAY FUNCTIONS *******************
 *
*/
export const sortArrayByVal = (arr, name, _order) => {
  let order = _order ? String(_order).toLowerCase() : "asc";
  if (order == 'asc') return arr.sort((a, b) => (a[name] < b[name]) ? 1 : -1)
  return arr.sort((a, b) => (a[name] > b[name]) ? 1 : -1)
}

export const constructCategoryArray = (allCats, parent = null) => {
  let arr = allCats.filter(o => o['parent_cat_id'] == parent).map(item => ({
    _id: item._id,
    title: item.title,
    slug: item.slug,
    status: item.status,
    menu_bg_img: item.menu_bg_img,
    title_img: item.title_img,
    children: constructCategoryArray(allCats, item._id),
  }))

  return arr;
}


/*************************** JSON FUNCTIONS *******************
 *
*/
export const jsonStringify = (jsonObj, a=0, b=0) => {
  if(!jsonObj) return jsonObj;
  let result = false;

  try{
            if (typeof jsonObj!=="string"){
              result = JSON.stringify(jsonObj, a, b);
            }else{
                result = false;
            }
  }
  catch (error){
      console.error("ERROR : jsonStringify()", error);
  }

  return result;
}

export const parseJson = (jsonString) => {
  let jsonObject = null;

  if(!jsonString) return jsonObject;

  try{
            if (typeof jsonString!=="string"){
                // return false;
            }else{
                jsonObject = JSON.parse(jsonString);
                // return jsonObject;
            }
  }
  catch (error){
            console.log("ERROR : parseJson()", error);
  }

  return jsonObject;
}

export const decode64 = (str) => {
  let _str = "";
  if(!str) return _str;
  try{
            if (typeof str!=="string"){
                // return false;
            }else{
                _str = base64.decode(str);
                // return jsonObject;
            }
  }
  catch (error){   console.log("ERROR : decode64()", error);   }
  return _str;
}



/**** Image functions ************/
/*
getBase64(File Field Object)
*/
export const getBase64 = (file, as='DataURL') => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    // reader.onload = () => resolve(reader);
    reader.onload = () => {
      // console.log("FileReader: ", reader);
      resolve(reader.result)
    }
    reader.onerror = error => reject(error);
  });
}

export const resizeImage = (_image, maxWidth=300, maxHeight=300) => {

    return new Promise(async function(resolve,reject){
      var thumbnailWidth, thumbnailHeight;
      var image = new Image();
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');

      image.onload = function(){
        //Calculate the size of the thumbnail, to best fit within max/width (cropspadding)
        var thumbnailScale = (image.width / image.height) > (maxWidth / maxHeight) ?
        maxWidth / image.width : maxHeight / image.height;
        thumbnailWidth = image.width * thumbnailScale;
        thumbnailHeight = image.height * thumbnailScale;

        // set its dimension to target size
        canvas.width = thumbnailWidth;
        canvas.height = thumbnailHeight;

        // draw source image into the off-screen canvas:
        ctx.drawImage(image, 0, 0, thumbnailWidth, thumbnailHeight);

        //Draw border (optional)
        // ctx.rect(0, 0, thumbnailWidth, thumbnailHeight - 1);
        // ctx.strokeStyle = "#555555";
        // ctx.stroke();

        resolve(canvas.toDataURL('image/png', 70));
        // resolve(canvas.toDataURL('image/jpeg', 70));
        // return canvas.toDataURL('image/jpeg', 70);
      }

      image.src = _image;

    });

}



/*************************** DATE FUNCTIONS ******************* 
 * 
*/

// Asia/Karachi	

export const timestamp = () => moment().valueOf();
export const dateToMongo = (_date) => {
  return moment_tz.utc(_date).format();
}
export const mongoToDate = (_date) => {
  return moment_tz.utc(_date).tz("Asia/Karachi");
}

// export const dateToMongo = (_date) => {
//   // console.log(
//   //   " --------------------- \n",
//   //   date, "\n",
//   //   moment(date).format('YYYY-MM-DD HH:mm:ss'),
//   //   "\n ---------------------",
//   // );
//   let newDate = date ? moment(date) : moment();
//   return newDate.format('YYYY-MM-DD[T]HH:mm:ss[Z]');
// }


export const getTimeDifference = _date => {
  var now = moment(new Date()); //todays date
  var end = moment(_date); // another date
  var duration = moment.duration(now.diff(end));

  var years = duration.asYears();
  
  if(years >= 1) return `${Math.floor(years)} Years ago`;

  var months = duration.asMonths();
  if (months >= 1) return `${Math.floor(months)} Months ago`;

  var weeks = duration.asWeeks();
  if (weeks >= 1) return `${Math.floor(weeks)} Weeks ago`;

  var days = duration.asDays();
  if (days >= 1) return `${Math.floor(days)} Days ago`;

  var hours = duration.asHours();
  if (hours >= 1) return `${Math.floor(hours)} Hours ago`;

  var minutes = duration.asMinutes();
  if (minutes >= 1) return `${Math.floor(minutes)} Minutes ago`;

  return `Less than a minute ago`;

}

export const convertTo24Hrs = timeString => {
  let newStr = String(timeString);
  if (newStr.length < 4)
    for (let a = 0; a < (4 - newStr.length); a++)
      newStr = "0" + newStr;
  return newStr;
}
export const convertTo12Hrs = timeString => {
  let _timeString = convertTo24Hrs(timeString);
  // return moment(_timeString, ["HH"]).format("hh A");
  return moment(_timeString, ["HHmm"]).format("hh:mm A");
}




/*************************** MOngo Record filters FUNCTIONS *******************
 *
*/
export const omitTypeName = (obj) => {
  // console.log("omitTypeName()", obj);
  let o = {};
  for (let b in obj) {
    if (b != '__typename')
      o[b] = obj[b];
  }
  // console.log("omitTypeName()", o);
  return o;
}

export const omitAllTypeName = (obj) => {

  if (_.isArray(obj) || _.isObject(obj)) {
    let o = _.isArray(obj) ? [] : {};
    for (let b in obj) {
      if (b != '__typename') {
        o[b] = _.isString(obj[b]) ? obj[b] : omitAllTypeName(obj[b]); //_.isObject(obj) ? omitAllTypeName(obj[b]) : obj[b];
      }
    }
    obj = o;
  }

  return obj;

  // _.isArray()
  // _.isString()
}


export const parseDeliverySlotData = delivery_slot => {
  let _date = mongoToDate(delivery_slot.date);
  let time_range_utc = _.isArray(delivery_slot.time_range_utc) ? delivery_slot.time_range_utc : JSON.parse(delivery_slot.time_range_utc);
  let t1 = mongoToDate(time_range_utc[0]);
  let t2 = mongoToDate(time_range_utc[1]);

  // let str = _date.format('YYYY-MM-DD ') + t1.format("HH:mm");
  // let deliveryTime = moment(str, 'YYYY-MM-DD HH:mm');

  return { 
    time_range_utc: [t1, t2], 
    start_date: moment(_date.format('YYYY-MM-DD ') + t1.format("HH:mm"), 'YYYY-MM-DD HH:mm'),
    end_date: moment(_date.format('YYYY-MM-DD ') + t2.format("HH:mm"), 'YYYY-MM-DD HH:mm'),
  }
}




/*************************** Form FUNCTIONS *******************
 *
*/
export const nextTabInput = (el, parentId) => {
  // var universe = document.querySelectorAll('input, button, select, textarea, a[href]');
  // var list = Array.prototype.filter.call(universe, function (item) { return item.tabIndex >= "0" });
  // var index = list.indexOf(el);
  // return list[index + 1] || list[0];

  // let VerifecationForm = document.getElementById("VerifecationForm")
  // let inputs = VerifecationForm.querySelectorAll('input[type="text"]');

  let inputs = document.querySelectorAll(`#${parentId} input[type="text"]`)
  if (!inputs || inputs.length < 1) {
    console.log(__error("Inputs not found!"))
    return;
  }

  let value = el?.target?.value;
  if (!value && value.length < 1) return;

  let currentIndex = el?.target.getAttribute('tabindex');
  if (currentIndex >= 4) return;
  inputs[currentIndex].focus()
}




/****************** Order functions */

export const calculateOrder = (order, page = "") => {
  console.log(__yellow("calculateOrder()"), { page })

  if (!order || !order._id) {
    console.log(__error("calculateOrder()"), "Missing order");
    return { error: { message: "M<issing order" } }
  }

  let new_order = ['unconfirmed', 'new', 'picking', 'pending', 'canceled', 'declined', 'refunded'].includes(order.status);
  let in_review = ['in-review'].includes(order.status);
  let dispatched = ['ready-to-dispatch', 'dispatched', 'delivered', 'completed'].includes(order.status);


  let orignal_total = 0;
  // let orignal_savings = 0;

  let total_items = 0;
  let sub_total = 0;
  let discount = 0;
  let grand_total = 0;
  let delivery_charges = 0;
  let tax = 0;
  let savings = 0;

  let items = order.items.map(o => ({ ...o, orignal_qty: o.qty }))
    .map(o => {
      let item = {
        ...o,
        // orignal_qty: o.qty,
        pickedQty: 0,
        deliverable_qty: o.deliverable_qty,
      }

      orignal_total += (item.qty * item.price)
      // orignal_savings += (item.price_was > item.price) ? (item.qty * item.price_was) : 0;

      let pickup_data = order?.pickup_data?.find(oo => oo.product_id == item._id)
      if (pickup_data) Object.assign(item, { pickedQty: pickup_data.pickedQty || 0 })

      if (new_order) { }
      if (dispatched) Object.assign(item, { qty: o.deliverable_qty || 0 })
      if (in_review) {
        if (pickup_data.notFound && pickup_data.notFound.length > 0) {
          Object.assign(item, { qty: pickup_data.pickedQty || 0 })
        }
        else {
          Object.assign(item, { qty: pickup_data.pickedQty || 0 })
        }
      }
      if (page == "till-confirmation") {
        Object.assign(item, { qty: o.deliverable_qty || 0 })
      }

      if (pickup_data && pickup_data.notFound && pickup_data.notFound.length > 0) Object.assign(item, { qty: 0, notFound: pickup_data?.notFound?.length > 0 ? pickup_data.notFound : undefined })

      let price = Number(item.qty || 0) * Number(item.price);
      let price_was = (item.price_was > item.price) ? (Number(item.qty || 0) * Number(item.price_was || 0)) : 0;

      sub_total += Number(price);
      savings += Number(price_was || 0);

      Object.assign(item, { total: item.qty * Number(item.price) })

      if (item.qty > 0) total_items++;

      return item;
    })

  // items.forEach(item => {
  //     let price = item.qty * item.price;
  //     let price_was = (item.price_was > item.price) ? item.qty * item.price_was : 0;

  //     sub_total += price;
  //     savings += price_was;
  // });

  if (order.discountVoucher && order?.discountVoucher?._id) {
    // discountVoucher.discount_value
    // discountVoucher.discount_type == "fix" ? "RS" : discountVoucher.discount_type
    // discountVoucher.discount_value
    discount = order.discountVoucher.discount_value;
  }

  console.log({ savings, sub_total })

  savings = (savings > sub_total) ? savings - sub_total : 0;
  grand_total = sub_total - discount + delivery_charges + tax;

  // orignal_savings = (orignal_savings > 0) ? (orignal_savings - orignal_total) : 0;


  return {
    items,
    calculations: { total_items, orignal_total, sub_total, discount, grand_total, delivery_charges, tax, savings }
  }

}
