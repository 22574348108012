import React, { Component } from 'react'
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { Row, Col, Modal, message, Alert } from 'antd';
import { Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import moment from "moment";
import { E404 } from "Layout_v1";
import { Icon, Button, Heading, Loader } from 'Common/components'
import { FormComponent, FormField, FormFieldGroup, rules, composeValidators } from 'Common/components/Form'
import { phoneCodes, genders } from 'configs/constants';
import { __error } from 'Common/scripts/consoleHelper'
import { sleep, dateToMongo, mongoToDate, timestamp } from 'Common/scripts/Functions';


const GET_PROFILE = loader('src/graphqls/user/user.graphql');
const UPDATE_USER = loader('src/graphqls/user/edit.graphql');

function handleMask(event, mask) {
    // <input type="text" onkeypress="handleMask(event, 'data: 99/99/9999 99:99 999 ok')" placeholder="data:  ok" size=40>

    event.stopPropagation()
    event.preventDefault()
    if (!event.charCode) return
    var c = String.fromCharCode(event.charCode)
    if (c.match(/\D/)) return

    var val = event.target.value.substring(0, event.target.selectionStart) + c + event.target.value.substr(event.target.selectionEnd)
    var pos = event.target.selectionStart + 1

    var nan = count(val, /\D/, pos) // nan va calcolato prima di eliminare i separatori
    val = val.replace(/\D/g, '')

    var mask = mask.match(/^(\D*)(.+9)(\D*)$/)
    if (!mask) return // meglio exception?
    if (val.length > count(mask[2], /9/)) return

    for (var txt = '', im = 0, iv = 0; im < mask[2].length && iv < val.length; im += 1) {
        var c = mask[2].charAt(im)
        txt += c.match(/\D/) ? c : val.charAt(iv++)
    }

    event.target.value = mask[1] + txt + mask[3]
    event.target.selectionStart = event.target.selectionEnd = pos + (pos == 1 ? mask[1].length : count(event.target.value, /\D/, pos) - nan)

    function count(str, c, e) {
        e = e || str.length
        for (var n = 0, i = 0; i < e; i += 1) if (str.charAt(i).match(c)) n += 1
        return n
    }
}




const UserProfilePage = props => {
    const [busy, setBusy] = React.useState(false);

    const onSubmit = async (values) => {
        // console.log("onSubmit: ", values);
        let data = {
            _id: values._id,
            fname: values.fname,
            lname: values.lname,
            gender: values.gender,
            dob: values.dob, //dateToMongo(values.dob),
            // phone: `${values.phone_code}-${values.phone}`,
            phone: String(values.phone).replace("+92", "").trim(),
            hash: `T${timestamp()}`,
        }
        
        if (data.phone != props.userData.phone){
            // data.phone_confirmed = 0;
            // alert("You are changing your Phone number, this action required re-verification")
        }

        setBusy(true);
        const resutls = await props.editUser(data).then(r => (r?.data?.editUser || { error: { message: "Invalid response!" } }))
        .catch(err => {
            console.log(__error(""), err);
            return { error: { message:"Query Error"}}
        })    
        setBusy(false);
        
        if (resutls.error){
            message.error(resutls.error.message);
            return false;
        }

        message.success("Record updated")
        
    }

    // console.log(InputMask)
    // if(window){
    //     console.log(window.InputMask)
    //     new window.InputMask()
    // }

    const { loading, userData, user } = props;

    if (!user || !user._id) return <Redirect to="/" />
    if (loading) return <Loader loading={true} />


    return (<div className="h-center" style={{ marginBottom: "50px" }}><div style={{ width: "100%", maxWidth: "400px" }}>
        <h3 style={{ margin: "10px", textTransform:"none" }}>{userData.email}</h3>
        
        <FormComponent onSubmit={onSubmit} id='ProdCatForm' loading={busy} debug={true} style={{ maxWidth: "400px", width:"100%" }} 
            fields={{ ...userData }}
            form_render={({ values }) => {
                return(<>
                    <FormField
                        // placeholder="+92 (3XX) XXX XX XX"
                        placeholder="(03XX) XXX XX XX"
                        name="phone"
                        type="mask"
                        // mask="+\92 (999) 9999999"
                        mask="(0999) 999-99-99"
                        label="Mobile Number"
                        // validate={composeValidators(rules.required, rules.func(val => {
                        //     let aa = val.replace(/_/g, "")
                        //     console.log("aa: ", aa)
                        // }))} 
                        validate={composeValidators(
                            rules.required,
                            rules.minChar(11, (val) => val.replace(/_/, "")),
                            rules.maxChar(11, (val) => val.replace(/_/, "")),
                        )}
                    // onChange={(e, callback)=>{
                    //     let val = e.target.value;
                    //         val = val.replace(/\)/g, "").replace(/\(/g, "").replace(/-/g, "").replace(/ /g, "")
                    //     console.log("val: ", val)
                    //     callback(val);
                    // }}
                />
                    {(userData && userData._id && values.phone != userData.phone) && <Alert message="Changing your phone number requires, re-verification via SMS" type="warning" showIcon={false} />}

                    <Row>
                        <Col flex="auto"><FormField type="text" width="100%" name="fname" label="First Name" validate={composeValidators(rules.required, rules.minChar(3))} /></Col>
                        <Col flex="auto"><FormField type="text" width="100%" name="lname" label="Last Name" validate={composeValidators(rules.required, rules.minChar(3))} /></Col>
                    </Row>

                    <Row>
                        <Col flex="auto">
                            <FormField type="date" name="dob" displayFormat={'DD-MM-YYYY'} size="large" valueFormat={'YYYY-MM-DD[T]00:00:00[Z]'} label="DOB" style={{ width: "100%" }}
                            // displayFormat={'YYYY-MM-DD'} // disabledDate={(current) => { return current && current < moment().startOf('month'); }}
                            />
                        </Col>
                        <Col flex="auto">
                            <FormField type="select" label={<>Gender</>} name="gender" data={genders} validate={rules.required} size="large" />
                        </Col>
                    </Row>

                    <div style={{ margin: "10px" }}><Button block type="primary" size="large" htmlType="submit">Update</Button></div>

                </>)
            }} />
    </div></div>)
}

const WithApollo = compose(

    graphql(GET_PROFILE, {
        options: (props) => {
            return {
                variables: { id: props.user_id },
                fetchPolicy: "no-cache", //no-cache, cache-first, network-only, cache-only, cache-and-network
            };
        },
        props: ({ ownProps, data }) => {
            const { loading, user, error } = data;
            if (error) console.log(__error("error"), error);

            const userData = { ...user }
            if (user && userData && userData._id) {
                Object.assign(userData, {
                    dob: (userData && userData.dob && userData.dob != null) ? mongoToDate(userData.dob) : moment()
                })
            }
            

            return { loading, userData, queryErrors: error, }
        },
    }),

    graphql(UPDATE_USER, {
        props: ({ mutate }) => ({
            editUser: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),


)(UserProfilePage);


const ModuleCheck = (props) => {
    if (!props.user || !props.user._id) return <E404 />
    return <WithApollo {...props} />
}
const mapStateToAvatarPopProps = state => {
    return ({ user: (state.user && state.user.user) || {} });
}
const WithRedux = connect(mapStateToAvatarPopProps)(ModuleCheck);
export default WithRedux;
