import React from 'react'
import PropTypes from 'prop-types';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { Row, Col, Modal, message, Steps } from 'antd';
import { connect } from "react-redux";
import { __error } from 'Common/scripts/consoleHelper'
import { loginUser, logoutUser } from '../redux/actions'
import RegisterForm from '../components/RegisterForm';
import SignInForm from '../components/SignInForm';
import PhoneVerification from './PhoneVerification'
import LocationForm from '../components/LocationForm';


export const RegisterPage = props => {
    const [doAutologin, setDoAutologin] = React.useState(false);
    const [loggedInUser, setLoggedInUser] = React.useState(false);
    const [stepNum, setStepNum] = React.useState(0);
    const [locationInfo, setLocationInfo] = React.useState(null);

    // if (props.isPopup) return <div>isPopup</div>
    let history = useHistory();

    const onRegisterCallback = async(autoLoginInfo) => {
        // console.log("onRegisterCallback()", autoLoginInfo);
        if (autoLoginInfo){
            setDoAutologin(autoLoginInfo);
            return true;
        }
        history.push('/user/sign-in');
    }

    const onSigninCallback = args => {
        // console.log("onSigninCallback()", args);
        setLoggedInUser(args);
        // props.loginUser(args);
        // setDoAutologin(false);
        // props.loginUser(args);
    }

    const onVerificationCallback = args => {
        // console.log("onVerificationCallback()");
        props.loginUser({ ...loggedInUser, user:args});
        if (props.onCancel) props.onCancel();
    }

    const onStepSubmit = async (_values, _stepNum) => {
        if (_stepNum < 2) setStepNum(_stepNum + 1);

        setLocationInfo(_values)

        return true;
    }


    if (props.user && props.user._id) history.push('/')
    // if (loggedInUser && !props.user){
    //     props.loginUser(loggedInUser);
    //     // return <div>loggedInUser</div>
    // }


    if (loggedInUser) return <PhoneVerification user={loggedInUser.user} onSuccessCallback={onVerificationCallback} />
    if (doAutologin) return <SignInForm {...props} autoLoginInfo={doAutologin} onSuccessCallback={onSigninCallback} />
    // if (doAutologin && !props.user) return <SignInForm {...props} autoLoginInfo={doAutologin} onSuccessCallback={onSigninCallback} />
    

    return (
        <div style={{ margin: "20px 0 50px 0" }}>

            <Row style={{ justifyContent: "center" }}>
                <Col flex="900px" style={{ margin: "0 25px" }}>
                    <div style={{ textAlign: "right", marginBottom: "20px" }}>Already a member? &nbsp;
                        {props.onLoginClick && <span onClick={props.onLoginClick} className="green-link">Login</span>}
                        {!props.onLoginClick && <Link to="/user/sign-in" className="green-link">Login</Link>}
                        &nbsp; here.
                    </div>
                </Col>
            </Row>

            <div style={{ width: "100%", display: "flex", justifyContent:"center", marginBottom:"30px" }}><div style={{width:"500px"}}>
                <Steps current={stepNum} labelPlacement="vertical">
                    <Steps.Step title="Location" />
                    <Steps.Step title="Credentials" />
                </Steps>
            </div></div>

            {stepNum === 0 && <>
                <LocationForm buttonLabel="Next" onSubmit={(args) => onStepSubmit(args, stepNum)} />
            </>}

            {stepNum === 1 && <>
                <RegisterForm {...props} locationInfo={locationInfo} onSuccessCallback={onRegisterCallback} />
            </>}
            

        </div>
    )
}

RegisterPage.propTypes = {
    isPopup: PropTypes.bool
    // toggleDrawer: PropTypes.func.isRequired
    // prop: PropTypes.type.isRequired
    // onEditRecord: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return ({ user: (state.user && state.user.user) || false });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    loginUser: (payload) => dispatch(loginUser(payload)),
    logoutUser: (payload) => dispatch(logoutUser(payload)),
})
export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);

// export default RegisterPage;