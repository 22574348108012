import React from 'react'
import { Route, Switch } from 'react-router-dom';
import { E404, LayoutWrapper } from "Layout_v1";
// import { CheckoutSuccess } from "Layout_v1/Pages";
import 'Layout_v1/styles/home.scss'

import MainPage from './containers';
import CheckoutSuccess from './containers/CheckoutSuccess';
import ReviewCheckout from './containers/ReviewCheckout';

import { ROOT, MODULE } from '.'; 


const menuArray = props => ([
    { path: `${ROOT}`, exact: true, title: 'Checkout', component: MainPage},
    { path: `${ROOT}/success`, exact: true, title: 'Checkout Success', component: CheckoutSuccess},
    { path: `${ROOT}/review`, exact: true, title: 'Review Checkout', component: ReviewCheckout},
    // { path: `${ROOT}/register`, exact: true, title: 'Register', component: RegisterPage},
    // { path: `${ROOT}/sign-in`, exact: true, title: 'Register', component: SignInPage},
]);

export const Home = props => {
    let routs = menuArray();
    return (
        <LayoutWrapper topMenu={null} menuOverride={null} className={String(MODULE).toLowerCase()}>
            <Switch>
                {routs.map((item, i) => {
                    if (!item.component) return null;
                    return <Route exact={item.exact} path={item.path} component={item.component} key={i} />
                })}
                <Route key={999} component={E404} />
            </Switch>
        </LayoutWrapper>
    )
}

export default Home;
