import React, { Component, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Spin, message } from 'antd';
import { loader } from 'graphql.macro';
import { graphql, withApollo } from 'react-apollo';
import compose from 'lodash.flowright';
import debounce from 'lodash/debounce';
import { FormField } from './Form'
import { __error } from 'Common/scripts/consoleHelper'
// import { Loader, IconButton, Drawer, Button, DevBlock, Heading } from 'Common/components'
import _ from 'lodash'

const LOCATIONS = loader('src/graphqls/locations/locations.graphql');


const LocationSelectionComp = props => {
    const [list, setList] = React.useState(props.defaultValues || []);  // [{_id, title}]
    const [state, setState] = React.useState({});
    // const [showList, setShowList] = React.useState(false);
    // const toggleDrawer = args => setShowList(!showList);
    useEffect(() => {
        if (!props.preload) return;
        _fetchData(props.preload);

        return () => console.log;
    }, [props.preload])


    var lastFetchId = 0; 
    const _fetchData = async (value) => {
        if (!value || value.length < 1) return;
        lastFetchId += 1;
        const fetchId = lastFetchId;
    
        // setState({ kw: value, loading: true })
        // let filter = JSON.stringify({ ...props.filter, search:{keywords: value} });

        var filter;
        if (_.isString(value)) {
            setState({ kw: value, loading: true })
            filter = JSON.stringify({ ...props.filter, search: { keywords: value } });
        }
        else if (value) {
            setState({ loading: true })
            filter = JSON.stringify({ ...props.preload });
        }

        props.client.query({ query: LOCATIONS, variables: { filter } }).then(e => {
            const results = e.data.locations;

            if (results.error) {
                console.log(__error("ERROR: "), e);
                message.error(results.error.message);
                setState({ kw: value, loading: false })
                return;
            }

            if (results) setList(results.map(o => ({ _id: o.code, title: o.title, raw: o })) )
            setState({ loading: false })

        }).catch(err => {
            console.log(__error("API Call ERROR: locations : "), err);
            message.error("Request ERROR");
            setState({ loading: false })
        })


    }
    const fetchData = debounce(_fetchData, 800);

    const changeHandler = (val, callback) => {
        if (props.onChange){
            props.onChange(list.find(o=>o._id==val));
        }
        callback(val);
    }


    return (
        <>
            <FormField validate={props.validate}
                // type={props.multi ? 'select-multiple' : "select" }
                type={props.mode == 'multiple' ? 'select-multiple' : 'select'}
                name={props.name}
                label={props.label}
                // onChange={props.onChange}
                onChange={changeHandler}
                data={list}
                placeholder={props.placeholder || "Search Locations"}
                addNull={false}
                compact={props.compact}
                size={props.size}
                style={{...props.style, width:"100%"}}
                allowClear={props.allowClear}
                // keyMap="value=>text"
                // defaultValue={props.defaultValue && props.defaultValue._id}
                inputProps={{
                    onSelect: props.onSelect || undefined, // console.log,
                    onDeselect: props.onDeselect || undefined, // console.log,
                    loading:state.loading,
                    mode: props.mode || undefined, // tags, multiple
                    showSearch: true,
                    defaultActiveFirstOption: false,
                    showArrow: true,
                    notFoundContent: state.loading ? <Spin size="small" /> : null,
                    filterOption: false,
                    onSearch: fetchData,
                    // onChange:handelChange,
                }}
            />
        </>
    )
}
LocationSelectionComp.propTypes = {
    mode: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    defaultValues: PropTypes.array,
    onSelect: PropTypes.func,
    onDeselect: PropTypes.func,
    filter: PropTypes.object,
}

export const LocationSelection = withApollo(LocationSelectionComp);
export default LocationSelection;

