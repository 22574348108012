import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { reducers, whitelist, blacklist } from 'Modules';

import systemReducer from './box_storefront';
import navReducer from './redux_nav';
import cartReducer from './cart';

export const rootReducer = combineReducers({
  ...systemReducer,
  ...navReducer,
  ...cartReducer,
  ...reducers
});

// console.log("whitelist: ", whitelist);


const pConfig = {
  key: 'root',
  storage,
  blacklist: [].concat(blacklist),
  whitelist: ['box_storefront'].concat(whitelist)
  // whitelist: ['cart', 'box_storefront'].concat(whitelist)
}
export default persistReducer(pConfig, rootReducer);