import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Steps, Row, Col, Divider, message, Alert } from 'antd';
import { connect } from "react-redux";
import { Link, Redirect } from 'react-router-dom';
// import { graphql } from '@apollo/client/react/hoc';
import { graphql, withApollo } from 'react-apollo';

import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
// import moment from 'moment'
// import moment_tz from 'moment-timezone';

import { __error } from 'Common/scripts/consoleHelper';
// import { omitAllTypeName } from 'Common/scripts/Functions';
import { Button, Icon, Loader, DevBlock } from 'Common/components'
// import { Select } from 'Common/components/Form';
import RegisterPage from 'Modules/User/components/RegisterForm'
import SigninPage from 'Modules/User/containers/sign-In'
import TimeSlotSelector from '../components/TimeSlotSelector'
import Checkout from '../components/Checkout'
import { timeslotSelect, addressSelect, couponSelect, statusUpdate, flushOrder } from '../redux/actions'
import AddressSelect from '../components/AddressSelect'
import { E404 } from 'Layout_v1'
import GetLoggedInUserData from 'Modules/User/containers/GetLoggedInUserData';
import { flushCart } from 'Store/cart.actions';
import { ROOT } from '../'
import { dateToMongo, mongoToDate } from 'Common/scripts/Functions'
import PhoneVerification from 'Modules/User/containers/PhoneVerification';

import './styles.scss'
// import { faShekelSign } from '@fortawesome/free-solid-svg-icons';
// import { order_reducer } from '../redux/reducer';
// import { addNavItems } from 'Modules/connector-copy';

const CREATE_ORDER = loader('src/graphqls/order/createOrderFromCart.graphql');
// const CART = loader('src/graphqls/cart.graphql');


class CheckoutClass extends Component {
    state = {
        showRegistration: true,
        currentStep: (this.props.user && this.props.user._id) ? 2 : 1,
        selectedSlot: null,
        address: null,
        cartSynced: false,
        orderSuccess: false,
        busy:true,
        error: false,
        reviewOrder: false,
    }
    // state = { currentStep: 1, showRegistration:true }
    componentDidMount() {
        const { order, cart, flushOrder } = this.props;
        if (order.status && order.status != 'null') {
            flushOrder();
        }
        this.setState({ busy: false });
        // if (cart.items && cart.items.length < 0 && order.status) {
        //     flushOrder();
        // }
    }

    onSlotSelect = args => {
        console.log("onSlotSelect()", args);
        this.setState({ selectedSlot: args });
    }

    goBack = num => {
        this.setState({ currentStep: num, error:false });
    }

    verifyStep(stepNum) {
        const { selectedSlot, address } = this.state;
        if (stepNum == 1) return true;
        
        if (stepNum == 2) return !(!this.props.order.timeslot || (this.props.order.timeslot == null) || (this.props.order.address==null))
        
        if (stepNum == 3) { }

        return false;
    }

    onCheckoutPress = async(args) => {
        const { order, cart, user } = this.props;
        const { timeslot } = order;

        this.setState({ error:false })

        if (!order.address){
            message.error('Address not found!');
            return { error: { mesage: "Address not found" } };
        }

        const input = {
            cart_id: Number(cart._id),
            paymentMethod: "COD",
            shipping_address_id: Number(order.address._id),
            note: order.note,
            delivery_slot: {
                _id: timeslot._id,
                day: timeslot.day,
                date: dateToMongo(timeslot.date),
                time_range_utc: timeslot.time_range_utc
            },
            discount_voucher_code: order.coupon ? order.coupon.code : null,
        }

        // console.log("timeslot.date: ", timeslot.date)
        // Object.assign(input, {
        //     delivery_slot: {
        //         _id: timeslot._id,
        //         day: timeslot.day,
        //         date: moment(timeslot.date).subtract(5, 'days').format() , // dateToMongo(timeslot.date),
        //         time_range_utc: timeslot.time_range_utc
        //     },
        // })
       
        this.setState({ busy: true });

        let orderResult = await this.props.createOrderFromCart(input)
            .then(r => {
                if (!r || r?.data?.createOrderFromCart?.error){
                    console.log(__error("ERROR: "), r)
                    return { error: { message: r ? r.data.createOrderFromCart.error.message : "Invalid response!" }}
                }
                
                return r.data.createOrderFromCart;
            })
            .catch(error => {
                console.log(__error("Request ERROR: "), error);
                return { error: { message: "Unable to process order at the moment!" } }
            })


        if (orderResult.error) {
            message.error(orderResult.error.message);
            this.setState({ error: orderResult.error.message, busy: false })
            return orderResult;
        }


        message.success("Order successfull");
        this.props.statusUpdate('success');
        this.props.flushCart();
        this.setState({ busy: false });
        return orderResult;

    }

    bk_onCheckoutPress = async(args) => {
        const { order, cart, user } = this.props;
        const { timeslot } = order;

        if (!order.address){
            message.error('Address not found!');
            return { error: { mesage: "Address not found" } };
        }

        const address = JSON.parse(order.address);
        
        if (!address.geo_point || !address.geo_point.coordinates || address.geo_point.coordinates.length<2){
            message.error('Address map location not found!');
            return { error: { mesage: "Address map location not found!" } };
        }

        // if (!address.geoCoords.lat || !address.geoCoords.lng){
        //     message.error('Address map location not found!');
        //     return { error: { mesage: "Address map location not found!" } };
        // }

        let data = {
            cart_id: cart._id,
            paymentMethod: "COD",
            shipping_address: order.address,
            // shipping_geo_coords,
            geo_point: {
                type: address.geo_point.type, //'Point',
                coordinates: [address.geo_point.coordinates[0], address.geo_point.coordinates[1]] //  [center.lat, center.lng]
            },
            note: order.note,
            delivery_slot: {
                _id: timeslot._id,
                // start_time: timeslot.start_time,
                // end_time: timeslot.end_time,
                day: timeslot.day,
                // date: timeslot.date,
                date: dateToMongo(timeslot.date),
                time_range_utc: timeslot.time_range_utc,
            },
            discount_voucher_code: order.coupon ? order.coupon.code : null,
        }
        
        this.setState({ busy: true });

        let orderResult = await this.props.createOrderFromCart(data)
            .then(r => {
                if (!r || r.data.createOrderFromCart) return { error: !r ? { message: "Invalid response!" } : r.data.createOrderFromCart.error }
                return r.data.createOrderFromCart;
            })
            .catch(error => {
                console.log(__error(""), error);
                // message.error("Unable to process order at the moment!");
                return { error: { message:"Unable to process order at the moment!!!!!!"}}
            })


        if (orderResult.error){
            message.error(orderResult.error.message);
            this.setState({ error: orderResult.error.message, busy:false })
            return orderResult;
        }


        this.setState({ busy: true });
        message.success("Order successfull");
        this.props.statusUpdate('success');
        this.props.flushCart();
        this.setState({ busy: false });
        return orderResult;


        // if (orderResult) {
        //     if (orderResult.error) {
        //         message.error(orderResult.error.message);
        //         return true;
        //     }
        //     message.success("Order successfull");

        //     this.setState({ busy: true });
        //     this.props.statusUpdate('success');
        //     this.props.flushCart();
        //     this.setState({ busy: false });
        // }


    }

    render() {
        const { currentStep, showRegistration, selectedSlot, cartSynced, orderSuccess, busy, error, reviewOrder } = this.state;
        const { user, cart, order, settings } = this.props;

        if (busy) return <Loader loading={true} />

        if (order.status == 'success') return <Redirect to={`${ROOT}/success`} />
        if (!user || !user._id) return <Redirect to={`/user/sign-in`} />

        if (user && user._id && !cartSynced) return <GetLoggedInUserData onSuccess={() => this.setState({ cartSynced:true })} />
        if (!cart.items || cart.items.length < 1) return <E404 />
        if (order.status && (order.status != null || order.status != 'null')) {
            console.log(__error(order.status));
            return <Alert message="Impossible Happened!" description="Invalid Order Status" type="error" showIcon />
        }

        if (settings.min_order_amount > cart.grandTotal) {
            return <Alert message="Order Error" description={`Minimum order limit is ${settings.currency} ${settings.min_order_amount}`} type="error" showIcon />
        }

        if (user && user._id && user.phone_confirmed < 1){
            return <div style={{ padding: "50px 0px", }}>
                <PhoneVerification redirectTo="/checkout" />
            </div>
        }

        return (
            <div style={{padding: "50px 0px",}}>

                <Steps size="small" current={currentStep - 1} labelPlacement="vertical">
                    <Steps.Step title="Login" />
                    <Steps.Step title="Address & Time Slot" />
                    <Steps.Step title="Place Order" />
                </Steps>

                {currentStep == 1 && <div className="checkout-page">
                        {showRegistration && <div style={{width:"100%"}}>
                            <Row style={{ justifyContent: "center" }}>
                                <Col flex="auto" style={{ margin: "0 25px" }}>
                                    <div style={{ textAlign: "right", marginBottom: "20px" }}>Already a member? &nbsp;<Button onClick={() => this.setState({ showRegistration:false})} type="link" style={{padding: 0,}} className="green-link">Login</Button>&nbsp; here.</div>
                                </Col>
                            </Row>
                            <RegisterPage />
                        </div>}
                        {!showRegistration && <SigninPage /> }
                </div>}

                {currentStep == 2 && <div className="checkout-page">
                    <Row>
                        <Col flex="auto">
                            {this.props.user && this.props.user._id && <AddressSelect user={this.props.user} /> }
                        </Col>
                        <Col flex="auto" align="right">
                            <div> &nbsp; </div>
                            <Button size="large" onClick={() => this.setState({ currentStep: 3 })} disabled={!this.verifyStep(2)} theme="blue">Continue to Checkout <Icon icon="angle-right" /></Button>
                        </Col>
                    </Row>
                    <Divider style={{margin:"10px 0 20px 0"}} />
                    <TimeSlotSelector onSlotSelect={this.onSlotSelect} />
                </div>}

                {currentStep == 3 && <div className="checkout-page">
                    {error && <Alert message={"Error Found"} description={<>
                        {error}
                        <Button style={{ marginLeft: "20px" }} size="small"><Link to="/checkout/review">Review Order</Link></Button>
                    </>} type="error" showIcon />}
                    <Checkout onCheckoutPress={this.onCheckoutPress} goBack={this.goBack} />
                </div>}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return ({ settings: state.box_storefront, cart: state.cart, order:state.order, user: (state.user && state.user.user) || false });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    flushOrder: (payload) => dispatch(flushOrder(payload)),
    statusUpdate: (payload) => dispatch(statusUpdate(payload)),
    timeslotSelect: (payload) => dispatch(timeslotSelect(payload)),
    addressSelect: (payload) => dispatch(addressSelect(payload)),
    couponSelect: (payload) => dispatch(couponSelect(payload)),
    flushCart: (payload) => dispatch(flushCart(payload)),
})
const CheckoutHome = connect(mapStateToProps, mapDispatchToProps)(CheckoutClass);

// export default withApollo(CheckoutHome);
export default compose(
    graphql(CREATE_ORDER, {
        props: ({ mutate }) => ({
            createOrderFromCart: (args) => mutate({
                variables: { input: { ...args } },
                fetchPolicy: "no-cache"
            }),
        })
    }),
)(CheckoutHome);


// export default CheckoutHome;