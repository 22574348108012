import React from 'react'
import PropTypes from 'prop-types';
import { Row, Col, Breadcrumb, message, Divider } from 'antd';
// import { graphql } from '@apollo/client/react/hoc';
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
// import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { ProductListScroller, ProductGalleryScroller } from 'Layout_v1';
import ProductPageSkeleton from 'Layout_v1/ProductPageSkeleton';
import AddToCartButton from 'Layout_v1/AddToCartButton';
import { E404 } from "Layout_v1";
import { Icon, Button, Loader, NotifyMeButton } from 'Common/components'
import { __error } from 'Common/scripts/consoleHelper'
import { noImage } from 'configs/constants';
import { appText } from 'configs';


const GET_PROD = loader('src/graphqls/product/productBySlug.graphql');


class MainPage extends React.Component {
  componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
   
  render() {
    const { loading, productBySlug, settings: { currency } } = this.props;

    if (loading) return <ProductPageSkeleton />
    if (!loading && (!productBySlug || productBySlug.error || !productBySlug._id)) return <E404 title="Product Not Found!" />

    const prod = productBySlug;
    console.log("prod: ", prod)

    if (prod?.category?.status != 'enabled') return <p align="center">Product Unavailable</p>

    const thumb = prod.picture_thumb || noImage;
    const img = prod.picture || false;

    return (<>
      {prod && prod._id && <Helmet>
        <title>{appText.titlePrefix} - {prod.seo_title || `${prod.category.title} - ${prod.title}`}</title>
        <meta name="description" content={prod.seo_desc || prod.short_description || prod.seo_title} />
      </Helmet>}

      <div className="product-page">
        <Button onClick={() => this.props.history.goBack()} type="default" className="back-button" size="large"><Icon icon="angle-left" /> Back</Button>
        <div style={{ height: '10px' }} />

        <div className="prod-body">
          <Row className="prod-body-contents">
            <Col className="prod-body-gallery" flex="50%" align="center">
              {prod.available_qty < 1 && <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', position: 'absolute', width: '100%', top: '-0px', left: '-0px', height: '50px', zIndex: 999, display: 'flex', alignItems: "center", justifyContent: "center" }}>
                <div style={{ backgroundColor: '#CCC', borderRadius: '10px', margin: '10px', fontSize: '18px', padding: '0px 10px' }}>Sold Out</div>
              </div>}
              <ProductGalleryScroller data={[{ thumb: thumb, img: img }, ...prod.gallery]} hideScrollbar showDirectPop={false} />
            </Col>

            <Col flex="auto" className="prod-body-contents-inner">
              <div className="prod-title">{prod.title}</div>

              {prod.attributes_v2 && <div className="prod-attributes">
                {prod.attributes_v2.filter(o => (o.show_in_store > 0)).map((o, i) => (<span key={i} className="attribute-item">{i > 0 ? ', ' : null}{o.value} {o.title}</span>))}
              </div>}

              {prod.price_was > 0 && <div><div className="price-cut">Price Cut</div></div>}
              <div className="prod-price">
                {prod.price_was > 0 && <div className="prod-price-was">{currency} {prod.price_was}</div>}
                <div className="prod-price-is">{currency} {prod.price}</div>
              </div>
              {prod.available_qty > 0 && <AddToCartButton prod={prod} qty={0} style={{ marginBottom: "20px" }} />}
              {prod.available_qty < 1 && <span style={{marginBottom:"10px", display:"inline-block"}}><NotifyMeButton prod={prod} user={this.props.user} /></span>}

              {prod.details &&
                <div className="prod-details">
                  <div className="prod-details-title">Product details for {prod.title}</div>
                  <div className="prod-details-contents">
                    <span dangerouslySetInnerHTML={{ __html: prod.details }} />
                  </div>
                </div>
              }

            </Col>
          </Row>

        </div>
      </div>

    </>)

  }

}

MainPage.propTypes = {
  // toggleDrawer: PropTypes.func.isRequired
  // prop: PropTypes.type.isRequired
  // onEditRecord: PropTypes.func.isRequired
}


const WithApollo = compose(

  graphql(GET_PROD, {
    options: (props) => {
      return {
        variables: { slug: props.match.params.slug },
        fetchPolicy: "no-cache", //no-cache, cache-first, network-only, cache-only, cache-and-network
      };
    },
    props: ({ ownProps, data }) => {
      const { loading, productBySlug, error } = data;
      // console.log('====================================');
      // console.log("GET_PROD > data: ", data);
      // console.log('====================================');

      if (error) console.log(__error("error"), error);

      return { loading, productBySlug, queryErrors: error, }
    },
  }),


)(MainPage);

const mapStateToProps = state => {
  return ({ settings: state.box_storefront, user: (state.user && state.user.user) });
}
export default connect(mapStateToProps)(WithApollo);
// export default WithApollo;
