import React from 'react';
import { Link, Redirect, withRouter, useParams } from 'react-router-dom';
import { SearchField } from 'Common/components/Form';
import { AlgoliaSearchBox } from 'Modules/AlgoliaSearch';


const SiteSearch = props => {
    return <AlgoliaSearchBox style={{ marginTop: 10 }} />

    // let { kw } = useParams();

    // const onSearchPress = txt => {
    //     if (!txt || txt.length < 1) return;
    //     let searchPath = `/search/${txt}`;
    //     // setRedirectTo(searchPath);
    //     window.location = searchPath;
    // }

    // return (
    //     <div style={{ width: "100%", paddingLeft:"20px" }}><SearchField enterButton style={{ maxWidth: "800px", width: "100%", ...props.style }} size="large"
    //             onSearch={onSearchPress}
    //             defaultValue={kw}
    //         name="keywords" placeholder="Search 5000+ products..." /></div>
    // )
}

export default SiteSearch;