import React, { useEffect } from 'react'
// import { graphql } from '@apollo/client/react/hoc';
import { graphql, withApollo } from 'react-apollo';

import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { connect } from "react-redux";
import { message, Modal } from 'antd';

import { __error } from 'Common/scripts/consoleHelper';
import { Loader, Button, DevBlock } from 'Common/components'
import { Select } from 'Common/components/Form';
import { addressSelect } from '../redux/actions'
import AddressForm from 'Modules/User/components/AddressForm'



const USER_ADDRESSES = loader('src/graphqls/user/userAddresses.graphql');

export const _AddressSelect = props => {
    const [showAddressForm, setShowAddressForm] = React.useState(false)
    const [busy, setBusy] = React.useState(false);
    const [userAddresses, set_userAddresses] = React.useState(null);

    useEffect(() => {
        fetchData();
    }, [props])


    const toggleAddressForm = args => {
        setShowAddressForm(!showAddressForm);
    }

    const onAddressAdded = newAddress => {
        if (newAddress.error) return false;

        let _userAddresses = userAddresses ? userAddresses.slice() : [];
        _userAddresses.push(newAddress);
        set_userAddresses(_userAddresses);
        
        setBusy(false);
        toggleAddressForm();
        
        props.addressSelect(newAddress);

    }

    const fetchData = async (args) => {
        if (!props.user._id) return;

        const _addresses = await props.client.query({
            query: USER_ADDRESSES,
            variables: { id: Number(props.user._id) },
            fetchPolicy: "no-cache",
            // reducer: (previousResult, action, variables) => {
            //     console.log('reducer!!!!!', previousResult, action, variables);
            // }
        })
        .then(({ data }) => (data.userAddresses))
        .catch(err => {
            console.log(__error("Query Error: "), err);
            return { error: { message:"Query Error"  }}
        });

        if (_addresses.error) message.error(_addresses.error.message);
        else {

            if (!props?.order?.address?._id && _addresses && _addresses.length>0){
                let default_address = _addresses.find(o => o.is_default=='1');
                if (!default_address) default_address = _addresses[0];

                props.addressSelect(default_address);
            }
            
            // check if the selected address exists/
            if (props?.order?.address?._id && !_addresses.find(o => o._id == props?.order?.address?._id)){
                props.addressSelect(null);
            }

            set_userAddresses(_addresses);


        }
    }

    if (busy) return <Loader loading={true} />


    if (!userAddresses) return (<>
        <div style={{ fontSize: "16px" }}>
            No user address found! <a className='a' onClick={(e) => {
                e.preventDefault();
                toggleAddressForm();
            }}>Please add your address</a>
        </div>

        <Modal title="" visible={showAddressForm} width={620} onCancel={toggleAddressForm} footer={null} bodyStyle={{ padding: "0px" }}>
            <AddressForm onCancel={toggleAddressForm} user_id={props.user._id} onSuccess={onAddressAdded} />
        </Modal>
    </>)


    return(<>
        <label style={{ fontWeight: "600" }}>Delivery address</label>

        {userAddresses && <>
            <Select width="300px" loading={busy} disabled={busy} name="address" placeholder="Select delivery address"
                onChange={(v) => props.addressSelect(userAddresses.find(o => o._id == v))}
                defaultValue={props?.order?.address?._id}
                value={props?.order?.address?._id}
                data={!userAddresses ? undefined : userAddresses.map(item => {
                    return { _id: item._id, title: `${item.label} ::: ${item.full_address}` }
                    // return { _id: JSON.stringify(item), title: `${item.label} ::: ${item.full_address}` };
                })}
                dropdownRender={menu => {
                    return (<div>
                        {menu}
                        <hr />
                        <div style={{ margin: "5px" }}><Button type="default" theme="blue-outline" block onClick={toggleAddressForm}>Add another address</Button></div>
                    </div>)
                }}
            />

            {(props?.order?.address?._id && props?.order?.address?.delivery_instructions) && <div style={{ maxWidth: "300px", marginTop: "5px" }}>
                {props.order.address.delivery_instructions}
            </div>}
        </>}



        <Modal title="" visible={showAddressForm} width={620} onCancel={toggleAddressForm} footer={null} bodyStyle={{ padding: "0px" }}>
            <AddressForm onCancel={toggleAddressForm} user_id={props.user._id} onSuccess={onAddressAdded} />
        </Modal>

    </>)
   
    /*
    return (<>
        <Modal title="" visible={showAddressForm} width={620} onCancel={toggleAddressForm} footer={null} bodyStyle={{padding:"0px"}}>
            <AddressForm onCancel={toggleAddressForm} user_id={props.user._id} onSuccess={onAddressAdded} />
        </Modal>

        <label style={{fontWeight:"600"}}>Delivery address</label>
        <Select width="300px" loading={busy || loading} disabled={busy || loading} name="address" placeholder="Select delivery address"
            onChange={onAddressSelect}
            defaultValue={default_address}
            data={userAddresses.map(item => {
                return { _id: JSON.stringify(item), title: `${item.label} ::: ${item.full_address}` };
            })}
            dropdownRender={menu => {
                return(<div>
                    {menu}
                    <hr />
                    <div style={{ margin: "5px" }}><Button type="default" theme="blue-outline" block onClick={toggleAddressForm}>Add another address</Button></div>
                </div>)
            }}
        />
        {selectedAddress && selectedAddress.delivery_instructions && <div style={{maxWidth:"300px", marginTop:"5px"}}>{selectedAddress.delivery_instructions}</div>}

    </>)
    */
}

const AddressSelect = compose(
    graphql(USER_ADDRESSES, {
        options: props => {
            return { 
                variables: { id: props.user._id },
                notifyOnNetworkStatusChange: true,
                // onCompleted: (e) => console.log(">>>>>>>>>>> onCompleted: ", e),
                onError: (e) => console.log(__error("QUERY ERROR:"), e),
            };
        },
        props: (apolloProps) => {
            // console.log("apolloProps: ", apolloProps);
            const { ownProps, data } = apolloProps;
            const { loading, userAddresses, error, fetchMore, subscribeToMore, refetch } = data;

            if (error) console.log(__error("error"), error);

            const loadMoreRows = (filter) => {

                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    return fetchMoreResult.userAddresses
                }

                return fetchMore({ variables: filter, updateQuery: updateQuery });
            }

            return { loading, userAddresses, queryErrors: error, subscribeToMore, loadMoreRows, refetch }
        },
    }),
)(_AddressSelect);


const mapStateToProps = state => {
    return ({ order: state.order });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    addressSelect: (payload) => dispatch(addressSelect(payload)),
})
export default connect(mapStateToProps, mapDispatchToProps)(withApollo(_AddressSelect));
// export default connect(mapStateToProps, mapDispatchToProps)(AddressSelect);
// export default AddressSelect;
