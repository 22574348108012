import React from 'react'
import { Route, Switch } from 'react-router-dom';
import { E404, LayoutWrapper } from "Layout_v1";
import 'Layout_v1/styles/home.scss'

import MainPage from './containers';
import { ROOT } from './'; 

const menuArray = props => ([
    { path: `${ROOT}`, exact: false, title: 'Search Page', component: MainPage},
    // { path: `${ROOT}`, exact: true, icon: 'caret-right', title: 'Innter Home', component: MainPage},
]);

export const Home = props => {
    // console.log("props.match.params: ", props.match.params);
    
    let routs = menuArray();
    return (
        <LayoutWrapper topMenu={null} menuOverride={null}>
            <Switch>
                {routs.map((item, i) => {
                    if (!item.component) return null;
                    return <Route exact={item.exact} path={item.path} component={item.component} key={i} />
                })}
                <Route key={999} component={E404} />
            </Switch>
        </LayoutWrapper>

    )
}

export default Home;
