import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { Alert, Row, Col, Divider, message, Table as _Table, Popconfirm, Modal } from 'antd'
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import _ from 'lodash'
import { Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import { __error } from 'Common/scripts/consoleHelper'
import { Table, Avatar, DevBlock, Loader, Heading, Button, Icon, IconButton } from 'Common/components'
import { E404 } from 'Layout_v1';
import { mongoToDate } from 'Common/scripts/Functions';


const GET_ODER_FOR_REORDER = loader('src/graphqls/order/get_order_for_reorder.graphql');
const REORDER = loader('src/graphqls/cart/makeReorder.graphql');

const MakeReorder = ({ loading, get_order_for_reorder, match, history, makeReorder, currency, cart }) => {
    const [busy, setBusy] = React.useState(false)
    const [error, setError] = React.useState(null);
    const [order, setOrder] = React.useState(null)
    const [success, setSuccess] = React.useState(false)
    const [showConfirmation, setShowConfirmation] = React.useState(false)

    useEffect(() => {
        setOrder(get_order_for_reorder);
    }, [get_order_for_reorder])


    const doReorder = (action) => {
        const { _id } = get_order_for_reorder;
        
        // .filter(o=>o.status!='published')
        const orderData = order.items.map(item=>{
            return {
                _id:item._id,
                qty:item.qty,
            }
        }).filter(o=>o.qty>0)

        if (!orderData || orderData.length<1){
            message.error("No item found to put in cart.")
            return;
        }

        setBusy(true)
        setError(false)


        makeReorder(action, orderData).then(r=>{
            const response = r.data.makeReorder;
            // setBusy(false);

            if (response.error){
                console.log(__error("ERROR: "), response.error.message);
                message.error(response.error.message);
                setBusy(false);
                setError(response.error.message);
                return;
            }

            message.success("Order has been added to your cart successfuly");
            setSuccess(true);
            // window.location.href = process.env.REACT_APP_HOST_URL;

        }).catch(err=>{
            console.log(__error("ERROR: "), err);
            message.error("Unable to complete your request at the moment!");
            setBusy(false);
            setError("Unable to complete your request at the moment!");
        })

    }

    const updateQty = (action, item_id) => {
        let item = order.items.find(o => o._id == item_id);

        if (action=="add"){
            item.qty += 1;
        }
        if (action=="remove"){
            item.qty -= 1;
        }
        let items = order.items.map(o=>{
            if (o._id == item_id) return item;
            return o;
        })

        // re-calculate totalmem, savings
        let grandTotal = 0;
        let savings = 0;
        items.forEach(item => {
            if (item.status != 'published') return;

            grandTotal += item.qty * item.price;
            if (item.price_was > item.price) {
                savings += (item.price_was > 0) ? Number((item.price_was - item.price) * item.qty) : 0;
            }
            // savings += (item.price_was && item.price_was > 0) ? Number(item.qty * item.price) - Number(item.qty * item.price_was) : 0;
        });


        setOrder({ ...order, savings, grandTotal, items });
    }

    const renderQty = (qty, rec) => {
        let disabled_remove = false;
        if (qty <= 0) disabled_remove = true;

        let disabled_add = false;
        if (!disabled_add && rec.limit_max_qty_in_cart > 0 && rec.limit_max_qty_in_cart <= qty) disabled_add = true;

        if (rec.status != 'published') return <div style={{ fontSize: "18px", color:"red" }}>Out Of Stock</div>

        return (<>
            <Row align="center">
                <Col><IconButton disabled={disabled_remove} onClick={() => updateQty('remove', rec._id)} icon="minus" /></Col>
                <Col style={{fontSize:"18px", width:"30px"}}>{qty}</Col>
                <Col><IconButton disabled={disabled_add} onClick={() => updateQty('add', rec._id)} icon="plus" /></Col>
            </Row>
        </>)
    }

    const columns = [
        { title: 'Products', dataIndex: 'title', render: (text, record) => {
            return (<Row gutter={[12, 0]} align="middle">
                <Col flex="50px"><Avatar size={40} shape="square" src={record.picture_thumb ? `${process.env.REACT_APP_DATA_URL}/${record.picture_thumb}` : null} icon={<Icon icon="image" />} /></Col>
                <Col>
                    <div>{record.title}</div>
                    {record.status == 'published' && <>
                        {(record.price_was > 0) && <div style={{ textDecoration: "line-through", color: "#999" }}>WAS {currency} {record.price_was}</div>}
                        {(record.qty > 0 && record.price_was > 0 && record.price_was > record.price) && <div style={{ color: "green" }}>You saved {currency} {(record.price_was - record.price) * record.qty}</div>}
                    </>}
                </Col>
            </Row>)
        } },
        { title: 'Qty', dataIndex: 'qty', align: "center", width: "150px", render:renderQty },
        // { title: 'Price Was', dataIndex: 'price_was', align: "center", width: '60px' },
        { title: 'Price', dataIndex: 'price', align: "center", width: '60px' },
        { title: 'Total', dataIndex: 'price', align: "center", width: '60px', render:(__, rec)=>(rec.price*rec.qty) },
        // { title: 'Status', dataIndex: 'status', align: "center", width: '100px' },
    ];

    if (!match.params.serial) return <Alert message="Error" description="Missing Serial" type="error" showIcon />
    if (loading) return <Loader loading={true} />
    if (!order) return <Alert message="Error" description="No record found" type="error" showIcon />
    if (order.error) return <Alert message="Error" description={order.error.message} type="error" showIcon />

    return (<>
        {success && <Redirect to="/" />}

        <Row align="middle">
            <Col><Button type="default" className="back-button" size="large" onClick={()=>history.goBack()}><Icon icon="angle-left" style={{marginRight:"5px"}} /> Back</Button></Col>
            <Col flex="auto"></Col>
            {(order && order.created_at) && <Col style={{ color:"#999", fontSize:"18px" }}><>Order Date: {mongoToDate(order.created_at).format("Do MMM YYYY")}</></Col>}
        </Row>

        <hr />
        {error && <Alert message="Error" description={error} type="error" showIcon />}

        <Table
            columns={columns}
            dataSource={order.items}
            pagination={false}
            rowClassName={
                (record) => {
                    console.log("record.delivery_status: ", record.delivery_status)
                    return (record.status != 'published') ? 'cancled-table-row' : ""
                }
            }
            summary={pageData => {
                return (<>
                    <_Table.Summary.Row style={{ backgroundColor: "#EEE", fontSize: "16px" }}>
                        <_Table.Summary.Cell index={0} colSpan={2}><div style={{ textAlign: "right", fontWeight: "bold" }}>Total payable</div></_Table.Summary.Cell>
                        {/* <_Table.Summary.Cell index={1}><div style={{ textAlign: "center" }}>{order.grandTotal}</div></_Table.Summary.Cell> */}
                        <_Table.Summary.Cell index={2} colSpan={2}><div style={{ textAlign: "left" }}>{currency} {order.grandTotal}</div></_Table.Summary.Cell>
                    </_Table.Summary.Row>
                    {/* {order.savings > 0 && 
                        <_Table.Summary.Row style={{ backgroundColor: "#EEE", fontSize: "16px" }}>
                            <_Table.Summary.Cell index={0} colSpan={2}><div style={{ textAlign: "right", fontWeight: "bold", color:"green" }}>Your saved on this order</div></_Table.Summary.Cell>
                            <_Table.Summary.Cell index={1} colSpan={2}><div style={{ textAlign: "left", color: "green" }}>{currency} {order.savings}</div></_Table.Summary.Cell>
                        </_Table.Summary.Row>
                    } */}
                </>)
            }}
        />

        <div style={{height:"10px"}} />

        <Row align="center">
            <Col>
                {cart.items.length>0 ? 
                    <Button loading={busy} disabled={busy} onClick={() => setShowConfirmation(true)} size="large">Add To Cart</Button>
                    : <Button loading={busy} disabled={busy} onClick={() => doReorder('add')} size="large">Add To Cart</Button>
                }
            </Col>
        </Row>
        <p>&nbsp;</p>

        <Modal
            visible={showConfirmation}
            title={false}
            // onOk={() => doReorder('add')}
            onCancel={() => setShowConfirmation(false)}
            footer={[
                // <Button type="default" key="back" onClick={() => setShowConfirmation(false)}>Cancel</Button>,
                <Button key="add" type="default" onClick={() => doReorder('add')}>Add to existing cart</Button>,
                <Button key="reset" type="primary" onClick={() => doReorder('reset')}>Load as new cart</Button>,
            ]}
        >
            <Row style={{flexWrap:"nowrap"}}>
                <Col style={{marginRight:"15px"}}><Icon icon="exclamation-triangle" color="orange" size="2x" /></Col>
                <Col>Please choose one of the below options</Col>
                <Col style={{width:"50px"}} />
            </Row>
        </Modal>

    </>)
}

MakeReorder.propTypes = {
    // toggleDrawer: PropTypes.func.isRequired
    // prop: PropTypes.type.isRequired
    // onEditRecord: PropTypes.func.isRequired
}

const WithApollo = compose(

    graphql(GET_ODER_FOR_REORDER, {
        options: (props) => {
            return {
                variables: { serial: props.match.params.serial },
                fetchPolicy: "no-cache", //no-cache, cache-first, network-only, cache-only, cache-and-network
            };
        },
        props: ({ ownProps, data }) => {
            const { loading, get_order_for_reorder, error } = data;

            if (error)  console.log(__error("error"), error);

            return { loading, get_order_for_reorder, queryErrors: error, }
        },
    }),

    graphql(REORDER, {
        props: ({ mutate }) => ({
            makeReorder: (action, orderData) => mutate({
                variables: { action, orderData }
            }),
        })
    }),


)(MakeReorder);



// if (!this.props.user || !this.props.user._id) return <Redirect to="/" />

const ModuleCheck = (props) => {
    if(!props.user || !props.user._id) return <E404 />
    return <WithApollo {...props} />
}
const mapStateToAvatarPopProps = state => {
    return ({ 
        cart: state.cart,
        user: (state.user && state.user.user) || {},
        currency: state.box_storefront.currency
    });
}
const WithRedux = connect(mapStateToAvatarPopProps)(ModuleCheck);
export default WithRedux;
