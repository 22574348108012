import { __error } from 'Common/scripts/consoleHelper';
import { UPDATE_SETTINGS } from '../box_storefront.actions.js';

const INITIAL_STATE = {
  deliveryCharges: 0,
  currency: 'RS',
  min_order_amount: 0, max_order_amount: 0
}


const updateSettings = (state, payload) => {
  return { ...state, ...payload }
}

// const flushCart = (state, nothing_expected) => {
//   return { ...INITIAL_STATE }
// }

export const box_storefront_reducer = (state = INITIAL_STATE, {type, payload} ) => {
  switch (type) {
    case UPDATE_SETTINGS:
      return updateSettings(state, payload);
      break;
    default:
      return state;
  }
}

export default { box_storefront:box_storefront_reducer };
