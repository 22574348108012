import React from 'react'
import { connect } from "react-redux";
import { Badge } from 'antd';
import { useMediaQuery } from 'react-responsive'
import { Button, Icon } from 'Common/components';
import CartDrawer from './CartDrawer';
// import CartDrawerBK from './CartDrawer_BK';
import CartSubncriptionWrapper from './CartSubncriptionWrapper';


export const CartIndex = props => {
    const [showDrawer, setShowDrawer] = React.useState(false);
    // const isTabletOrMobileDevice = useMediaQuery({ query: '(max-device-width: 1224px)' })
    const isSmallDevice = useMediaQuery({ query: '(max-device-width: 438px)' })

    
    const { settings, user, cart, hidden } = props;
    const totalItems = cart && cart.items ? cart.items.length : 0

    return(<>
        {!hidden && 
            <Button className='shoppingcartButton' theme="light-gray" size="large" onClick={() => setShowDrawer(!showDrawer)}>
                <Badge count={totalItems}><Icon style={{ fontSize: '1.5em' }} icon="shopping-cart" /></Badge>
                <span className="txt"> {settings.currency} {Number(cart.totalPrice || 0).toFixed(2)}</span> <span style={{ fontWeight: 100, fontSize: "12px" }}><Icon icon="chevron-down" /></span>
            </Button>
        }

        {user && user._id && 
            <CartSubncriptionWrapper visible={showDrawer} onClose={() => setShowDrawer(false)} isSmallDevice={isSmallDevice} />
        }
        {(!user || !user._id) &&
            <CartDrawer visible={showDrawer} onClose={() => setShowDrawer(false)} isSmallDevice={isSmallDevice} />
        }

    </>)

}

const mapStateToProps = state => {
    return ({ 
        user: (state.user && state.user.user) || {}, 
        cart: state.cart, settings: state.box_storefront 
    });
}
// const mapDispatchToProps = (dispatch, ownProps) => ({
//     logoutUser: () => dispatch(logoutUser()),
//     updateUser: (payload) => dispatch(updateUser(payload)),
// })
export default connect(mapStateToProps)(CartIndex);
