import React from 'react'
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { connect } from "react-redux";
import RenderCart from './RenderCart'
import { Table, Heading, Button, DevBlock } from 'Common/components'
import { calculateOrder } from 'Common/scripts/Functions';


const RenderInvoice = ({ order, settings, hideTotal }) => {
    const { discountVoucher, pickup_data, items, discount_value, totalPrice, totalTax, deliveryCharges, grandTotal, paymentMethod, serial, total_savings } = order;
    const { currency } = settings;

    let calculations = calculateOrder(order);

    return (<>
        {/* <DevBlock obj={calculations.calculations} /> */}

        <div className="grid-block">
            <Heading>Order - {serial} - ({order.status})</Heading>

            <div style={{ padding: "0 10px", marginTop: "-10px" }}>
                <RenderCart items={calculations.items} 
                    order={order} 
                    // filter={(item, order) => item.delivery_status != 'canceled'}
                    filter={(item) => (item.qty > 0)}
                    />
            </div>

            {!hideTotal && <>
                <div style={{ padding: "10px 0px", margin:"0 10px", marginTop: "0px", border: "1px solid #EEE" }}>
                    <Row gutter={[0, 12]} style={{ border: "0px solid #EEE", padding: "0px 10px" }}>
                        <Col flex="200px">Subtotal</Col>
                        <Col flex="auto">{calculations.calculations.total_items} items</Col>
                        <Col flex="130px">{currency} {calculations.calculations.sub_total}</Col>
                    </Row>

                    {discountVoucher && discountVoucher._id &&
                        <Row gutter={[0, 12]} style={{ border: "0px solid #EEE", padding: "0px 10px" }}>
                            <Col flex="200px">Discount applied</Col>
                            <Col flex="auto">({discountVoucher.discount_value} {discountVoucher.discount_type == "fix" ? currency : discountVoucher.discount_type})</Col>
                            <Col flex="130px">{currency} {calculations.calculations.discount}</Col>
                        </Row>
                    }

                    <Row gutter={[0, 12]} style={{ border: "0px solid #EEE", padding: "0px 10px" }}>
                        <Col flex="200px">Tax</Col>
                        <Col flex="auto">{totalTax < 1 && "does not apply"}</Col>
                        <Col flex="130px">{currency} {calculations.calculations.tax}</Col>
                    </Row>

                    <Row gutter={[0, 12]} style={{ border: "0px solid #EEE", padding: "0px 10px" }}>
                        <Col flex="200px">Delivery Charges</Col>
                        <Col flex="auto">{totalTax < 1 && "does not apply"}</Col>
                        <Col flex="130px">{currency} {calculations.calculations.delivery_charges}</Col>
                    </Row>

                    <Row gutter={[0, 12]} style={{ border: "0px solid #EEE", padding: "0px 10px" }}>
                        <Col flex="200px">Total Savings</Col>
                        <Col flex="auto" />
                        {/* <Col flex="130px">RS {calculations.calculations.savings} ({Number((calculations.calculations.savings / calculations.calculations.sub_total) * 100).toFixed(2)}%)</Col> */}
                        <Col flex="130px">RS {order.total_savings} ({Number((order.total_savings / calculations.calculations.sub_total) * 100).toFixed(2)}%)</Col>
                    </Row>

                    <Row gutter={[0, 12]} style={{ border: "0px solid #EEE", padding: "0px 10px" }}>
                        <Col flex="200px">Payment Method</Col>
                        <Col flex="auto" />
                        <Col flex="130px">{paymentMethod}</Col>
                    </Row>

                </div>
                <Row gutter={[0, 12]} style={{ backgroundColor:"#f0f2f5", border: "1px solid #EEE", padding: "0px 10px", fontWeight:"bold" }}>
                    <Col flex="200px">Total</Col>
                    <Col flex="auto" />
                    <Col flex="140px">{currency} {calculations.calculations.grand_total}</Col>
                </Row>
            </>}

            {items.filter(o => o.delivery_status == 'canceled').length>0 &&
                <div style={{ padding: "0 10px", marginTop: "20px" }}>
                    <div style={{ backgroundColor: "#EEE", textAlign: "center", fontSize: "15px", fontWeight: "bold", padding: "5px", color: "#888" }}>Unavailable Items</div>
                    <RenderCart items={calculations.items} filter={(item) => (item.qty < 1)} />
                </div>
            }


        </div>

    </>)
}
RenderInvoice.propTypes = {
    order: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
    return ({ settings: state.box_storefront });
}
export default connect(mapStateToProps)(RenderInvoice);
// export default RenderInvoice;




