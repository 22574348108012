import React from 'react';
import { ProductList } from 'Layout_v1';
import { connectInfiniteHits, connectStateResults } from 'react-instantsearch-dom';
import { Button } from 'antd';
import { Icon } from 'Common/components';

const Hits = ({ hits, hasMore, refineNext, isSearchStalled }) => (
  <>
    <ProductList
      loading={isSearchStalled}
      data={(hits && hits) || []} total={(hits && hits.length) || 0} />

    <Button disabled={!hasMore} onClick={refineNext} block theme="gray" size="large"><Icon style={{ margin: "3px 5px 0 0" }} icon={"angle-down"} /> More <Icon style={{ margin: "3px 0 0 5px" }} icon={"angle-down"} /></Button>
  </>
);

export const AlgoliaHits = connectInfiniteHits(connectStateResults(Hits));