import React, { Component } from 'react'
import { InstantSearch } from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';

export * from './AlgoliaHits';
export * from './URLSync'; 
export * from './AlgoliaSearchBox'; 

const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
    process.env.REACT_APP_ALGOLIA_API_KEY
);



export const SearchWrapper = ({ children, searchState, createURL, onSearchStateChange }) => {
    return (<>
        <InstantSearch
            searchClient={searchClient}
            indexName={process.env.REACT_APP_ALGOLIA_INDEX_NAME}
            searchState={searchState}
            createURL={createURL}
            onSearchStateChange={onSearchStateChange}
        >
            {children}
        </InstantSearch>
    </>)
}
