import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { CircleSpinner } from './';

// https://ant.design/components/spin/

/***
 * Spin.setDefaultIndicator(indicator: ReactNode)

 * <Spin 
 * spinning={boolean}
 * tip={string}
 * size={small | default | large}
 * wrapperClassName={className}
 * delay={number}
 */

/**
   //  custom icon
   import { LoadingOutlined } from '@ant-design/icons';
   const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
   <Spin indicator={antIcon} />
 */

export const Loader = props => {
  const propTypes = {
    loading: PropTypes.bool.isRequired,
  }

  let _props = { ...props };
  delete _props.loading;
  // if (props.loading===true) _props = Object.assign(_props, {spinning:props.loading})
  _props = Object.assign(_props, {spinning:props.loading || false})
  return <Spin {..._props} indicator={<CircleSpinner size={Number(props.size) || 50} />} />
  // return <Spin {..._props} />
};
export default Loader;