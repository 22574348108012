import React, { Component } from 'react'
import PropTypes from 'prop-types';
// import { graphql, withApollo } from '@apollo/client/react/hoc';
import { graphql, withApollo } from 'react-apollo';

import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { message, Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { appText } from 'configs';

import { __error } from 'Common/scripts/consoleHelper';
import { Loader, Button, Icon } from 'Common/components';
import { constructCategoryArray } from 'Common/scripts/Functions';

const LIST_DATA = loader('src/graphqls/product_cat/productCats.graphql');


export const RelatedCategories = props => {
    // const [busy, setBusy] = React.useState(true);
    const [catsArray, setCatsArray] = React.useState(null);
    const [catSlug, setCatSlug] = React.useState(null);
    const [parentsCats, setParents] = React.useState([]);
    const [currentCat, setCurrentCat] = React.useState([]);
    const { loading, productCats, cat_slug } = props;

    const getParentTree = (parent_cat_id, arr=[]) => {
        let c = props.productCats.find(o => o._id == parent_cat_id);
        if (c) {
            arr.push(c);
            if (c.parent_cat_id) return getParentTree(c.parent_cat_id, arr);
        }
        return arr;
    }

    if (loading) return <Loader loading={true} />
   
    if (productCats && catSlug != cat_slug) {
        let _currentCat = productCats.filter(o => o.slug == cat_slug)[0]
        if (!_currentCat) return <div>Product Category Unavailable</div>;
        setCurrentCat(_currentCat)
        
        if (_currentCat) {
            let theTree = [];
            if (_currentCat.parent_cat_id) {
                theTree = getParentTree(_currentCat.parent_cat_id);
                if (theTree) theTree.reverse().push(_currentCat);
            } else {
                theTree.push(_currentCat);
            }
            setParents(theTree);            

            let children = constructCategoryArray(productCats, _currentCat._id);
            setCatsArray(children);
            setCatSlug(cat_slug);
        }
    }

    return (<>
        {currentCat && currentCat._id && <Helmet>
            <title>{appText.titlePrefix} - {currentCat.seo_title || currentCat.title}</title>
            <meta name="description" content={currentCat.seo_desc || currentCat.title} />
        </Helmet>}

        
        <Breadcrumb style={{marginBottom:"10px"}}>
            <Breadcrumb.Item><Link to="/"><Icon icon="home" /></Link></Breadcrumb.Item>
            {/* <Breadcrumb.Item><span>Categories</span></Breadcrumb.Item> */}
            {parentsCats.map((item, i) => {
                return <Breadcrumb.Item key={i}><Link to={`/category/${item.slug}`}>{item.title}</Link></Breadcrumb.Item>
            })}
            {/* {currentCat && <Breadcrumb.Item><span>{currentCat.title}</span></Breadcrumb.Item>} */}
        </Breadcrumb>

        {catsArray && catsArray.length > 0 &&
            <div className="related-cats2">
                <h6><span>{productCats.filter(o => o.slug == cat_slug)[0].title} <small>({props.count || 0})</small></span></h6>
                <h3>Related Categories</h3>
                {catsArray.map((item, i) => {
                    return <Button key={i} theme="gray" size="large"><Link to={`/category/${item.slug}`}>{item.title}</Link></Button>
                })}
            </div>
        }
    </>)
}

RelatedCategories.propTypes = {
    parent_cat_id: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
    ]),
    cat_slug: PropTypes.string.isRequired,
    // onEditRecord: PropTypes.func.isRequired
}


const WithApollo = compose(

    graphql(LIST_DATA, {
        options: props => {
            // return { variables: { filter: JSON.stringify({ parent_cat_id:null }) } };
            return {
                variables: { filter: JSON.stringify({ status: "enabled" }) },
                fetchPolicy: "cache-first",
            };
        },
        props: ({ ownProps, data }) => {
            const { loading, productCats, error, fetchMore, subscribeToMore } = data;

            if (error) console.log(__error("error"), error);

            const loadMoreRows = (filter, others) => {

                let vars = { filter: filter, others: others };

                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    return fetchMoreResult.productCats
                }

                return fetchMore({ variables: vars, updateQuery: updateQuery, fetchPolicy: "no-cache" });
            }

            return { loading, productCats, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),

)(RelatedCategories);

export default WithApollo;
