import React, { Component } from 'react'
// import { graphql, withApollo } from '@apollo/client/react/hoc';
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { message, Alert, Row, Col } from 'antd';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import { Button } from 'Common/components'
import { FormComponent, FormField, rules, composeValidators } from 'Common/components/Form'
import { __error } from 'Common/scripts/consoleHelper'
import { updateUser } from '../redux/actions'
import { verificationTimeout } from 'configs';
import { nextTabInput, timestamp } from 'Common/scripts/Functions';
import { ROOT, MODULE } from '../constants'

const VERIFY_PHONE_CODE = loader('src/graphqls/user/verifyPhoneCode.graphql');
const RESEND_PHONE_CODE = loader('src/graphqls/user/resendPhoneCode.graphql');
const UPDATE_PHONE = loader('src/graphqls/user/updateUserPhone.graphql');




class TimerComponent extends Component {
    initialTime = 10;
    state = {seconds:0, timerStarted:false}
    interval = false;

    constructor(props){
        super(props);
        this.intervalCalled = this.intervalCalled.bind(this);
    }

    // static getDerivedStateFromProps(props, state) {
    //     console.log("getDerivedStateFromProps()");
        
    //     if (props.timerStarted !== state.timerStarted) {
    //         this.startTimer()
    //         return { timerStarted: props.timerStarted };
    //     }
    //     return null;
    // }

    componentDidUpdate(prevProps) {
        // console.log("componentDidUpdate()");
        // console.log("prevProps: ", prevProps);
        // console.log("this.props: ", this.props);
        if (prevProps.timerStarted !== this.props.timerStarted) {
            if (this.props.timerStarted) this.startTimer();
            if (!this.props.timerStarted) this.stopTimer();
        }
    }

    componentDidMount(){
        // this.setState({ timerStarted: true, seconds: this.initialTime })
        if (this.props.timerStarted) this.startTimer()
    }
    componentWillUnmount(){
        this.stopTimer();
    }

    intervalCalled(){
        // console.log("intervalCalled()", this.state.seconds);
        if (this.state.seconds<=0){
            // this.setState({ timerStarted: false })
            this.stopTimer();
            if (this.props.onSuccess) this.props.onSuccess();
            return;
        }
        this.setState({ seconds: this.state.seconds-1 })
    }

    startTimer(){
        console.log("startTimer()");
        
        const { seconds, timerStarted } = this.state;
        if (timerStarted && this.interval) return;

        this.setState({ seconds: this.props.initialTime || this.initialTime, timerStarted:true });
        this.interval = setInterval(this.intervalCalled, 1000);
        // this.interval = setInterval(() => this.setState({ seconds: Number(seconds)-1 }), 1000);
    }
    stopTimer = () => {
        // console.log("stopTimer()");
        this.setState({ seconds: 0, timerStarted:false });
        clearInterval(this.interval);
    }

    render() {
        const { timerStarted, seconds } = this.state;
        // console.log(timerStarted, seconds);
        
        // if (!timerStarted) return null;

        return (<div style={{ ...this.props.style }}>{!timerStarted ? 'Verification code Expired' : `Expires in ${this.state.seconds} seconds`}</div>)
    }
}


const PhoneChangeForm = ({ phone, back, onPhoneChanged }) => {
    const [loading, setLoading] = React.useState(false);

    const onSubmit = values => {
        setLoading(true);
        onPhoneChanged(values).then(r=>{
            setLoading(false);
        })
    }

    return (<>
        <div style={{ padding: "10px", alignItems: "center", display: "flex", flexDirection: "column", textAlign: "center" }}>
            <h4>Change Phone Number</h4>
            <FormComponent onSubmit={onSubmit} id='PhoneChnageForm' loading={loading} debug={true} style={{ width: "350px", marginBottom: "0px" }} fields={{ phone }}
                form_render={({ values }) => {
                    return <>
                        {/* <FormField type="text" placeholder="New new phone number" name="phone" validate={rules.required} /> */}
                        <FormField
                            placeholder="+92 (3XX) XXX XX XX"
                            name="phone"
                            type="mask"
                            mask="(0999) 999-99-99"
                            label="Mobile Number (verification will require)"
                            validate={composeValidators(
                                rules.required,
                                rules.minChar(11, (val) => val.replace(/_/, "")),
                                rules.maxChar(11, (val) => val.replace(/_/, "")),
                            )}
                        />
                        <Row style={{ margin: "10px 0" }}>
                            <Col style={{ paddingRight: '10px' }}><Button onClick={() => back()} type="default" theme="blue-outline" size="large">Back</Button></Col>
                            <Col flex="auto"><Button disabled={phone==values.phone} type="primary" htmlType="submit" theme="blue" block size="large">Chnage Phone</Button></Col>
                        </Row>

                    </>
                }}
            />
        </div>
    </>)
}

const PhoneVerificationForm = ({ user, on_showPhoneChange, resendCode, onSubmitVerification, setError }) => {
    const [loading, setLoading] = React.useState(false);
    const [timerStarted, setTimerStarted] = React.useState(true);
    const [enableResend, setEnableResend] = React.useState(false);

    const onSubmit = values => {
        console.log("onSubmit()", values)
        onSubmitVerification(values);
    }

    const onTimerComplete = args => {
        setEnableResend(true);
        setTimerStarted(false);
    }

    const on_resendCode = args => {
        setLoading(true);
        resendCode(args).then(r=>{
            setLoading(false);
            setEnableResend(false);
            setTimerStarted(true);
            if (r){
            }
        });
    }

    const onInput = e => {
        nextTabInput(e, 'VerifecationForm');
    }


    return(<>

        <FormComponent onSubmit={onSubmit} id='VerifecationForm' loading={loading} hideDevBlock={true} style={{ width: "350px", marginBottom: "0px" }} fields={{}}><>
            {/* {!timerStarted && <h3 style={{ color: "red", textAlign:"center !important", width:"100%", border:"1px solid #000" }}>Verification code expired</h3>} */}
            <Row>
                <Col flex="auto"></Col>
                <Col><FormField type="text" disabled={!timerStarted} name="d1" style={{ textAlign: "center" }} width="60px" maxLength={1} validate={rules.required}
                    onInput={onInput} tabIndex={1}
                /></Col>
                <Col><FormField type="text" disabled={!timerStarted} name="d2" style={{ textAlign: "center" }} width="60px" maxLength={1} validate={rules.required}
                    onInput={onInput} tabIndex={2}
                /></Col>
                <Col><FormField type="text" disabled={!timerStarted} name="d3" style={{ textAlign: "center" }} width="60px" maxLength={1} validate={rules.required}
                    onInput={onInput} tabIndex={3}
                /></Col>
                <Col><FormField type="text" disabled={!timerStarted} name="d4" style={{ textAlign: "center" }} width="60px" maxLength={1} validate={rules.required}
                    onInput={onInput} tabIndex={4}
                /></Col>
                <Col flex="auto"></Col>
            </Row>
            <TimerComponent timerStarted={timerStarted} onSuccess={onTimerComplete} initialTime={verificationTimeout} style={{ padding: "10px" }} />

            <Button type="primary" htmlType="submit" theme="blue" disabled={!timerStarted} block size="large" style={{ margin: "10px 0" }}>{timerStarted ? "Verify Registration" : "Verification code expired"}</Button>
        </></FormComponent>

        <Row style={{ width: "350px" }}>
            <Col flex="auto"></Col>
            <Col><Button type="link" disabled={!enableResend} onClick={on_resendCode}>Resend Code</Button></Col>
        </Row>

    </>)
}


const PhoneVerification = props => {
    // const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [showPhoneChange, set_showPhoneChange] = React.useState(false);
    const [user, setUser] = React.useState(props.user || props.new_user);
    // const user = {...props.user};
    // const user = (props?.user?._id) ? props.user : (props?.loggedInUser?.user || false);
    
    const onSubmitVerification = values => {
        // setLoading(true);
        setError(null)
        // const code = `${values.d1}-${values.d2}-${values.d3}-${values.d4}`
        const code = `${values.d1}${values.d2}${values.d3}${values.d4}`

        return props.verifyPhoneCode({ code, _id: user._id }).then(r=>{
            const { verifyPhoneCode } = r.data;

            if (!verifyPhoneCode || verifyPhoneCode.error){
                setError(!verifyPhoneCode ? "Invalid response" : verifyPhoneCode.error.message)
                // setLoading(false);
                return;
            }

            message.success("Verification successfull.");

            props.updateUser(verifyPhoneCode)
            
            if (props.onSuccessCallback) props.onSuccessCallback(verifyPhoneCode)
            // else props.updateUser(verifyPhoneCode);

            // setLoading(false);
            return;

        }).catch(err=>{
            console.log(__error("Query Call ERROR: VERIFY_PHONE_CODE : "), err);
            message.error("Request ERROR");
            setError("Request ERROR")
            return;
        })
    }

    const resendCode = async args => {
        setError(null)
        // setLoading(true);

        return props.resendPhoneCode({ _id: user._id, phone: user.phone, hash:`T${timestamp()}` }).then(r => {
            const { resendPhoneCode } = r.data;

            if (!resendPhoneCode || resendPhoneCode.error) {
                setError(!resendPhoneCode ? "Invalid response" : resendPhoneCode.error.message)
                // setLoading(false);
                return false;
            }

            // setEnableResend(false);
            // setTimerStarted(true);
            props.updateUser(resendPhoneCode);
            message.success("Please check your phone for new verification code.");
            // setLoading(false);
            return true;

        }).catch(err => {
            console.log(__error("Query Call ERROR: VERIFY_PHONE_CODE : "), err);
            message.error("Request ERROR");
            // setLoading(false);
            return false;
        })

    }

    const onPhoneChanged = async (values) => {
        return props.updateUserPhone({ id:user._id, phone: values.phone }).then(r=>{
            if (r?.data?.updateUserPhone?.error?.message){
                message.error(r.data.updateUserPhone.error.message)
            }else{
                if (!props.new_user) props.updateUser({ phone: values.phone });
                setUser({ ...user, phone: values.phone })
            }

            set_showPhoneChange(false)
            return true;
        }).catch(err=>{
            message.error("Unable to update Phone number at the moment")
            // set_newPhoneNumber(values.phone);
            set_showPhoneChange(false);
            return false;
        })

    }

    if (!user || !user._id) return <Redirect to={`/user/sign-in`} />
    if (user && user.phone_confirmed) return <Redirect to={`/user/register/success`} />

    if (showPhoneChange){
        return <PhoneChangeForm onPhoneChanged={onPhoneChanged} back={() => set_showPhoneChange(false)} phone={user.phone} />
    }

    return (<div style={{ padding: "10px", alignItems: "center", display: "flex", flexDirection: "column", textAlign: "center" }}>
        <h4>Phone Number Verification</h4>
        {/* <h1>Enter the 4 digit verification code sent to your phone</h1> */}
        <h1>We just sent you a code via SMS/Email on {user.phone}. Enter it to verify your phone. Please note that SMS delivery can take a minute or more.</h1>
        <div>
            {/* <div>{user.phone}</div> */}
            <Button type="link" onClick={() => set_showPhoneChange(true)} style={{ color: 'blue' }}>Change phone number?</Button>
        </div>
        {error && <Alert showIcon type="error" message={error} description={null} />}

        <PhoneVerificationForm user={user} on_showPhoneChange={() => set_showPhoneChange(!showPhoneChange)} resendCode={resendCode} onSubmitVerification={onSubmitVerification} />

    </div>)

}


const WithApollo = compose(
    
    graphql(VERIFY_PHONE_CODE, {
        props: ({ mutate }) => ({
            verifyPhoneCode: (args) => mutate({
                variables: { ...args }
                // variables: { input: { ...args } }
            }),
        })
    }),

    graphql(RESEND_PHONE_CODE, {
        props: ({ mutate }) => ({
            resendPhoneCode: (args) => mutate({
                variables: { ...args }
                // variables: { input: { ...args } }
            }),
        })
    }),

    graphql(UPDATE_PHONE, {
        props: ({ mutate }) => ({
            updateUserPhone: (args) => mutate({
                variables: { ...args }
                // variables: { input: { ...args } }
            }),
        })
    }),

)(PhoneVerification);
// const WithApollo = withApollo(PhoneVerification)

const mapStateToAvatarPopProps = (state, ownProps) => {
    // if (ownProps?.user) return { user: ownProps.user }

    return { 
        user: state?.user?.user,
        new_user: ownProps.user,
    }

    // return ({ user: state.user ? state.user.user : false });
}
const mapAvatarPopDispatchToProps = (dispatch, ownProps) => ({
    updateUser: (payload) => dispatch(updateUser(payload)),
})
export default connect(mapStateToAvatarPopProps, mapAvatarPopDispatchToProps)(WithApollo);
// export default WithApollo;
