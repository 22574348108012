import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types';
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { Link, Redirect, useHistory, useLocation, useRouteMatch, withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { __error } from 'Common/scripts/consoleHelper';
import { useMediaQuery } from 'react-responsive'
import { isMobile, isTablet, withOrientationChange } from 'react-device-detect';
import { Layout, Menu, Col, Row, Popover, Divider, Affix, Badge, Modal, Alert, message, Input as AntInput } from 'antd';
import { Icon, Button, Loader, Drawer, DevBlock } from 'Common/components';
import LayoutTopMenu from './LayoutTopMenu';
import LayoutSideMenu_Mobile from './LayoutSideMenu_Mobile';
import LayoutTopMenu_affixed from './LayoutTopMenu_affixed';
import PhoneVerification from 'Modules/User/containers/PhoneVerification'
import { sleep } from 'Common/scripts/Functions';
import { logoutUser, updateUser } from 'Modules/User/redux/actions'
import { updateSettings } from 'Store/box_storefront.actions';
import { flushCart } from 'Store/cart.actions'
import Cart from './Cart';
import SiteSearch from './SiteSearch';
import { SearchField } from 'Common/components/Form';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';




const SiteSearch_v2 = () => {
    const url = 'https://analytics.maktech.space/api/global-search';
    const history = useHistory();
    const location = useLocation()
    const inputRef = useRef(null);
    
    let _kw = decodeURIComponent(String(location.hash).substring(1));

    const [val, setVal] = useState(_kw)
    const [list, setList] = useState(null)

    useEffect(() => {
        if (inputRef.current && location.pathname.startsWith('/search')) inputRef.current.focus({ cursor: 'start' });
    }, [inputRef])
    
    
    const onFocus = (e) => {
        // if (location.pathname.startsWith('/search')) return;
        // history.push('/search')
    }   

    const _onSearch = async (kw) => {
        console.log("onSearch()", kw)

        let input = {
            "search": kw,
            "page": 1,
            // "customer_id": "asd"
        }

        let result = await Axios({
            method: 'post',
            url, 
            data: input,
            headers: {
                'app-key': '3d9188577cc9bfe9291ac66b5cc872b7',
                'app-secret': '210a28f50a8e9a0986df287ac9ae224de95b8978',
            }
        })
        .then(({ data: response }) => (response))
        .catch(error => {
            console.log(__error("error: "), error);
            return { error: { message:"Operation failed" } }
        });

        if (result && result.error){
            console.log(__error("error: "), result);
            message.error(result.error.message);
            return;
        }
        setList(result)
        

    }
    const onSearch = (kw) => {
        // history.push(`/search?query=${kw}`)
        if (!kw || kw.length<3) return;
        // history.push(`/search/#${kw}`)
        history.replace({ pathname: `/search/#${kw}` })
        // window.history.replaceState(null, "Search", `/search/#${kw}`)
        // history.replace({ pathname: `/product/${this.props.product.id}` })
    }
    const onChange = (e) => {
        e.preventDefault();

        setVal(e.target.value)

        history.push(`/search/#${e.target.value}`);

        // // event.currentTarget.value
        // setVal(String(e.currentTarget.value))
        // if (!e.currentTarget.value || e.currentTarget.value.length<3) return;
        // history.replace({ pathname: `/search/#${e.currentTarget.value}` })
        // // history.push(`/search?query=${e.currentTarget.value}`)
        // // history.push(`/search/#${e.currentTarget.value}`)
    }

    const handleSearch = (kw) => {
        // e.preventDefault(); // Prevents the form from submitting and refreshing the page
        history.push(`/search/#${kw}`);
    };

    return (<>
        <div style={{ border:"1px solid black", textAlign:"left" }}>
            <div style={{ width: "100%", paddingLeft: 20 }}>
                <AntInput.Search 
                    enterButton
                    ref={inputRef}
                    style={{ maxWidth: "800px", width: "100%" }} size="large"
                    onClick={(e) => e.stopPropagation()}
                    onSearch={handleSearch}
                    onChange={onChange}
                    defaultValue={val}
                    value={val}
                    name="keywords" placeholder="Search 5000+ products..."
                />

                {/* <SearchField enterButton style={{ maxWidth: "800px", width: "100%" }} size="large"
                    // onChange={(e) => setVal(e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                    onSearch={handleSearch}
                    onChange={onChange}
                    // onFocus={onFocus}
                    defaultValue={val}
                    value={val}
                    name="keywords" placeholder="Search 5000+ products..."
                /> */}

            </div>
        </div>

        {/* <DevBlock obj={list} /> */}
    </>)
}



const LIST_DATA = loader('src/graphqls/product_cat/productCats.graphql');
const { Header } = Layout;


// const VERIFY_PHONE_CODE = loader('src/graphqls/user/verifyPhoneCode.graphql');

const _YourAccountMenu = props => {
    const [mobileVerificationModal, setMobileVerificationModal] = React.useState(false);
 
    const { user, cart } = props;
    const { phone_confirmed } = user || {};
    
    const confirmSignOut = () => {
        // props.hide();
        Modal.confirm(
            {
                centered:true,
                // className:"confirmation-pop",
                title: 'Are You Sure',
                // icon: <Icon icon="exclamation-triangle" size="8x" color="orange" className='anticon' />,
                content: 'Are you really sure you want to sign out?',
                
                cancelText: "Cancel",
                cancelButtonProps: { block: true },
                
                okText: "Yes, sign out",
                okButtonProps: { block: true },
                
                onOk() { onLogout(); },
                onCancel() { console.log('Cancel'); },
            }
        );
    }

    const onLogout = args => {
        props.flushCart();
        props.logoutUser();
        sleep(1).then(r=>{
            window.location = "/";
        })
    }

    return (<>
        <div style={{ width: '250px' }}>
            <Row gutter={[0, 12]}>
                {/* <Col flex="60px"><Avatar size={60}>U</Avatar></Col> */}
                <Col flex="auto" style={{ display:"flex", flexDirection:"column", alignContent:"left", textAlign:"left" }}>
                    {/* <Divider type="horizontal" style={{margin:"5px 0"}} /> */}
                    {/* <Button theme="light-gray" block style={{textAlign:"left"}}><Link to="/user/profile"><Icon icon="cog" style={{marginRight:"5px"}} /> Account Settings</Link></Button> */}

                    <Link to="/user/profile" onClick={props.onClose || undefined} className="menu-item"><Row>
                        <Col><Icon icon="cog" className="ico link-icon" /></Col>
                        <Col flex="auto">Account Settings</Col>
                    </Row></Link>

                    <Link to="/user/my-orders" onClick={props.onClose || undefined} className="menu-item"><Row>
                        <Col><Icon icon="truck" className="ico link-icon" /></Col>
                        <Col flex="auto">Orders History</Col>
                    </Row></Link>


                    {/* <Link to="/bug-report"><Icon icon="exclamation-triangle" /> Bug Report</Link>
                    <Divider type="horizontal" style={{ margin: "5px" }} /> */}
                </Col>
            </Row>
            
            {/* <Divider type="horizontal" style={{ margin: "5px 0" }} /> */}
            <Divider type="horizontal" style={{ margin: "0 0 10px 0" }} />
            {/* <div style={{ margin: "0 10px" }}><Button type="default" theme="blue-outline" block onClick={onLogout}>Sign out</Button></div> */}
            <div style={{ margin: "0 10px" }}>
                {phone_confirmed < 1 &&
                    <>
                        <Button type="danger" style={{ margin: "0 0 5px 0" }} onClick={() => setMobileVerificationModal(true)} block><>
                            <Icon icon="phone-alt" className="ico link-icon" /> Confirm Account
                        </></Button>
                    </>
                }
                <Button type="default" theme="blue-outline" block onClick={confirmSignOut}>Sign out</Button>
            </div>
        </div>


        <Modal centered 
            closable={false} 
            destroyOnClose={true} zIndex={9999}
            footer={null} forceRender={false}
            // title="Basic Modal"
            // maskClosable
            visible={mobileVerificationModal}
            // onOk={this.handleOk} 
            onCancel={() => setMobileVerificationModal(false)}
        >
            <PhoneVerification onSuccessCallback={() => setMobileVerificationModal(false)} />
            {/* <FormComponent onSubmit={onMobileVerificationSubmit} id='ProdCatForm' loading={loading} hideDevBlock={true} >
                <h3>Phone Verification</h3>
                {vreificationError && <Alert message={vreificationError} type="error" showIcon />}
                <FormField type="text" name="code" compact
                    label="Phone Verification Code"
                    placeholder="Please enter the phone verification code here that you received"
                    validate={composeValidators(rules.required, rules.minChar(4))} />
  
                <div style={{ display: "flex", justifyContent: "space-between", margin: "20px 0" }}>
                    <Button type="danger" onClick={() => setMobileVerificationModal(false)}><Icon icon="caret-left" /> Cancel</Button>
                    <Button type="primar y" theme="gray">Re-Send Verification Code</Button>
                    <Button type="primary" htmlType="submit">Verify</Button>
                </div>
            </FormComponent> */}
        </Modal>

    </>)

}
const YourAccountMenuWithApollo = withApollo(_YourAccountMenu)
const mapStateToYourAccountMenuProps = ({ user, cart }) => {
    // console.log("state: ", state)
    return ({ 
        user: user ? user.user : {},
        cart,
        // user: (state.user && state.user.user) || {}, 
        // cart: state.cart 
    });
}
const mapYourAccountMenuDispatchToProps = (dispatch, ownProps) => ({
    // loginUser: (payload) => dispatch(loginUser(payload)),
    flushCart: () => dispatch(flushCart()),
    logoutUser: () => dispatch(logoutUser()),
    updateUser: (payload) => dispatch(updateUser(payload)),
})
export const YourAccountMenu = connect(mapStateToYourAccountMenuProps, mapYourAccountMenuDispatchToProps)(YourAccountMenuWithApollo);

export const LoggedInUserHeader = ({ user }) => {
    const { phone_confirmed } = user;
    const [popVisible, setPopVisible] = React.useState(false);
    const isSmallDevice = useMediaQuery({ query: '(max-device-width: 365px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const _isMobile = (isMobile || isSmallDevice) && !isTablet;

    const isCheckoutPage = useRouteMatch({ path: "/checkout", strict: false, sensitive: true });
    if (isCheckoutPage) return <Cart hidden />


    const handleClickChange = visible => {
        setPopVisible(visible);
    };





    if (_isMobile){
        return (<div className="mobile-side-menu">
            <Link to="/user/my-orders" className="menu-item"><Row>
                <Col><Icon className="link-icon" icon={['fa', 'truck']} /></Col>
                <Col flex="auto">Your Orders</Col>
            </Row></Link>
        </div>)
    }

    return (<>
        {user.ordersCount > 0 && <>
            <Button theme="light-gray" size="large"><Link to="/user/my-orders">Your Orders</Link></Button>
            <Divider type="vertical" />
        </>}

        <div style={{ display: "inline-block", }}>
            <Popover
                visible={popVisible}
                content={<YourAccountMenu hide={() => setPopVisible(false)} />}
                onVisibleChange={handleClickChange}
                // onClick={setPopVisible(true)}
                title={<div style={{ textAlign: "center" }}>{user.name || user.email}</div>}
                trigger="click"
                placement="bottomRight" overlayClassName="your-acc-pop">
                <Button theme="light-gray" size="large" style={{margin:"0"}}>Your Account <span style={{ fontWeight: 100, fontSize: "12px" }}><Icon icon="chevron-down" /></span></Button>
            </Popover>
        </div>
        <Divider type="vertical" />

        <Cart />
    </>)
}

export const NotLoggedInUserHeader = () => {
    // const isSmallDevice = useMediaQuery({ query: '(max-device-width: 1224px)' })
    // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

    // if (isSmallDevice) return (<>
    //     <Row gutter={[10,2]}>
    //         <Col><Button theme="blue"><Link to="/user/register">Register</Link></Button></Col>
    //         <Col><Button theme="blue"><Link to="/user/sign-in">Sign In</Link></Button></Col>
    //     </Row>
    // </>)

    return(<>
        <Button theme="light-gray" size="large"><Link to="/user/register">Register</Link></Button>
        <Button className="blue" size="large" style={{ margin: "0 0 0 10px", width: "130px" }}><Link to="/user/sign-in">Sign In</Link></Button>
        <Divider type="vertical" />
        <Cart />
    </>)
}




var lastScroll = 0;

const _LayoutHeader = props => {
    const [showDrawer, setShowDrawer] = React.useState(false);
    const [affixed, setAffix] = React.useState(false);
    const [mounted, setMounted] = React.useState(false);
    const [scrollDirection, setScrollDirection] = React.useState(null);
    const [redirectTo, setRedirectTo] = React.useState(null);
    const isSmallDevice = useMediaQuery({query: '(max-device-width: 1000px)'})
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isCheckoutPage = useRouteMatch({ path: "/checkout", strict: false, sensitive: true });

    const _isMobile = (isMobile || isSmallDevice) && !isTablet;

    const scrollDetect = () => {
        window.onscroll = function () {
            let newScroll = document.documentElement.scrollTop;
            if (newScroll == lastScroll) return;
            
            let diff = newScroll - lastScroll;
            
            let direction = (diff > 0) ? 'down' : 'up';
            
            if (scrollDirection != direction && mounted) setScrollDirection(direction);
            
            lastScroll = newScroll;
        };
    }

    React.useEffect(() => {
        scrollDetect()
        if (!mounted) {
            setMounted(true)
            scrollDetect()
        }
        return ()=>{
            if (mounted) setMounted(false)
        }
    });

    const { user, cart } = props;

    if (redirectTo) return <Redirect to={redirectTo} />;

    // if (isCheckoutPage){
    //     return (<>
    //         <div className={`site-layout-header-wrapper ${_isMobile && 'isMobile'}`}></div>
    //     </>)
    // }


    return (<>
        <div className={`site-layout-header-wrapper ${_isMobile && 'isMobile'}`}>
            {_isMobile && <>
                <Affix offsetTop={0} onChange={_affixed => { setAffix(_affixed); }} style={{width:"100%"}}>
                    {/* {(scrollDirection != 'up' && affixed) && <span></span>} */}

                    {/* {(scrollDirection == 'up' || !affixed) && */}
                        <Header style={{ display: scrollDirection == 'up' || !affixed ? 'block' : 'none' }} className="site-layout-header AffixedMenuAnimation animated">
                            <Row className="header-row" gutter={[12, 0]}>
                                <Col><LayoutSideMenu_Mobile {...props} /></Col>
                                {/* <Col><Button onClick={() => setShowMenu(true)} theme="light-gray" style={{padding:"8px 0", margin:"0"}}><Icon icon="bars" size={"2x"} /></Button></Col> */}
                                <Col flex="90px" style={{ paddingTop: "10px" }}><Link to="/"><img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/logo-190.png`} width="100%" /></Link></Col>
                                {/* <Col flex="90px" style={{ paddingTop: "10px" }}><span style={{ cursor: "pointer" }} onClick={() => window.location.href = "http://localhost:4041/"}><img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/logo-190.png`} width="100%" /></span></Col> */}
                                <Col flex="auto" align="right">
                                    {isPortrait && <>
                                        {/* <Button theme="light-gray" size="large"><Icon icon={['far', 'question-circle']} /> Help</Button> */}
                                        <Cart />
                                    </>}
                                    {!isPortrait && <>
                                        <Row>
                                            <Col flex="auto"></Col>
                                            <Col style={{ alignItems: "flex-end", display: "inline-flex" }}>{!user || !user._id && <NotLoggedInUserHeader />}</Col>
                                            <Col>{user && user._id && <LoggedInUserHeader user={user} />}</Col>
                                            {/* <Col><Cart /></Col> */}
                                        </Row>
                                        {/* <Button theme="light-gray" size="large"><Icon icon={['far', 'question-circle']} /> Help</Button> <Divider type="vertical" /> */}
                                    </>}
                                </Col>
                            </Row>
                            {!isCheckoutPage && <SiteSearch style={{ marginTop: "5px"}} />}
                            {/* <AlgoliaSearchBox style={{ marginTop: 10 }} /> */}
                        </Header>
                    {/* } */}

                </Affix>
            </>}

            {!_isMobile &&
                <Header className="site-layout-header">
                    <Row className="header-row">
                    <Col style={{ maxWidth: "200px" }} style={{ alignItems: "center", display: "inline-flex" }}>
                            <a href="/"><img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/logo-190.png`} width="100%" /></a>
                            {/* {props.skipRouterLinks && <a href="/"><img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/logo-190.png`} width="100%" /></a>}
                            {!props.skipRouterLinks && <Link to="/"><img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/logo-190.png`} width="100%" /></Link>} */}
                        </Col>
                        <Col flex="auto" align="right">
                            {!isCheckoutPage && <><Button theme="light-gray" size="large"><Icon icon={['far', 'question-circle']} /> Help</Button> <Divider type="vertical" /></>}
                            {!user || !user._id && <NotLoggedInUserHeader isSmallDevice={isSmallDevice} isPortrait={isPortrait} />}
                            {user && user._id && <LoggedInUserHeader user={user} isSmallDevice={isSmallDevice} isPortrait={isPortrait} />}
                            {/* {!isCheckoutPage && <SiteSearch style={{ marginTop: "5px" }} />} */}
                            {!isCheckoutPage && <SiteSearch_v2 style={{ marginTop: "5px" }} />}
                            {/* <AlgoliaSearchBox style={{ marginTop: '5px' }} /> */}
                        </Col>
                    </Row>
                </Header>
            }
        </div>

        {(!_isMobile) && <>
            <Affix offsetTop={0} onChange={_affixed => { setAffix(_affixed); }}><>
                {affixed &&
                    <LayoutTopMenu_affixed {...props}
                        cartCalculations={props.cart}
                        affixed={affixed}
                        visible={scrollDirection != 'up'}
                        onCartClick={() => setShowDrawer(!showDrawer)} />
                }
                {!affixed && <span></span>}
            </></Affix>

            {!isCheckoutPage && <LayoutTopMenu {...props} cartCalculations={props.cart} affixed={false} visible={false} onCartClick={() => setShowDrawer(!showDrawer)} />}
        </>}
    </>)

}

_LayoutHeader.propTypes = {
    collapsed: PropTypes.bool,
    onToggleClick: PropTypes.func,
    menu: PropTypes.array,
    // topMenu: PropTypes.array,
    // labelA: PropTypes.oneOfType([
    //             PropTypes.string,
    //             PropTypes.object
    //         ]).isRequired,
}

const WithApollo = compose(

    graphql(LIST_DATA, {
        options: props => {
            // return { variables: { filter: JSON.stringify({ parent_cat_id:null }) } };
            return {
                variables: { filter: JSON.stringify({ status: "enabled" }) },
                fetchPolicy: "cache-first",
            };
        },
        props: ({ ownProps, data }) => {
            const { loading, productCats, error, fetchMore, subscribeToMore } = data;
            if (error) console.log(__error("error"), error);

            const loadMoreRows = (filter, others) => {
                let vars = { filter: filter, others: others };
                let updateQuery = (previousResult, { fetchMoreResult }) => fetchMoreResult.productCats;
                return fetchMore({ variables: vars, updateQuery: updateQuery, fetchPolicy: "no-cache" });
            }

            return { loading, productCats, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),

)(_LayoutHeader);


const mapStateToProps = state => {
    // console.log("state: ", state.user.token)
    return ({ user: (state.user && state.user.user) || {}, cart: state.cart, settings: state.box_storefront });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    // loginUser: (payload) => dispatch(loginUser(payload)),
    logoutUser: () => dispatch(logoutUser()),
    updateUser: (payload) => dispatch(updateUser(payload)),
    updateSettings: (payload) => dispatch(updateSettings(payload)),
})
const WithRedux = connect(mapStateToProps, mapDispatchToProps)(WithApollo);
export const LayoutHeader = withRouter(WithRedux);
// export default LayoutHeader;

