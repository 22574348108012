import React from 'react'; 
import { ApolloClient } from 'apollo-client';
import { ApolloLink, split } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { WebSocketLink } from 'apollo-link-ws';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from 'react-apollo';
import { getMainDefinition } from 'apollo-utilities';
import { SubscriptionClient } from "subscriptions-transport-ws";
import { __info } from 'Common/scripts/consoleHelper';
import { connect } from "react-redux";

const NETWORK_INTERFACE_URL = process.env.REACT_APP_GRAPHQL_PATH; //"http://localhost:4000/graphql";
const SUBSCRIPTION_CLIENT_URL = process.env.REACT_APP_SUBSCRIPTION_CLIENT_URL; //"ws://localhost:4000/subscriptions";



const ApolloWrapper = ({ children, user }) => {
  // console.log("USER: ", user)

  /******** Setting up apollo-link ********/
  const httpLink = createHttpLink({ uri: NETWORK_INTERFACE_URL });

  const middlewareLink = setContext(() => ({
    headers: {
      'x-token': localStorage.getItem('token'),
      'x-refresh-token': localStorage.getItem('refreshToken'),
      authorization: `Bearer ${user.token || undefined}`
      // authorization: `Bearer ${localStorage.getItem('token')}`
      // authorization: `Bearer token-123456`
    },
  }));

  const afterwareLink = new ApolloLink((operation, forward) => {
    const { headers } = operation.getContext();

    if (headers) {
      const token = headers.get('x-token');
      const refreshToken = headers.get('x-refresh-token');

      if (token) localStorage.setItem('token', token);
      if (refreshToken) localStorage.setItem('refreshToken', refreshToken);

    }

    return forward(operation);
  });

  const timeStartLink = new ApolloLink((operation, forward) => {
    operation.setContext({ start: new Date() });
    return forward(operation);
  });
  const logTimeLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((data) => {
      // data from a previous link
      const time = new Date() - operation.getContext().start;
      if (process.env.REACT_APP_DEV == 'yes') {
        console.log(__info("APOLLO : "), ` operation ${operation.operationName} took ${time} to complete`, operation);
        // console.log(`APOLLO: operation ${operation.operationName} took ${time} to complete`);
      }
      return data;
    })
  });
  const linkLogger = timeStartLink.concat(logTimeLink);


  let httpLinkWithMiddleware = httpLink;
  httpLinkWithMiddleware = middlewareLink.concat(httpLinkWithMiddleware)
  httpLinkWithMiddleware = afterwareLink.concat(httpLinkWithMiddleware)
  httpLinkWithMiddleware = linkLogger.concat(httpLinkWithMiddleware)


  const SubClient = new SubscriptionClient(SUBSCRIPTION_CLIENT_URL, {
    reconnect: true,
    inactivityTimeout: 0,
    // onConnected: ()=>{
    //   console.log(`Subscription Socket connectd on ${SUBSCRIPTION_CLIENT_URL}`);
    // },
    connectionCallback: (error) => {
      if (!error)
        console.log(`🚀 Subscription Socket connectd on ${SUBSCRIPTION_CLIENT_URL}`);
      else
        console.log("🚨 ERROR - Subscription Socket Connection Error: ", error);
    }
  });


  const wsLink = new WebSocketLink(SubClient);


  const link = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    httpLinkWithMiddleware,
  );

  const ApolloDefaultOptions = {
    watchQuery: {
      fetchPolicy: 'network-only', //no-cache,network-only/
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  };

  const aClient = new ApolloClient({
    link,
    cache: new InMemoryCache({
      // typePolicies:{
      //   ProductQuery:{
      //     fields: {
      //       repositories: relayStylePagination()
      //     },
      //   }
      // },
      // dataIdFromObject: o => {
      //   // if (o._id) console.log(`dataIdFromObject: ${o.__typename} : ${o._id}`);
      //   // return null;

      //   console.log("O: ", o);

      //   if (!o._id) return null;

      //   let obj = {}
      //   obj[o.__typename] = o._id;
      //   return obj;
      // }
    }),
    onError: ({ networkError, graphQLErrors }) => {
      console.log('graphQLErrors', graphQLErrors)
      console.log('networkError', networkError)
    },
    errorPolicy: 'all',
    defaultOptions: ApolloDefaultOptions
  });



  return (<ApolloProvider client={aClient}>
    {children}
  </ApolloProvider>)
}
const mapStateToProps = state => {
  return { user: state.user ? state.user : false };
}
export default connect(mapStateToProps)(ApolloWrapper);
