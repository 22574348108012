import React from 'react'
import { Link } from 'react-router-dom';
import { Row, Col, Divider } from 'antd';
import { __error } from 'Common/scripts/consoleHelper';
import { Icon, Button, Loader, Drawer } from 'Common/components';
import Cart from './Cart';
import { YourAccountMenu } from './LayoutHeader'
import { constructCategoryArray } from 'Common/scripts/Functions';


const CategoryMenu = props => {
    var { loading, productCats } = props;
    const [parents, setParents] = React.useState(["root"]);
    const [showMenu, setShowMenu] = React.useState(false);

    const onBackClicked = args => {
        if (parents.length < 2) return setShowMenu(false);
        let p = parents.slice();
        p = p.slice(0, p.length - 1);
        setParents(p);
    }

    const getCatsByParent = (parent = null) => {
        let arr = productCats?.filter(o => o.parent_cat_id == parent)?.map(item => ({
            _id: item._id,
            title: item.title,
            slug: item.slug,
            status: item.status,
            menu_bg_img: item.menu_bg_img,
            title_img: item.title_img,
            parent_cat_id: parent,
            children: getCatsByParent(item._id),
        }))

        return arr;
    }

    const getCatsById = (id = null) => {
        console.log(`getCatsById(${id})`);
        if (!id) return {};
        return productCats.filter(o => o._id == id)[0]
    }

    const OnMenuItemClick = node => {
        let p = parents.slice();
        p.push(node._id);
        setParents(p);
    }

    const RenderMenuItem = ({ item }) => {
        if (item.children && item.children.length > 0) {
            return (<span className="menu-item" onClick={() => OnMenuItemClick(item)}>
                <Row style={{ width: "100%" }}>
                    <Col flex="auto">{item.title}</Col>
                    <Col align="right">
                        {item.children && item.children.length > 0 && <Icon className="arrow-right" icon="chevron-right" />}
                    </Col>
                </Row>
            </span>)
        }
        else {
            return (<Link to={`/category/${item.slug}`} className="menu-item" onClick={props.onClose}>
                <Row style={{ width: "100%" }}>
                    <Col flex="auto">{item.title}</Col>
                    <Col align="right">
                        {item.children && item.children.length > 0 && <Icon className="arrow-right" icon="chevron-right" />}
                    </Col>
                </Row>
            </Link>)
        }
    }

    const RenderViewAll = args => {
        if (parents.length < 1) return null;

        let pNode = getCatsById(parents[parents.length - 1]);
        if (!pNode || !pNode._id) return null;

        return (<>
            <Divider />
            <Link to={`/category/${pNode.slug}`} className="menu-item" onClick={props.onClose}>
                <Row style={{ width: "100%" }}>
                    <Col flex="auto">View All {pNode.title}</Col>
                </Row>
            </Link>
        </>)
    }

    const RenderMenus = () => {
        if (loading || !productCats) return null;
        let parentIds = parents[parents.length - 1];
        let menuArray = getCatsByParent(parentIds == 'root' ? null : parentIds);
        // let menuArray = constructCategoryArray(productCats, parentIds == "root" ? null : parentIds);

        return (<div className="mobile-side-menu-contents">
            {menuArray.filter(o=>o.slug ? true : false).map((item, i) => {
                return <RenderMenuItem item={{ ...item, level: i }} key={i} />
            })}

            <RenderViewAll />
        </div>)
    }

    return (<>
        <Row className="menu-item" style={{ width: "100%" }} onClick={() => setShowMenu(true)}>
            <Col flex="auto">Shop</Col>
            <Col><Icon className="arrow-right" icon="chevron-right" /></Col>
        </Row>


        <Drawer width={"350px"} height="100%" destroyOnClose={true} maskClosable={false} closable={true} placement="left" visible={showMenu} footer={null} onClose={() => setShowMenu(false)}
            headerStyle={{ paddingLeft: "15px" }} bodyStyle={{ padding: "15px" }}
            title={<Button theme="gray" onClick={() => onBackClicked()}><Icon icon="chevron-left" /> Back</Button>}
        >
            {RenderMenus()}
        </Drawer>
    </>)
}

const AccountMenu = props => {
    const [showMenu, setShowMenu] = React.useState(false);

    return (<>
        <span className="menu-item" onClick={() => setShowMenu(true)}><Row>
            <Col><Icon className="link-icon" icon={['fa', 'user']} /></Col>
            <Col flex="auto">Your Account</Col>
        </Row></span>

        <Drawer width={"272px"} height="100%" destroyOnClose={true} maskClosable={false} closable={true} placement="left" visible={showMenu} footer={null} onClose={() => setShowMenu(false)}
            // getContainer={false} style={{ position: 'absolute' }}
            headerStyle={{ paddingLeft: "15px" }} bodyStyle={{ padding: "15px" }}
            title={<Button theme="gray" onClick={() => setShowMenu(false)}><Icon icon="chevron-left" /> Back</Button>}
        ><div className="mobile-side-menu">
                <YourAccountMenu onClose={props.onClose} />
            </div></Drawer>
    </>)

}

const LayoutSideMenu_Mobile = props => {
    const [showMenu, setShowMenu] = React.useState(false);
    const { user } = props;

    return (<>
        <Button onClick={() => setShowMenu(true)} theme="light-gray" style={{ padding: "8px 0", margin: "0" }}><Icon icon="bars" size={"2x"} /></Button>

        <Drawer width={"350px"} height="100%" destroyOnClose={false} maskClosable={true} placement="left" onClose={() => setShowMenu(false)} visible={showMenu}
            // bodyStyle={{maxWidth:"200px"}}
            // headerStyle={{ backgroundColor:"#F6F6F6"}}
            footer={null}
            title={<>
                {!user || !user._id && <Row gutter={[10, 2]}>
                    <Col><Button theme="blue"><Link to="/user/register">Register</Link></Button></Col>
                    <Col><Button theme="blue"><Link to="/user/sign-in">Sign In</Link></Button></Col>
                </Row>
                }
                {user && user._id && <Cart />}
                {/* {user && user._id && <LoggedInUserHeader user={user} />} */}
            </>}
        >
            <div className="mobile-side-menu">
                <CategoryMenu {...props} onClose={() => setShowMenu(false)} />

                <Divider />
                {user && user._id && <>
                    <Link to="/user/my-orders" className="menu-item" onClick={() => setShowMenu(false)}><Row>
                        <Col><Icon className="link-icon" icon={['fa', 'truck']} /></Col>
                        <Col flex="auto">Your Orders</Col>
                    </Row></Link>

                    <AccountMenu onClose={() => setShowMenu(false)} />
                    <Divider />
                </>}


                <Link to="/help" className="menu-item" onClick={() => setShowMenu(false)}><Row>
                    <Col><Icon className="link-icon" icon={['far', 'question-circle']} /></Col>
                    <Col flex="auto">Help</Col>
                    {/* <Col><Icon className="arrow-right" icon="chevron-right" /></Col> */}
                </Row></Link>


            </div>
        </Drawer>

    </>)
}
export default LayoutSideMenu_Mobile;