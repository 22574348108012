import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Progress, message, Upload, Modal, Card, Alert } from 'antd';
import _ from 'lodash';
import axios from 'axios';
import { Field } from 'react-final-form'
import { Loader, IconButton, Button, Icon } from "../";
import { getBase64 } from 'Common/scripts/Functions';
import { __warning, __error, __success } from 'Common/scripts/consoleHelper';

const UploadButton = props => {
    if (props.listType == 'picture-grid')
        return <Button><Icon icon="upload" /> {props.buttonLabel || "Upload"}</Button>;
    else
        return <div><Icon icon="upload" /><div className="ant-upload-text">{props.buttonLabel || "Upload"}</div></div>;
}

export const ThumbnailHolder = props => {
    // console.log("ThumbnailHolder.props: ", props);
    const { thumbUrl, srcUrl, handlePreview, status, onRemovePress, response } = props;
    
    if (srcUrl && srcUrl.length < 10) return null;
    // else if (props.response) data = { ...props.response };

    // check if its a upload response or archived image from DB
    let _thumbUrl = response ? response.thumbUrl : thumbUrl;
    let _srcUrl = response ? response.srcUrl : srcUrl;

    return (<div className={`gallery-item`}>
        {status == 'loading' && <Loader className={`loader`} size="small" loading={status == 'loading'} />}
        
        {status == 'loading' &&
            <>
            <div className="thumbnail-holder"><Icon size='2x' icon="image" /></div>
            <div className="loader-bar">
                <Loader className={`loader`} size="small" loading={true} />
                <Progress percent={30} status="active" className="progress-bar" />
            </div>
            </>
        }
       {status != 'loading' &&
            <>
                <div className="thumbnail-holder" onClick={() => handlePreview(_srcUrl)}>
                    <div className="hoverLayer"><Icon size="lg" className="preview-icon" icon="eye" /></div>
                    {_thumbUrl && <img className="thumb-img" src={_thumbUrl} />}
                    {!_thumbUrl && <div className="thumb-img"><Icon size='2x' icon="image" /></div>}
                </div>
                <div className="icon-holder">
                    <IconButton className="delete-button" onClick={onRemovePress} icon="trash-alt" />
                </div>
            </>
        }
    </div>)

}



/**********
 <UploadField type="picture"
    name="menu_bg_img" label="Menu Background Image" buttonLabel={<>Upload Menu Background Image</>}
    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
    data={{a:"A", b:"B"}}
    limit={1} listType="picture" />
 */
export const UploadImage = props => {
    const propTypes = {
        limit: PropTypes.number,
        listType: PropTypes.string, // text, picture, picture-card
        label: PropTypes.string,
        buttonLabel: PropTypes.string,
        onUpdateFileList: PropTypes.func,
        action: PropTypes.string,
        showCount: PropTypes.bool,
    }

    const [{ previewVisible, previewImage, previewTitle }, setState] = React.useState({
        previewVisible: false, previewImage: "", previewTitle: ""
    });

    const [fileList, setFileList] = React.useState(props.fileList || []);
    // const [uploaded_fileList, setUploadFileList] = React.useState([]);

    // let uploadButton;
    // if (props.listType == 'picture-grid')
    //     uploadButton = <Button><Icon icon="upload" /> {props.buttonLabel || "Upload"}</Button>;
    // else
    //     uploadButton = <div><Icon icon="upload" /><div className="ant-upload-text">{props.buttonLabel || "Upload"}</div></div>;

    const handlePreviewCancel = () => setState({ previewVisible: false, previewImage:"", previewTitle:"" });

    const handlePreview = async file => {
        // if (!file.url && !file.preview) {
        //     file.preview = await getBase64(file.originFileObj);
        // }

        let title = _.isString(file) ?
            file.substring(file.lastIndexOf('/') + 1) :
            file.name || file.response.srcUrl.substring(file.response.srcUrl.lastIndexOf('/') + 1);
        let src = _.isString(file) ? file : file.response.srcUrl;

        setState({
            previewVisible: true,
            previewImage: src,
            previewTitle: title,
        });
    };

    const onChange = (args) => {
        // console.log(__warning("onChange: "), args);
        setFileList(args.fileList);
    }

    const getFileIndex = file => {
        // var matchKey = file.uid !== undefined ? 'uid' : 'name';
        var matchKey;
        if (!matchKey && file.uid !== undefined) matchKey = 'uid';
        if (!matchKey && file.srcUrl !== undefined) matchKey = 'srcUrl';
        // if (!matchKey && file.name !== undefined) matchKey = 'name';
        
        if (!matchKey) {
            console.log(__error("Missing match key"), file);
            return false;
        }

        return fileList.findIndex(o => o[matchKey] == file[matchKey])
    }

    const beforeUpload = file => {
        console.log(__warning("beforeUpload()"), file);
        setFileList([...fileList, file]);
        return true;
    }

    const removeFileFromLocal = file => {
        const index = getFileIndex(file);
        if (index === false || index < 0) return;
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
    }

    const onRemove = file => {
        console.log(__warning("onRemove()"), file);

        props.onDeleteClicked(file)
            .then(r => {
                if (!r || r.error) {
                    console.log(__error("Response ERROR"), r);
                    message.error((r && r.error) ? r.error.message : "Invalid response");
                    return;
                }
                removeFileFromLocal(file);
                // const index = getFileIndex(file);
                // if (index === false || index < 0) return;
                // const newFileList = fileList.slice();
                // newFileList.splice(index, 1);
                // setFileList(newFileList);
                message.success("File removed")
            })
            .catch(error => {
                console.log(__error("ERROR"), error);
                message.error("Deletion request failed")
            });

    }

    const onStart = file => {
        // console.log(__warning('onStart'), file);
        // let _file = { ...file, status: "uploading", percent: 0 };

        let targetIndex = getFileIndex(file);
        if (targetIndex < 0) return;
        let targetItem = fileList[targetIndex];

        targetItem = Object.assign(targetItem, { ...file }, { status: "uploading", percent: 0 })

        const newFileList = fileList.slice();
        // newFileList[targetIndex] = { ...targetItem, status: "uploading", percent: 0 }
        newFileList[targetIndex] = targetItem

        setFileList(newFileList)
    }

    // const onSuccess = (response, file) => {
    const onSuccess = (response, file, xhr) => {
        console.log(__success('onSuccess'), response);

        if (response.error) {
            message.error(response.error.message);
            // onRemove(file);
            return;
        }
        if (response.success && response.success.message)
            message.success(response.success.message);

        // console.log("file: ", file);
        // console.log("fileList: ", fileList);
        message.success("Upload complete")

        try { if (typeof response === 'string') response = JSON.parse(response); }
        catch (e) { /* do nothing */ }

        let targetIndex = getFileIndex(file);
        if (targetIndex < 0) return;

        let targetItem = fileList[targetIndex];
            targetItem = Object.assign(targetItem, {
                percent: 100,
                status: 'done',
                response: response,
                xhr: xhr,
                uid: response.id || targetItem.uid || targetItem.srcUrl
            })
        
        // let newItem = {
        //     percent: 100,
        //     status: 'done',
        //     response: response,
        //     xhr: xhr,
        //     srcUrl: targetItem.srcUrl,
        //     thumbUrl: targetItem.thumbUrl,
        //     uid: targetItem.uid || targetItem.srcUrl
        // };

        const newFileList = fileList.slice();
        newFileList[targetIndex] = targetItem

        onChange({ fileList: newFileList, file: targetItem });
        // setUploadFileList(uploaded_fileList)

    }

    const onError = (error, response, file) => {
        // console.log(__error('onError'), error);
        // console.log("response: ", response);
        // console.log("file: ", file);

        if (error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
            // return { error: { message: error.response.data.error.message } };
            message.error(error.response.data.error.message);
        } else {
            message.error("Error uploading file..")
        }

        removeFileFromLocal(file);

        return false;
    }

    // const onProgress = ({ percent }, file) => {
    const onProgress = args => {
        console.log(__warning('onProgress'), args);
    }

    const customRequest = args => {
        // console.log(__warning("customRequest()"), args);
        const { action,
            data, file, filename, headers,
            onError, onProgress, onSuccess,
            withCredentials,
        } = args;

        // EXAMPLE: post form-data with 'axios'
        // eslint-disable-next-line no-undef
        const formData = new FormData();
        if (data) {
            Object.keys(data).forEach(key => {
                formData.append(key, data[key]);
            });
        }
        formData.append(filename, file);
        formData.append("name", props.name);
        // if custom size if available
        if (props.thumbSize) formData.append("thumbSize", props.thumbSize);


        axios.post(action, formData, {
            withCredentials,
            headers,
            onUploadProgress: ({ total, loaded }) => {
                let percent = Math.round(loaded / total * 100).toFixed(2);
                // let percent = Math.round((loaded/2) / total * 100).toFixed(2);
                // if (percent == 100) return;
                onProgress({ percent: percent }, file);
                // onProgress({ percent: Math.round(loaded / total * 100).toFixed(2) }, file);
            },
        })
            .then(({ data: response }) => {
                // onProgress({ percent: 100 }, file);
                onSuccess(response, file);
            })
            .catch(onError);

        return {
            abort() {
                console.log('upload progress is aborted.');
            },
        };
    }

    const RenderGalleryItems = args => {
        if (!args.items) return null;
        // console.log("RenderGalleryItems: ", args.items);

        const { items, input } = args;
        const { listType } = props;

        return (<><div className={`upload-gallery ${listType}`}>
            {items.map((item, i) => {
                return <ThumbnailHolder {...props} {...item} key={i} handlePreview={handlePreview}
                    onRemovePress={!props.onDeleteClicked ? false : () => onRemove({ ...item.response, ...item, name: input.name, data: props.data })} />
                // return <ThumbnailHolder {...item} key={i} handlePreview={handlePreview} onRemovePress={() => onRemove({ ...item.response, ...item})} />
            })}
        </div></>)
    }

    const uploadProps = {
        // fileList,
        action: props.action, // || `${process.env.REACT_APP_API_URL}/upload/assets`,
        disabled: false,
        multiple: false,
        data: props.data,
        accept: ".png,.jpg,.jpeg",
        method: props.method || "post",
        listType: props.listType || "picture-card",
        // headers: { Authorization: '$prefix $token' },
        beforeUpload: beforeUpload,
        onRemove: onRemove,
        onChange: onChange,
        onError: onError,
        onSuccess: onSuccess,
        // onProgress: onProgress,
        // onPreview: handlePreview,
        onStart: onStart,
        customRequest: customRequest,
    };

    return (
        <Field name={props.name} validate={props.validate}>
            {({ input, meta }) => {
                // let value = input.value;

                return (
                    <div className="form-field upload-image">
                        {props.label && <label>{props.label}</label>}
                        <Upload {...uploadProps}>
                            {/* {props.onUploadClicked && (!fileList || _.isUndefined(fileList) || fileList.length < (props.limit || 1)) && uploadButton} */}
                            {(!fileList || _.isUndefined(fileList) || fileList.length < (props.limit || 1)) && <UploadButton {...props} />}
                            {/* {(!fileList || _.isUndefined(fileList) || fileList.length < (props.limit || 1)) && uploadButton} */}
                            {/* {(!fileList || _.isUndefined(fileList) || fileList.length < (props.limit || 1)) && <>
                                {uploadButton}
                            </>} */}
                        </Upload>
                        
                        <RenderGalleryItems items={fileList} input={input} />

                        {/* {fileList.map((item, i) => {
                            // console.log("fileList", fileList);
                            return <ThumbnailHolder {...props} {...item} key={i} handlePreview={handlePreview}
                                onRemovePress={!props.onDeleteClicked ? false : () => onRemove({ ...item.response, ...item, name: input.name, data: props.data})} />
                            // return <ThumbnailHolder {...item} key={i} handlePreview={handlePreview} onRemovePress={() => onRemove({ ...item.response, ...item})} />
                        })} */}

                        {props.showCount && <div>{fileList.length} / {props.limit}</div>}
                        
                        <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handlePreviewCancel}>
                            <img alt="example" style={{ width: '100%' }} src={previewImage} />
                        </Modal>
                    </div>
                )
            }}
        </Field>
    )

}




export const UploadField = props => {
    const propTypes = {
        onDeleteClicked: PropTypes.func,
        // onUploadClicked: PropTypes.func,

        name: PropTypes.string.isRequired,
        label: PropTypes.string,
        buttonLabel: PropTypes.string,
        action: PropTypes.string,
        // remove_action: PropTypes.string,
        data: PropTypes.object.isRequired,
        listType: PropTypes.string, // text, picture, picture-card
        // type: PropTypes.string,
        fileList: PropTypes.array,
        limit: PropTypes.number,
        thumbSize: PropTypes.string, //"WidthxHeight"
    }

    let _props = { ...props };
    if (!props.buttonLabel) _props.buttonLabel = "Upload";
    if (!props.listType) _props.listType = "picture";
    if (!props.limit) _props.limit = 1;
    if (!props.action) _props.action = `${process.env.REACT_APP_API_URL}/upload/assets`;
    // if (!props.remove_action) _props.remove_action = `${process.env.REACT_APP_API_URL}/remove/assets`;

    if (_props.listType == "picture-grid" || _props.listType == "picture-card" || _props.listType == "list") {
        return <UploadImage {..._props} />
    }
    else {
        return <Alert message={`Invalid gallery type (${props.listType})`} type="warning" showIcon />
    }

    // if (props.type == 'crop') return <UploadImageCrop {...props} />
    // if (_props.listType == 'picture') return <UploadImage {..._props} />
    // else { return <div>Invalid upload field ({props.listType})</div> }

}

export default UploadField;