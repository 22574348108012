import React from 'react'
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { message, BackTop, Modal, Row, Col, Divider, Image } from 'antd';
import { Helmet } from "react-helmet";
import { graphql, withApollo } from 'react-apollo';
import { __error } from 'Common/scripts/consoleHelper'
import { loader } from 'graphql.macro';
import GetLoggedInUserData from 'Modules/User/containers/GetLoggedInUserData';
import { updateSettings } from 'Store/box_storefront.actions';
import { appText } from 'configs';
import { Button, DevBlock, Icon } from 'Common/components'
import { logoutUser } from 'Modules/User/redux/actions'
import AppSuspended from 'Common/components/AppSuspended';

import SignInPage from 'Modules/User/containers/sign-In';
import RegisterPage from 'Modules/User/containers/register';

import './styles/styles.scss';

const SETTINGS = loader('src/graphqls/settings/settings_str.graphql');

//// global events for registration/.signup pops to be opened
global.RegistrationPopEvent = new CustomEvent('showRegistrationPop', { bubbles: true, detail: 'Some initial data' });
global.SigninPopEvent = new CustomEvent('showSigninPop', { bubbles: true, detail: 'Some initial data' });
// global.BackToTopEvent = new CustomEvent('backToTopEvent', { bubbles: true, detail: 'Some initial data' });


export class _Wrapper extends React.Component {
    state = { userloaded: false, showRegistrationPop: false, showSigninPop:false };

    componentDidMount() {
        document.addEventListener('showRegistrationPop', (e)=>{
            this.setState({ showRegistrationPop: true, showSigninPop: false})
        }, false);

        document.addEventListener('showSigninPop', (e)=>{
            this.setState({ showRegistrationPop: false, showSigninPop: true})
        }, false);

        // console.log("Wrapper.componentDidMount()");
        if (!this.props.box_storefront || !this.props.box_storefront._id) {
            this.getSettings();
        }
    }

    componentWillUnmount(){
        this.setState({ showRegistrationPop: false, showSigninPop: false })
    }

    getSettings = async() => {
        let settings = await this.props.client.query({ query: SETTINGS }).then(e => {
            if (e.error || e.errors) {
                console.log("ERROR: ", e);
                message.error(__error("ERROR "), (e.error && e.error.message) || (e.errors && e.errors[0].message));
                this.setState({ error: "System configurations error!", loading: false })
                return false;
            }
            return JSON.parse(e.data.settings_str);
        }).catch(err => {
            console.log(__error("REDUX Call ERROR: "), err);
            message.error("Request ERROR");
            // this.setState({ error: "System Error!", loading: false })
            return false;
        })

        if (settings && !settings.error) {
            this.props.updateSettings(settings);
        }
    }

    render() {
        const { userloaded, showRegistrationPop, showSigninPop } = this.state;
        const { user, token, settings } = this.props;

        if ((user && user._id) && !token) {
            this.props.logoutUser()
            return <p>Re-Login required</p>;
        }

        if (!settings) return null;

        if (settings.suspend_app == 'true') return <AppSuspended />


        if (user && user._id && !userloaded) return <GetLoggedInUserData onSuccess={() => this.setState({ userloaded:true})} />
        
        return <>
            <Helmet>
                <title>{appText.defaultTitle}</title>
            </Helmet>
            {this.props.children}
            <BackTop duration={200} style={{opacity:"0.5"}}><Button><Icon icon="chevron-up" /></Button></BackTop>

            {!user || !user._id && <>
                <Modal centered width="100%" height="100%" closable={true} destroyOnClose={true} footer={null} forceRender={false} maskClosable={false} visible={showRegistrationPop}
                    title="Quick Register"
                    height="200"
                    onCancel={() => this.setState({ showRegistrationPop: false, showSigninPop: false })}
                >
                    {showRegistrationPop && <>
                        <RegisterPage isPopup
                            onCancel={() => this.setState({ showRegistrationPop: false, showSigninPop: false })} 
                            onLoginClick={() => this.setState({ showRegistrationPop: false, showSigninPop: true })}
                        />
                    </>}
                </Modal>

                <Modal centered closable={true} destroyOnClose={true} footer={null} forceRender={false} maskClosable={false} visible={showSigninPop}
                    title="Sign In To Your Account"
                    onCancel={() => this.setState({ showRegistrationPop: false, showSigninPop: false })}
                >
                    {showSigninPop && <SignInPage style={{ marginTop:0 }} isPopup
                        onCancel={() => this.setState({ showRegistrationPop: false, showSigninPop: false })}
                        onRegisterClick={() => this.setState({ showSigninPop: false, showRegistrationPop: true })}
                    /> }
                </Modal>
            </>}

        </>
    }

}

const mapStateToProps = state => {
    return ({ 
        settings: state.box_storefront, 
        user: (state.user && state.user.user) || {} ,
        token: (state.user && state.user.token) || false 
    });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    logoutUser: (payload) => dispatch(logoutUser(payload)),
    updateSettings: (payload) => dispatch(updateSettings(payload)),
})
const WrapperWithApollo = withApollo(_Wrapper);
export const Wrapper = connect(mapStateToProps, mapDispatchToProps)(WrapperWithApollo);
export default Wrapper;
