import React from 'react'
import PropTypes from 'prop-types';
import { graphql, useSubscription, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import moment from 'moment'
import { connect } from "react-redux";
import { Button, Loader, DevBlock, Icon } from 'Common/components';
import TimeSlotSelectorPage from 'Layout_v1/Pages/TimeSlotSelector';
import { dateToMongo, mongoToDate, convertTo12Hrs, sortArrayByVal } from 'Common/scripts/Functions'
import { __error } from 'Common/scripts/consoleHelper'
import SubscriptionHandler from 'Common/scripts/SubscriptionHandler';
import { timeslotSelect } from '../redux/actions'
import { message } from 'antd';

const LIST_DATA = loader('src/graphqls/delivery_slot/delivery_slots.graphql');
const QUERY_SUBSCRIPTION = loader('src/graphqls/delivery_slot/subscription.graphql');
const GET_QTYS = loader('src/graphqls/delivery_slot/getTotalOrdersForSlots.graphql');

const TimselotSelectionTimeout = 1000 * 60 * 5; // 5 min

// const SubHeandler = ({ variables }) => {
//     const { data, loading } = useSubscription(
//         QUERY_SUBSCRIPTION,
//         { variables }
//     );

//     console.log("loading: ", loading)
//     console.log("data: ", data)
// }

class TimeSlotSelector_Page extends React.Component {
    state = { busy: false, data: null, currentDate: moment(), selectedSlot: null, pageConfigs:null };
    slotClock = null;
    
    columns = [
        {
            title: ' ', dataIndex: 'time_range',
        },
    ];//.map(item => ({ ...item, width: "200px" }));

    shouldComponentUpdate(nextProps, nextState){
        const { subscribeToMore } = nextProps;

        if (!this.subscription) {
            this.subscription = new SubscriptionHandler({
                _subscribeToMore: subscribeToMore,
                _document: QUERY_SUBSCRIPTION,
                _variables: { filter: "" },
                _subscriptionName: "deliverySlotsUpdated",
                _subscriptionType: "simple-array",
                _queryName: "deliverySlots",
                _typename: "DeliverySlot",
                debug: true
            });
        }

        if (nextProps.order.timeslot && nextProps.order.timeslot != null && nextProps.order.timeslot != this.props.order.timeslot){ 
            this.stopSlotClock();
            this.startSlotClock();
        }

        return true;
    }
    // componentWillReceiveProps(nextProps) {
    //     const { subscribeToMore } = nextProps;

    //     if (!this.subscription) {
    //         this.subscription = new SubscriptionHandler({
    //             _subscribeToMore: subscribeToMore,
    //             _document: QUERY_SUBSCRIPTION,
    //             _variables: { filter: "" },
    //             _subscriptionName: "deliverySlotsUpdated",
    //             _subscriptionType: "simple-array",
    //             _queryName: "deliverySlots",
    //             _typename: "DeliverySlot",
    //             debug: true
    //         });
    //     }

    //     if (nextProps.order.timeslot && nextProps.order.timeslot != null && nextProps.order.timeslot != this.props.order.timeslot){ 
    //         this.stopSlotClock();
    //         this.startSlotClock();
    //     }
    // }

    // componentDidUpdate(nextProps) { }

    componentWillUnmount(){
        this.stopSlotClock();
    }

    componentDidMount(){
        this.startSlotClock();
    }

    startSlotClock = () => {
        this.slotClock = setInterval(() => this.refetchSlots(), TimselotSelectionTimeout);
        // this.slotClock = setInterval(() => this.deselectSlot(), TimselotSelectionTimeout);
    }
    stopSlotClock = () => this.slotClock ? clearInterval(this.slotClock) : null;

    refetchSlots(){
        this.deselectSlot();
        this.props.loadMoreRows().then(r => {
            this.stopSlotClock();
            this.startSlotClock();
            return r;
        });
    }

    deselectSlot(){
        this.props.timeslotSelect(null);
        this.stopSlotClock();
    }
    onSlotClick = args => {
        console.log("onSlotClick: ", args);
        const data = {
            _id: args._id,
            date: args.date,
            day: args.day,
            start_time: args.start_time,
            end_time: args.end_time,
            time_range_utc: args.time_range_utc,
            max_order_limit: args.max_order_limit,
        }
        this.props.timeslotSelect(data);
        // this.startSlotClock()
    }

    onDateChange = new_date => {
        console.log("onDateChange()", new_date.format("DD-MM-YYYY"));
        this.setState({ currentDate: new_date });
    }

    getWeek = async week => {
        // let columns = this.getDays(week);
        // let data = this.getSlots(week);

        // let dataColumns = this.createDataRows();
        // let dataRows = this.createColumns();

        let dataRows = this.createDataRows(week);
        let dataColumns = this.createColumns(week);

        // console.log("dataColumns: ", dataColumns)

        // collect time strings for query;
        let slotQueries = []
        dataRows = dataRows.map(d_slot=>{

            let raw = d_slot.raw.map(o=>{
                let time_range_utc = JSON.parse(o.time_range_utc);
                let t1 = mongoToDate(time_range_utc[0]).format('HH:mm');
                let t2 = mongoToDate(time_range_utc[1]).format('HH:mm');
                let col = dataColumns.find(d => d.dataIndex==o.day);
                // console.log("col: ", col)
                let col_date = mongoToDate(col.date).format('YYYY-MM-DD ');

                if (slotQueries.indexOf(col_date + t1) == -1)
                    slotQueries.push(col_date + t1);
                    // slotQueries.push({ start_time: col_date + t1 });

                return { 
                    ...o, 
                    this_slot_start: col_date+t1,
                    this_slot_end: col_date+t2,
                }
            })

            return { ...d_slot, raw }
        })


        // query Db for orders count
        const qtys = await this.props.client.query({
            query: GET_QTYS,
            variables: { times: slotQueries },
            fetchPolicy: "no-cache",
            // reducer: (previousResult, action, variables) => {
            //     console.log('reducer!!!!!', previousResult, action, variables);
            // }
        }).then(data => {
            // console.log("data: ", data.data.getTotalOrdersForSlots)
            if (data.data.getTotalOrdersForSlots.error){
                console.log(__error(data.data.getTotalOrdersForSlots.error.message));
                message.error(data.data.getTotalOrdersForSlots.error.message);
                return false;
            }
            return data.data.getTotalOrdersForSlots.results;
        }).catch(err => {
            // this.setState({ busy: false })
            return false;
        });

        // console.log("qtys: ", qtys)


        // add counts to records
        dataRows = dataRows.map(row=>{
            // console.log("this_slot_start: ", row)

            let _row = { ...row }

            row.raw.forEach(__r => {
                let this_slot_start =__r.this_slot_start;
                let _day = __r.day
                let thisDay = row[_day]

                let thisQty = qtys.find(o => o.start_time == this_slot_start);
                    Object.assign(thisDay, { totalOrders: thisQty ? thisQty.cnt : 0 })            
                
                _row[_day] = thisDay;
            });

            return _row;

        })

        // console.log("dataRows: ", dataRows)

        return { dataColumns, dataRows };
    }

    createColumns = (week = 1) => { // create columns
        
        let now = mongoToDate(); //moment_tz();
        // const dayOfyear = now.dayOfYear()
        // const monthDate = this.state.currentDate.startOf('month');
        // const daysInMonth = monthDate.daysInMonth();
        // const targetWeek = monthDate.week() + (week - 1);
        
        let columns = [{ title: ' ', dataIndex: 'time_range', }];

        now.add((week - 1), 'weeks');

        for (let i = 0; i < 7; i++){
            let nextDate = now.add(i <1 ? 0 : 1, 'days')

            let same_day = moment(nextDate.format("YYYY-MM-DD")).isSame(mongoToDate().format("YYYY-MM-DD") )
            let isTomorrow = moment(nextDate.format("YYYY-MM-DD")).isSame(mongoToDate().add(i < 1 ? 0 : 1, 'days').format("YYYY-MM-DD") )

            columns.push({
                title: same_day ? "Today" : isTomorrow ? "Tomorrow" : nextDate.format("ddd MMM DD"),
                dataIndex: nextDate.format("ddd").toLocaleLowerCase(),
                date: nextDate.format(), //dateToMongo(nextDate),
            })
        }

        return columns;
    }

    createDataRows = () => {
        // timeslot_days_to_show
        const { deliverySlots } = this.props;
        if (!deliverySlots) return [];

        // group by time
        let timeGroups = []
        let _deliverySlots = deliverySlots.map(element => {
            let slot = `${element.start_time}${element.end_time}`;
            if (timeGroups.indexOf(slot) < 0) timeGroups.push(slot)
            return { ...element, slot: slot };
        });

        // console.log("timeGroups: ", timeGroups);
        // console.log("_deliverySlots: ", _deliverySlots);

        let rows = timeGroups.map((slot, i) => {
            let filteredArray = _deliverySlots.filter(word => word.slot == slot);

            if (filteredArray && filteredArray[0]) {
                let cols = { raw: filteredArray };
                filteredArray.forEach(element => {
                    cols[element.day] = element;
                });

                let time_range_utc = JSON.parse(filteredArray[0].time_range_utc);
                let t1 = mongoToDate(time_range_utc[0]).format('hh:mm A');
                let t2 = mongoToDate(time_range_utc[1]).format('hh:mm A');
                return { ...cols, time_range: `${t1} to ${t2}`, key:i};
            }
            
            return filteredArray;
        });

        return rows;
        // const result = arr.filter(word => word[index] == value);

    }
    
    render() {
        const { loading, deliverySlots } = this.props;
        const { currentDate, selectedSlot } = this.state;


        // let tmp = dateToMongo();
        // console.log(">>>>>>>>>>>>>>>>>");
        // console.log("DATE TO MONGO: ", tmp);
        // console.log("MONGO TO DATE: ", mongoToDate(tmp).format());
        // console.log("<<<<<<<<<<<<<");
        
        if (loading) return <Loader loading={true} />

        const pageConfigs = {
            selectedSlot: this.props.order.timeslot,
            onSlotClick: this.onSlotClick,
            onNextClick: this.onNextClick,
            onPrevClick: this.onPrevClick,
            // onDateChange: this.onDateChange,
            getWeek: this.getWeek,
            // ...this.getWeek(),
            // dataRows: [{ sat: "yes" }, { sun: "yes" }],
            // dataRows: this.createDataRows(),
            // dataColumns: this.createColumns(),
            // data: this.getSlots(), //this.state.data,
            // columns: this.getDays(), //this.columns,
            // defaultDate: currentDate,
            loading: this.state.busy,
        }
        // console.log("pageConfigs: ", pageConfigs);
        

        return <>

            {/* <div>Current Date: {mongoToDate().format("ddd MMM DD ~ hh:mm A")}</div> */}
            {/* <h4>{mongoToDate().format()}</h4> */}
            <TimeSlotSelectorPage {...pageConfigs} />
        </>
        
    }

}

// TimeSlotSelector_Page.propTypes = {
//     topBanner: PropTypes.string,
//     // data: PropTypes.array,
//     // labelA: PropTypes.oneOfType([
//     //             PropTypes.string,
//     //             PropTypes.object
//     //         ]).isRequired,
// }

const WithApollo = compose(

    graphql(LIST_DATA, {
        options: props => {
            return { 
                variables: { filter: "" },
                fetchPolicy: "cache-first",
            };
        },
        props: ({ ownProps, data }) => {
            const { loading, deliverySlots, error, fetchMore, subscribeToMore } = data;

            if (error) console.log(__error("error"), error);

            const loadMoreRows = (filter="") => {

                let vars = { filter: filter };

                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    return fetchMoreResult.deliverySlots
                }

                return fetchMore({ variables: vars, updateQuery: updateQuery });
            }

            return { loading, deliverySlots, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),

)(TimeSlotSelector_Page);

const mapStateToProps = state => {
    return ({ order: state.order });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    timeslotSelect: (payload) => dispatch(timeslotSelect(payload)),
})
const WithRedux = connect(mapStateToProps, mapDispatchToProps)(WithApollo);
export default withApollo(WithRedux);
