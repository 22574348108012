import React, { Component, Image } from 'react'
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { Row, Col, Modal, message, Divider, Steps } from 'antd';
import { connect } from "react-redux";
import { Loader } from 'Common/components'
import { __error } from 'Common/scripts/consoleHelper'
import LocationForm from './LocationForm';

const RECORD = loader('src/graphqls/user/userAddress.graphql');
const ADD_RECORD = loader('src/graphqls/user/addUserAddress.graphql');
const UPDATE_RECORD = loader('src/graphqls/user/editUserAddress.graphql');

const containerStyle = { width: '600px', height: '400px' };

class AddressForm extends Component {
    constructor(props){
        super(props);
        this.state = { loading: this.props.loading, }
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit = async values => {
        const { user, userAddress } = this.props;
        
        const data = {
            user_id: user._id,
            full_address: values.full_address,
            city: values.city,
            label: values.label,
            delivery_instructions: values.delivery_instructions,
            // geoCoords: {
            //     lat: center.lat,
            //     lng: center.lng,
            //     latitudeDelta: center.latitudeDelta || 0.001,
            //     longitudeDelta: center.longitudeDelta || 0.001,
            // },
            geo_point: values.geo_point,
        }
        if (userAddress && userAddress?._id) Object.assign(data, { _id: this.props.userAddress._id })

        this.setState({ busy: true })
        if (data._id) {
            this.props.editUserAddress({ ...data})
                .then(r => {
                    this.setState({busy:false})
                    message.success("Updated");
                    if (this.props.onSuccess) this.props.onSuccess(r.data.editUserAddress);
                })
                .catch(err => {
                    message.error("Unable to complete request at the moment");
                    console.log(__error("ERROR"), err)
                    this.setState({ busy: false })
                })
        }
        else {
            this.props.addUserAddress(data)
                .then(r => {
                    this.setState({ busy: false })
                    if (r.error) {
                        message.error(r.error.message);
                        return;
                    }
                    message.success("Added");
                    if (this.props.onSuccess) this.props.onSuccess(r.data.addUserAddress);
                })
                .catch(err => {
                    message.error("Unable to complete request at the moment");
                    console.log(__error("ERROR"), err)
                    this.setState({ busy: false })
                })
        }

    }


    render(){
        const { busy, options, map, locationConfirmed } = this.state;
        const { loading, userAddress } = this.props;

        if(loading) return <Loader loading={true} />

        return (<>
            <div className="h-center" style={{ marginBottom: "50px" }}>
                <div style={{ width: "100%", maxWidth: containerStyle.width, }}>
                    <LocationForm buttonLabel="Save" onSubmit={(args) => this.onSubmit(args)} userAddress={userAddress} />
                </div>
            </div>
        </>)

    }

}

const mapStateToAvatarPopProps = state => {
    return ({ user: state.user ? state.user.user : {} });
}
export const WithRedux = connect(mapStateToAvatarPopProps)(AddressForm);

const WithApollo = compose(

    graphql(ADD_RECORD, {
        props: ({ mutate }) => ({
            addUserAddress: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),

    graphql(UPDATE_RECORD, {
        props: ({ mutate }) => ({
            editUserAddress: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),

)(WithRedux);

const EditWrapper = compose(
    graphql(RECORD, {
        options: ({ fields }) => {
            return {
                variables: { id: fields._id },
                fetchPolicy: "no-cache",
            };
        },
        props: ({ ownProps, data }) => {
            const { loading, userAddress, error } = data;
            if (error) console.log(__error("error"), error);

            return { loading, userAddress, queryErrors: error, }
        },
    }),
)(WithApollo);

const Wrapper = props => (props.fields && props.fields._id > 0) ? <EditWrapper {...props} /> : <WithApollo {...props} />
export default Wrapper;

// export default WithApollo;
