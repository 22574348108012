import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { message, Alert } from 'antd';
import { loader } from 'graphql.macro';
import { graphql, withApollo } from 'react-apollo';
import { __error } from 'Common/scripts/consoleHelper'
import { updateCart } from 'Store/cart.actions'
import { logoutUser, updateUser } from 'Modules/User/redux/actions'
import { Loader } from 'Common/components';
import SignIn from './sign-In';

const GET_LOGGED_IN_USER = loader('src/graphqls/user/getLoggedInUser.graphql');

export class _GetLoggedInUserData extends Component {
    state = { loading: false, error: null };

    componentDidMount() {
        const { cart, user } = this.props;
        if (!user || !user._id) return;

        this.fetchData()
    }

    async fetchData(){
        this.setState({ loading: true });

        const resutls = await this.props.client.query({
            query: GET_LOGGED_IN_USER,
            // variables: { id: user._id },
            // fetchPolicy: "no-cache", //no-cache, cache-first, network-only, cache-only, cache-and-network
        })
        .then(e => (e.data.getLoggedInUser)).catch(err => {
            console.log(__error("Query Error: "), err);
            return {}; // { error: { message:"Request ERROR"}}
        })

        // if (!resutls){
        //     console.log(__error("No user data found!"))
        //     this.setState({ error: "User data error!", loading: false });
        //     return;
        // }

        // if (resutls.error || resutls.errors){
        //     console.log(__error("ERROR: "), resutls);

        //     let error = (resutls.error && resutls?.error?.message) || (resutls.errors && resutls?.errors[0]?.message);
        //     message.error(error);
        //     this.setState({ error: "User data error!", loading: false })

        //     if (resutls.error) this.props.logoutUser();
        //     return;
        // }

        this.setState({ loading: false })
        this.handelResults(resutls);

        // if (resutls?.user?._id) this.handelResults(resutls);


    }

    handelResults(args) {
        // console.log("GetLoggedInUserData.handelResults: ", args);

        if (!args) return;
        
        if (args.error) {
            this.setState({ error: args.error.message })
            message.error(args.error.message);
            this.props.logoutUser();
            return;
        }

        if (args.user.status !== "enabled") {
            this.props.logoutUser();
            this.props.onSuccess(null);
            return;
        }

        this.props.updateUser({ ...args.user, orders: args.orders, ordersCount: args.ordersCount});
        this.props.onSuccess(args);
    }

    render() {
        if (!this.props.user._id) return <SignIn />

        const { error } = this.state;
        if (error) return <Alert message={error} type="error" showIcon />
        return <Loader loading={true} />
    }
}

_GetLoggedInUserData.propTypes = {
    onSuccess: PropTypes.func.isRequired
}


const mapStateToProps = state => {
    return ({ user: (state.user && state.user.user) || false, cart: state.cart });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    // loginUser: (payload) => dispatch(loginUser(payload)),
    logoutUser: () => dispatch(logoutUser()),
    updateUser: (payload) => dispatch(updateUser(payload)),
    updateCart: (payload) => dispatch(updateCart(payload)),
})
export const __GetLoggedInUserData = connect(mapStateToProps, mapDispatchToProps)(_GetLoggedInUserData);

const GetLoggedInUserData = withApollo(__GetLoggedInUserData);
export default GetLoggedInUserData;
