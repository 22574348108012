import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { __error } from 'Common/scripts/consoleHelper'
// import CategoryPage from 'Modules/Category/containers/index';
// import { AlgoliaHits } from 'Modules/AlgoliaSearch/AlgoliaHits';
import { ProductList } from 'Layout_v1';
import { Link, Redirect, useHistory, useLocation, useRouteMatch, withRouter } from 'react-router-dom';
import { sleep } from 'Common/scripts/Functions';
import { message } from 'antd';
import Axios from 'axios';


const SearchPage = props => {
  let kw = decodeURIComponent(String(props.location.hash).substring(1));
  const [busy, setBusy] = useState(false)
  const [list, setList] = useState(false)

  useEffect(() => {
    if (!kw || kw.length < 3) return;
    searchResutls()
  }, [kw])

  if (!props.location.hash || props.location.hash.length < 4) return null;

  const searchResutls = async () => {
    const url = 'https://analytics.maktech.space/api/global-search';

    let input = {
      "search": kw,
      "page": 1,
      // "customer_id": "asd"
    }

    setBusy(true)
    let result = await Axios({
      method: 'post', url, data: input,
      headers: {
        'app-key': '3d9188577cc9bfe9291ac66b5cc872b7',
        'app-secret': '210a28f50a8e9a0986df287ac9ae224de95b8978',
      }
    })
    .then(({ data: response }) => (response))
    .catch(error => {
      console.log(__error("error: "), error);
      return { error: { message: "Operation failed" } }
    });
    setBusy(false)

    if (result && result.error) {
      console.log(__error("error: "), result);
      message.error(result.error.message);
      return;
    }
    setList(result)


    // await sleep(1500)
  }


  console.log("list: ", list)

  return (<>
    <h3>Search results for: {kw}</h3>
    

    <ProductList
      loading={busy}
      data={(list && list.data.map(o=>({ _id: o.id, ...o }))) || []} total={(list && list.total) || 0}
    />

    
  </>)
}

SearchPage.propTypes = {
  // toggleDrawer: PropTypes.func.isRequired
  // prop: PropTypes.type.isRequired
  // onEditRecord: PropTypes.func.isRequired
}

export default SearchPage;
