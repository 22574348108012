import React from 'react'
// import { Icon, Button, Avatar } from 'Common/components'
import { Skeleton } from 'antd';

const Button = Skeleton.Button;
const Avatar = Skeleton.Avatar;

export const ProductListItem_skeleton = props => {
    return (
        <div className={`product-list-item-wrapper skeleton`}>
            {/* <Skeleton.Button active={buttonActive} size={buttonSize} shape={buttonShape} /> */}

            <div className="product-list-item">
                <div className="li-img"><Avatar shape="square" size={162} /></div>
                <Skeleton active />
                {/* <div className="li-details">
                    <div className="attributes">100mg</div>
                    <div className="name">Title</div>
                </div> */}
                <div className="li-footer">
                    {/* <div className="li-price">RS 0000</div> */}
                    <div className="li-order-button"><Button active>Add</Button></div>
                </div>
            </div>
        </div>
    )
}

// export const ProductListItem_ = props => {
//     // console.log("ProductListItem.props: ", props);

//     let was = props.price_was ? <div className="linethrough"> was RS {props.price_was}</div> : null;
    
//     return (
//         <div className={`product-list-item-wrapper ${props.className}`}>
//             <div className="product-list-item">
//                 <div className="li-img"><Avatar shape="square" size={162} src={props.picture_thumb} style={{zIndex:0}} /></div>
//                 {/* <div className="li-img"><img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/demo-product.jpg`} /></div> */}
//                 <div className="li-details">
//                     <div className="attributes">100mg</div>
//                     <div className="name">{props.title}</div>
//                 </div>
//                 <div className="li-footer">
//                     {was}
//                     <div className="li-price">RS {props.price}</div>
//                     <div className="li-order-button"><Button>Add</Button></div>
//                 </div>
//             </div>
//         </div>
//     )
// }

export default ProductListItem_skeleton;
