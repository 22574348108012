import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Alert, Row, Col } from 'antd';
import { connect } from "react-redux";
import { Link, Redirect } from 'react-router-dom';
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { __error } from 'Common/scripts/consoleHelper';
import { Button, Avatar, Loader, DevBlock } from 'Common/components'
import { flushCart } from 'Store/cart.actions';
import { noImage } from 'configs/constants';

import './styles.scss'

const CART_BY_USER = loader('src/graphqls/cart/cartByUser.graphql');
const REMOVE_UNAVAILABLE_CART = loader('src/graphqls/cart/removeUnavailableItemsFromCart.graphql');


class CheckoutReviewComp extends Component {
    state = {
        busy: false,
        error:false,
        redirect: false,
    }

    async clearUnavailableItems() {
        const { user, removeUnavailableItemsFromCart } = this.props;
        this.setState({ busy: true });

        const updatedCart = await removeUnavailableItemsFromCart({ id: user._id })
            .then(({ data }) => (data.removeUnavailableItemsFromCart))
            .catch(err => {
                console.log(__error("ERROR: "), err)
                return { error:{message:"Query Error!"}};
            });

        if (updatedCart.error) return this.setState({ busy: false, error: updatedCart.error.message });

        window.location.href = "/checkout"

    }

    render() {
        const { busy, error } = this.state;
        const { user, cartByUser, loading } = this.props;

        if (busy || loading) return <Loader loading={true} />

        var unavailableItems = cartByUser && cartByUser?.items?.filter(i => (i.available_qty < 1 || i.available_qty < i.qty));
        if (!unavailableItems || unavailableItems.length<1){
            return (<>
                <div style={{ padding: "50px 0px", }}>
                    <h4>No items to review</h4>
                    <div style={{ padding:"30px" }}><Button><Link to="/checkout">Go Back</Link></Button></div>
                </div>
            </>)
        }
        // unavailableItems = cartByUser?.items;

        return (
            <div style={{ display: "flex", flexDirection:"column", alignItems:"center" }}>
                <div style={{padding: "50px 0px", border:"0px solid black", width:"600px" }}>
                    <h4>Missing Item(s)</h4>
                    {error && <Alert message={error} showIcon type='error' />}


                    <Alert message={<>You have {unavailableItems.length} item(s) which gone out of stock.<br />Please remove these items by clicking "Update Cart" button.</>} type='warning' showIcon />

                    {unavailableItems && unavailableItems?.map((prod , i) => {
                        let out_of_stock = (prod.available_qty < 1);
                        let low_stock = (prod.available_qty > 0 && prod.qty > prod.available_qty);

                        return (<div key={i}>
                            <Row align="middle">
                                <Col style={{ marginRight: "5px" }}><Avatar src={prod.picture_thumb ? `${process.env.REACT_APP_DATA_URL}/${prod.picture_thumb}` : noImage} size={100} shape="square" /></Col>
                                <Col flex="auto">
                                    <div style={{ fontSize:"20px" }}>{prod.title}</div>
                                    <div>
                                        {out_of_stock && 
                                            <span style={{ backgroundColor: '#CCC', textAlign: "center", fontSize: '14px', padding: '5px 10px' }}>Sold Out</span>
                                        }
                                        {low_stock &&
                                            <span style={{ backgroundColor: '#CCC', textAlign: "center", fontSize: '14px', padding: '5px 10px' }}>Low Stock</span>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                        </div>)
                    })}

                    <div style={{ padding: "20px" }}><Button onClick={() => this.clearUnavailableItems()} danger block>Update Cart</Button></div>
                    

                    <DevBlock obj={cartByUser} />
                </div>
            </div>
        )
    }
}
const WithApollo = compose(
    graphql(CART_BY_USER, {
        options: props => {
            return {
                variables: { id: props.user._id },
                fetchPolicy: "network-only", //no-cache, cache-first, network-only, cache-only, cache-and-network
                notifyOnNetworkStatusChange: true,
            };
        },
        props: (props) => {
            const { ownProps, data } = props;
            const { loading, cartByUser, error, refetch, subscribeToMore } = data;

            if (error) console.log(__error("error"), error);

            return { loadingCart: loading, cartByUser, error, refetch, subscribeToMore }
        },
    }),

    graphql(REMOVE_UNAVAILABLE_CART, {
        props: ({ mutate }) => ({
            removeUnavailableItemsFromCart: (args) => mutate({
                variables: { ...args }
            }),
        })
    }),

)(CheckoutReviewComp);

const mapStateToProps = state => {
    return ({ 
        settings: state.box_storefront, 
        user: (state.user && state.user.user) || false 
    });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    flushCart: (payload) => dispatch(flushCart(payload)),
})
const CheckoutReview = connect(mapStateToProps, mapDispatchToProps)(WithApollo);
export default CheckoutReview;
