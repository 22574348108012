import React, { Component } from 'react'
import { Skeleton, Breadcrumb, Row, Col, Divider } from 'antd';
import { Icon } from 'Common/components'


export const ProductPageSkeleton = props => {
      return (<div className="product-page">
          <Row className="prod-page-header">
              <Col flex="auto">
                  <Breadcrumb>
                      <Breadcrumb.Item><Icon icon="home" /></Breadcrumb.Item>
                      <Breadcrumb.Item ><span>Category</span></Breadcrumb.Item>
                      <Breadcrumb.Item>{props.match && props.match.params && props.match.params.slug}</Breadcrumb.Item>
                  </Breadcrumb>
              </Col>
          </Row>

          <div className="prod-body">
              <Row style={{ margin: "0 60px" }}>
                  <Col flex="233px" style={{ boxSizing: "borderBox", marginRight: "28px", maxWidth: "233px" }} align="center">
                      <Skeleton.Avatar shape="square" size={200} />
                      {/* <Row gutter={[6, 6]} style={{marginTop:"5px"}} align="center">
                        <Col><Skeleton.Avatar shape="square" size={65} /></Col>
                        <Col><Skeleton.Avatar shape="square" size={65} /></Col>
                        <Col><Skeleton.Avatar shape="square" size={65} /></Col>
                      </Row> */}
                      {/* <ProductGalleryScroller data={[{ thumb: thumb, img: img }, ...productBySlug.gallery]} hideScrollbar showDirectPop /> */}
                  </Col>

                  <Col flex="auto">
                      <Skeleton active />

                      <div className="prod-attributes"><Skeleton active /></div>


                      <div className="prod-details"><Skeleton active /></div>
                  </Col>

              </Row>

              <Divider />

              <div className="you-may-also-like">
                  {/* <ProductListScroller title="You May Also Like" data={[{}, {}, {}, {}, {}, {}, {}]} hideScrollbar /> */}
              </div>



          </div>


      </div>)

}

export default ProductPageSkeleton;