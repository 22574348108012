import React, { Component, useState, useEffect } from 'react'
import { graphql, useSubscription, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import { message, Row, Col, Popconfirm, Modal, Alert } from 'antd';
import { connect } from "react-redux";

import { Icon, Button, IconButton, Heading, Loader, DevBlock } from 'Common/components'
import { __error } from 'Common/scripts/consoleHelper'
import { Subscriber } from 'Common/scripts/SubscriptionHandler';
import AddressForm from './AddressForm'


const GET_RECORD = loader('src/graphqls/user/userAddresses.graphql');
const QUERY_SUBSCRIPTION = loader('src/graphqls/user/subscriptionAddress.graphql');
// const ADD_RECORD = loader('src/graphqls/user/addUserAddress.graphql');
// const UPDATE_RECORD = loader('src/graphqls/user/editUserAddress.graphql');
const DELETE_RECORD = loader('src/graphqls/user/deleteUserAddress.graphql');
const SET_DEFAULT = loader('src/graphqls/user/setDefaultAddress.graphql');

const RenderAddress = ({ fields, setDefaultAddress, onEditClick, onDeletePress }) => {
    const [busy, setBusy] = useState(false);

    const isDefault = fields.is_default && fields.is_default > 0;
    return (<div className="user-address-display">
        <Row>
            <Col flex="auto">
                <div><b>{fields.label}</b></div>
            </Col>
            <Col align="right"><div>
                <IconButton onClick={setDefaultAddress} icon="check" size="small" theme="gray" disabled={isDefault} tip={isDefault ? "Default address" : "Make this default"} type={isDefault ? 'primary' : 'default'} />
                <IconButton onClick={onEditClick} icon="pen" size="small" />
                <Popconfirm title="Sure to delete?" onConfirm={onDeletePress}><IconButton icon="trash-alt" /></Popconfirm>
                {fields.verified == 'yes' && <div style={{ color: 'green' }}><Icon icon={'check'} style={{ marginRight: '2px' }} />Verified</div>}
            </div></Col>
        </Row>
        <div>{fields.full_address}, {fields.city}</div>
        {fields.delivery_instructions && <div style={{ marginTop: "20px" }}><i><pre>{fields.delivery_instructions}</pre></i></div>}
    </div>)
}

// function Subscriber({ QUERY, callback, variables, debug }) {
//     const { data, loading, error } = useSubscription(
//         QUERY,
//         {
//             // subscription: QUERY,
//             variables: variables,
//             // fetchPolicy: "network-only" // cache-first, cache-only, cache-and-network, network-only, no-cache, standby
//             onSubscriptionData: ({ client, subscriptionData }) => {
//                 const { data, error, loading, variables } = subscriptionData
//                 if (debug) console.log({ subscriptionData })
//                 if (callback) callback(subscriptionData)
//             },
//             shouldResubscribe: false, // Determines if your subscription should be unsubscribed and subscribed again
//         }
//     );

//     if (loading) {
//         if (debug) console.log("Receiving subscription on ....", variables);
//         return null;
//     }

//     if (data) {
//         if (debug) console.log('Subscription received: ', data);
//         // const { mutation, node } = data.formsDatasUpdated;
//         // if (callback) callback({ mutation, node })
//     }

//     return null;

// }





const AddressList = ({ user, userAddresses, setDefaultAddress, onDeletePress }) => {
    const [showAddreessForm, set_showAddreessForm] = useState(false)

    if (!userAddresses || userAddresses.length < 1) return null;

    const toggleAddressForm = args => {
        if (showAddreessForm) return set_showAddreessForm(false);
        set_showAddreessForm(args);
    }

    return (<>
        <Row gutter={[0, 12]}>
            <Col flex="auto"> </Col>
            <Col align="right"><Button size="large" type="primary" onClick={toggleAddressForm}>Add Another</Button></Col>
        </Row>

        {userAddresses.map((item, i) => (<RenderAddress key={i} fields={item}
            setDefaultAddress={() => setDefaultAddress(item)}
            onDeletePress={() => onDeletePress(item)}
            onEditClick={() => toggleAddressForm(item)}
        />))}

        <Modal title="" visible={showAddreessForm} width={620} onCancel={toggleAddressForm} footer={null} bodyStyle={{ padding: "0px" }}>
            {showAddreessForm && <AddressForm
                onCancel={() => toggleAddressForm(false)}
                onSuccess={() => toggleAddressForm(false)}
                fields={showAddreessForm}
                user_id={user._id}
            // onSuccess={this.onAddressAdded}
            />}
        </Modal>

    </>)
}

const UserAddressesPage = ({ user, client }) => {
    const [addressArray, set_data] = useState(null);
    const [busy, set_busy] = useState(false);
    const [error, set_error] = useState(false);

    useEffect(() => {
        fetchData();
    }, [user])

    const fetchData = async () => {
        set_busy(true);

        const results = await client.query({ query: GET_RECORD, variables: { id: user._id } })
            .then(e => (e.data.userAddresses))
            .catch(err => {
                console.log(__error("Request ERROR : "), err);
                return { error: { message: "Request ERROR" } }
            })

        set_busy(false);
        if (results.error) {
            set_error(results.error.message);
            return;
        }

        set_data(results);
    }

    const setDefaultAddress = async ({ _id }) => {
        console.log("setDefaultAddress: ", _id);

        return client.mutate({ mutation: SET_DEFAULT, variables: { id: _id, user_id: user._id } })
            .then(e => {
                const response = e.data.setDefaultAddress;
                if (!response.error) fetchData();
                return response;
            })
            .catch(err => {
                console.log(__error("Request ERROR : "), err);
                return { error: { message: "Request ERROR" } }
            })

    }

    const onDeletePress = async ({ _id }) => {
        console.log("onDeletePress()", _id)
        return client.mutate({ mutation: DELETE_RECORD, variables: { id:_id } })
            .then(e => (e.data.deleteUserAddress))
            .catch(err => {
                console.log(__error("Request ERROR : "), err);
                return { error: { message: "Request ERROR" } }
            })
    }

    const onSubscriptionReceived = ({ data, error, loading, variables }) => {
        const { mutation, node } = data.addressesUpdated;

        let _data = addressArray ? addressArray.slice() : [];

        if (mutation == "DELETED") _data = _data.filter(o => o._id != node._id);
        else if (mutation == "UPDATED") _data = _data.map(o => (o._id == node._id) ? { ...node } : o)
        else if (mutation == "CREATED") _data.push(node);
        else {
            console.log(__error("Invalid Subscription received: ", data));
            return;
        }

        set_data(_data)
    }


    return (<>
        <Subscriber QUERY={QUERY_SUBSCRIPTION} variables={{ id: user._id }} callback={onSubscriptionReceived} />

        <div className="h-center" style={{ marginBottom: "50px" }}>
            <div style={{ width: "100%", maxWidth: "600px" }}>
                <h3><span />Your Addresses<span /></h3>

                <Loader loading={busy}>
                    {error && <Alert message={error} showIcon={true} type="error" />}

                    <AddressList loading={busy} userAddresses={addressArray}
                        user={user}
                        setDefaultAddress={setDefaultAddress}
                        onDeletePress={onDeletePress}
                    />
                    {/* <DevBlock obj={addressArray} /> */}
                </Loader>
            </div>
        </div>

    </>)
}

const mapStateToAvatarPopProps = state => {
    return ({ user: (state.user && state.user.user) || {} });
}
export default connect(mapStateToAvatarPopProps)(withApollo(UserAddressesPage));

// export default WithApollo;
