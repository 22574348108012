import React from 'react'
import PropTypes from 'prop-types';
import { Col, Row, message } from 'antd';
import _ from 'lodash'
import { connect } from "react-redux";
// import { graphql, withApollo } from '@apollo/client/react/hoc';
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { Link } from 'react-router-dom';
import { __error } from 'Common/scripts/consoleHelper';
import { Icon, Button, IconButton, Drawer, Avatar, Loader } from 'Common/components';
import LastMinBuy from './LastMinBuy';
import CartItem from './CartItem';

const REMOVE_FROM_CART = loader('src/graphqls/cart/deleteCartItem.graphql');
const REMOVE_UNAVAILABLE_CART = loader('src/graphqls/cart/removeUnavailableItemsFromCart.graphql');


class CartDrawer extends React.Component {
    state = { busy: false, reviewVisible:false };
    constructor(props) {
        super(props);
        this.onDelete = this.onDelete.bind(this);
        this.clearUnavailableItems = this.clearUnavailableItems.bind(this);
        this.toggleReview = this.toggleReview.bind(this);
    }

    async onDelete(prod) {
        const { user, redux_cart, deleteCartItem, updateCart } = this.props;

        if (user && user._id) {
            let data = { customer_id: user._id, product_id: prod.product_id, delete_all: "yes" };
            let removed = await deleteCartItem({ input: data })
                .then(r => {
                    if (!r || !r.data) {
                        message.error("Invalid response!");
                        return false;
                    }
                    if (r.data.deleteCartItem.error) {
                        console.log(__error("ERROR"), r.data.deleteCartItem);
                        message.error(r.data.deleteCartItem.error.message);
                        return false;
                    }
                    return r;
                })
                .catch(err => {
                    console.log(__error("ERROR"), err);
                    message.error("Unable to complete your request at the moment");
                    return false; //{error:{message:"Unable to complete request"}}
                })

            console.log("removed: ", removed);
            // updateCart(removed.data.deleteCartItem);
            return true; // disable local cart update if user is logged in.. this will be done via subscription
        }

        // deleteProduct(prod);
        return redux_cart.items.length > 1; // return false if deleteing last item in cart, to avoide state error of unmountend
        // return false;

    }

    async clearUnavailableItems(){
        const { user } = this.props;
        this.setState({ busy:true });

        const updatedCart = await this.props.client.mutate({
            mutation: REMOVE_UNAVAILABLE_CART,
            variables: { id: user._id },
            // reducer: (previousResult, action, variables) => {
            //     console.log('reducer!!!!!', previousResult, action, variables);
            // }
        }).then(data => {
            return data.data.removeUnavailableItemsFromCart;
        }).catch(err => {
            console.log(__error("ERROR: "), err)
            return false;
        });

        if (updatedCart.error){
            message.error(updatedCart.error.message);
            return this.setState({ busy: false });
        }

        this.setState({ busy: false, reviewVisible:false });

    }

    async toggleReview(val){
        if (val==undefined) this.setState({ reviewVisible: !this.state.reviewVisible });
        else this.setState({ reviewVisible: val });
    }

    render() {
        const { busy } = this.state;
        const { isSmallDevice, loading, offerByKey, onClose, visible, redux_cart, user, settings } = this.props;

        let invalid_cart = (user && user._id && user._id != redux_cart.customer_id || (!redux_cart.items || !redux_cart.items[0]?._id)) ? true : false;
        if (!user || !user._id) invalid_cart = true;

        const items = _.isArray(redux_cart.items) ? redux_cart.items.slice() : [];
        
        const totalItems = items.length;
        const unavailableItems = !invalid_cart ? items.filter(i => (i.available_qty < 1 || i.available_qty < i.qty)) : null;
        // const availableItems = !invalid_cart ? items.filter(i => i.available_qty>0) : null;

        var hasOffer = false;
        let filtered_offer_items = [];
        if (offerByKey) {
            let offer_items = offerByKey && offerByKey.items || null;
            if (offer_items){
                offer_items.forEach(row => {
                    let item = items.find(o => o._id == row._id)
                    if (!item) filtered_offer_items.push(row);
                });
            }
            hasOffer = (!filtered_offer_items || filtered_offer_items.length < 1) ? false : true;
        }

        return (
            <Drawer width={isSmallDevice ? "100%" : "438px"} destroyOnClose={true} maskClosable={true} placement="right"
                loading={busy}
                className={`cart-drawer ${!invalid_cart && hasOffer ? "hasPromo" : ""}`}
                // onClose={onClose}
                visible={visible}
                bodyStyle={{}}
                onClose={()=>{
                    this.toggleReview(false);
                    onClose()
                }}
                // headerStyle={{ backgroundColor:"#F6F6F6"}}
                footer={totalItems<1 ? null : 
                    <div className="footer-inner-container">
                        {settings.min_order_amount > redux_cart.grandTotal ? <>
                            <p style={{color:"red"}}>Min order limit is {settings.currency} {settings.min_order_amount}</p>
                        </> : <>
                                <p>* Promo code can be applied on order confirmation</p>
                        </>}

                        <Link disabled={!(settings.min_order_amount <= redux_cart.grandTotal && (!unavailableItems || unavailableItems.length < 1))} to="/checkout" className="proceed-button" style={!(settings.min_order_amount <= redux_cart.grandTotal && (!unavailableItems || unavailableItems.length < 1)) ? {backgroundColor:"#CCC"} : {}}>
                            <span>Proceed to Checkout</span>
                            <span>{settings.currency} {redux_cart.grandTotal} <span style={{ fontSize: "12px", marginLeft: "10px" }}><Icon icon="chevron-right" /></span></span>
                        </Link>
                    </div>
                }
                title={<><Icon className="icon" icon="shopping-cart" />{(redux_cart.totalItems > 0) ? <><b>My Cart </b> ({redux_cart.totalItems} items)</> : <b>Your cart is empty</b>}</>}
            >
                {invalid_cart && <div className="cart-contents" style={{ height: "calc(100vh - 60px)", flex: 1, display: "flex" }}>
                    <div style={{ width: "100%", textAlign: "center", alignItems: "center", display: "flex", boxSizing: "border-box", paddingBottom: "150px" }}>
                        <div style={{width:"100%"}}>
                            <img width="70%" src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/no-item-in-cart.jpg`} />
                            <div style={{ fontSize: "22px" }}>NO ITEMS IN CART</div>
                        </div>
                    </div>
                </div>}

                {!invalid_cart && redux_cart.totalItems > 0 && <>
                    {(unavailableItems && unavailableItems.length > 0) && <div style={{ color: "red", backgroundColor: "rgba(255, 0, 0, 0.1)", margin:"10px 0"}}>
                        <Row align="middle">
                            <Col flex="auto" style={{padding:"10px"}}>
                                <div><b>{unavailableItems.length} item(s) out of stock</b></div>
                                <div style={{fontSize:"10px"}}>review to add alternatives</div>
                            </Col>
                            <Col style={{ paddingRight: "10px" }}><Button onClick={this.toggleReview}>Review</Button></Col>
                            {/* <Col style={{paddingRight:"10px"}}><Button block type="danger" onClick={this.clearUnavailableItems}>Clear</Button></Col> */}
                        </Row>
                    </div>}

                    <div className="cart-contents">
                        <Row>
                            <Col>Sub total</Col>
                        <Col flex="auto" align="right">{settings.currency} {redux_cart.totalPrice}</Col>
                        </Row>
                        {redux_cart.deliveryCharges > 0 && <Row>
                            <Col>Delivery Charges</Col>
                            <Col flex="auto" align="right">+ {settings.currency} {redux_cart.deliveryCharges}</Col>
                        </Row>}
                    </div>

                    {redux_cart.savedTotal > 0 && <div className="cart-contents">
                        <Row>
                            <Col>You saved</Col>
                            <Col flex="auto" align="right">{settings.currency} {redux_cart.savedTotal}</Col>
                        </Row>
                    </div>}

                    <div style={{ marginBottom: "5px" }} />

                    {items.reverse().map((item, i) => {
                        return <CartItem settings={settings} key={i} prod={item} onDelete={this.onDelete} />
                    })}

                    {hasOffer && <LastMinBuy loading={loading} offers={!loading ? offerByKey.items : []} />}

                </>}

                {this.state.reviewVisible && 
                    <div style={{ position:"absolute", bottom:0, backgroundColor:"rgba(0, 0, 0, 0.3)", width:"100%", height:"100%"}}>
                        <div style={{ position: "absolute", bottom: 0, backgroundColor: "white", width: "100%", height: "50%", minHeight:"500px" }}>
                            <div style={{ color: "red", backgroundColor: "rgba(255, 0, 0, 0.1)", margin: "0", padding:"10px" }}>
                                <Row>
                                    <Col flex="auto"><b>Review unavailable items</b></Col>
                                    <Col><Button onClick={() => this.toggleReview(false)}>X</Button></Col>
                                </Row>
                            </div>
                            
                            {unavailableItems.map((item, i)=>{
                                return <CartItem settings={settings} key={i} prod={item} />
                            })}
                            {/* {String("0123456789012456789").split("").map((item, i)=>{
                                return <h1 key={i}>{item}</h1>
                            })} */}

                            <div style={{textAlign:"center", padding:"10px"}}><Button type="danger" onClick={this.clearUnavailableItems}>Clear All Unavailable Products</Button></div>
                        </div>
                    </div>
                }

            </Drawer>
        )
    }

}
CartDrawer.propTypes = {
    visible: PropTypes.bool.isRequired
}


const WithApollo = compose(

    graphql(REMOVE_FROM_CART, {
        props: ({ mutate }) => ({
            deleteCartItem: (args) => mutate({
                variables: { ...args }
            }),
        })
    }),

)(CartDrawer);


const mapStateToProps = state => {
    return ({ settings: state.box_storefront, redux_cart: state.cart, user: (state.user && state.user.user) || false });
}
const WithRedux = connect(mapStateToProps)(WithApollo);

export default withApollo(WithRedux);