import React from 'react'
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { connect } from "react-redux";
import { Table, Avatar, Icon, DevBlock } from 'Common/components'

const renderTitle = (pickup_data, record) => {
    return (
        <Row gutter={[12, 0]}>
            {/* <Avatar size={40} shape="square" icon={<Icon icon="image" />} /> */}
            <Col flex="50px"><Avatar size={40} shape="square" src={record.picture_thumb ? `${process.env.REACT_APP_DATA_URL}/${record.picture_thumb}` : null} icon={<Icon icon="image" />} /></Col>
            <Col>{record.title}</Col>
        </Row>
    )
}


const RenderCart = props => {
    const { settings: { currency }, items, filter, order } = props;

     const columns = () => [
        { title: 'Product', dataIndex: 'title', render:renderTitle },
        { title: 'Price', dataIndex: 'price', align: "center", width: "60px", render:(__, rec)=>{
            return <>
                <div className='price-was'>{rec.price_was}</div>
                <div>{rec.price}</div>
            </>
        } },
        { title: 'Qty', dataIndex: 'deliverable_qty', align: "center", width: '50px', render:(__, rec)=>{
            return rec.deliverable_qty || rec.qty;
        } },
        { title: 'Total', dataIndex: 'total', width: '100px', align: "right", render: (text, rec) => {
                let qty = rec.qty;
                if (props?.pickup_data) {
                    let pickup_data = props?.pickup_data?.find(o => o.product_id == rec._id);
                    if (pickup_data) qty = pickup_data.pickedQty;
                }
                if (rec.deliverable_qty || props.page == "till-confirmation") qty = rec.deliverable_qty || 0;

                return Number(qty) * rec.price;
            }
        },
    ];

    let itemstoShow = [];
    if (filter) itemstoShow = items.filter(o => filter(o))
    else itemstoShow = items;

    return (<>
        <Table
            columns={columns()}
            dataSource={itemstoShow}
            pagination={false}
            // rowClassName={
            //     (record) => {
            //         console.log("record.delivery_status: ", record.delivery_status)
            //         return (record.delivery_status == 'canceled') ? 'cancled-table-row' : ""
            //     }
            // }
        />
        {/* <DevBlock obj={order} /> */}

    </>)
}
RenderCart.propTypes = {
    // onClose: PropTypes.func.isRequired,
    order: PropTypes.object.isRequired,
    // items: PropTypes.array.isRequired,
}
const mapStateToProps = state => {
    return ({ settings: state.box_storefront, });
}
export default connect(mapStateToProps)(RenderCart);
// export default RenderCart;