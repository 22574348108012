import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { Col, Row, message } from 'antd';
import _ from 'lodash'
import { connect } from "react-redux";
import { __error } from 'Common/scripts/consoleHelper';
import { Icon } from 'Common/components';
import { ProductList } from 'Layout_v1'


const LastMinBuy = ({ offers, settings }) => {
    const [showProds, setShowProds] = useState(false);
    if (!offers || offers.length < 1) return null;

    return (<div className={`cart-last-min-offer ${showProds ? "showProds" : ""}`}>
        <div className="lmb-bar" onClick={() => setShowProds(!showProds)}>
            <div className="bar-contents">
                <Row className="strip">
                    <Col className="offer-icon"><img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/offer-bag@2x.png`} /></Col>
                    <Col flex="auto"><div>
                        <div style={{fontSize:"1.3em"}}>Last Minute Buys</div>
                        <div style={{ fontSize: "1em" }}>Get special discount here!</div>
                    </div></Col>
                    <Col className="icon-holder" align="center"><Icon icon="chevron-up" className={`up-icon ${showProds ? "" : "bounceVertical"} `} /></Col>
                </Row>
            </div>
        </div>
        {showProds && 
            <div className="details">
                <ProductList loading={false} data={offers?.map(o=>(o.prod))} total={offers.length} itemClassName="last-min-offer-item" />
            </div>
        }
    </div>)
}

LastMinBuy.propTypes = {
    offers: PropTypes.array,
}
const mapStateToProps = state => {
    // return ({ settings: state.box_storefront, user: (state.user && state.user.user) || false });
    return ({ settings: state.box_storefront });
}
export default connect(mapStateToProps)(LastMinBuy);
