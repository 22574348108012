import React from 'react'
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { useMediaQuery } from 'react-responsive'
// import { Icon, Button, ToggleButton } from 'Common/components';
import { Footer, LayoutHeader, ContentArea } from './';

import withURLSync from 'Modules/AlgoliaSearch/URLSync'; 
// import { InstantSearch } from 'react-instantsearch-dom';
// import algoliasearch from 'algoliasearch/lite';
// import { AlgoliaHits } from 'Modules/AlgoliaSearch/AlgoliaHits';
import { AlgoliaHits, SearchWrapper } from 'Modules/AlgoliaSearch';

// const searchClient = algoliasearch(
//     process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
//     process.env.REACT_APP_ALGOLIA_API_KEY
// );



const _LayoutWrapper = props => {
    // const [collapsed, setNavCollaps] = React.useState(false);
    // const [collapsedWidth, setCollapsedWidth] = React.useState(undefined);
    const [pathName, setPathName] = React.useState('');

    const isTabletOrMobileDevice = useMediaQuery({ query: '(max-device-width: 1224px)' })
    // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

    React.useEffect(() => {
        const { pathname } = window.location;
        setPathName(pathname);
    }, []);


    return (<>
        <SearchWrapper searchState={props.searchState} createURL={props.createURL} onSearchStateChange={props.onSearchStateChange}>

            <LayoutHeader {...props.header} />

            <Layout className={`site-layout ${isTabletOrMobileDevice && 'isMobile'}`}>
                <Layout className="site-layout-content">
                    {props.skipContentArea && props.children}
                    {/* {!props.skipContentArea && <ContentArea>{props.children}</ContentArea>} */}
                    <div style={{ display: props.searchState.query && !pathName.includes('/search') ? 'none' : '' }}>
                        {!props.skipContentArea && <ContentArea>{props.children}</ContentArea>}
                    </div>
                    {props.searchState.query && !pathName.includes('/search') && <ContentArea><AlgoliaHits /></ContentArea>}
                </Layout>
            </Layout>

            <Footer />

        </SearchWrapper>
    </>)

}

export const LayoutWrapper = withURLSync(_LayoutWrapper);
// export default withURLSync(LayoutWrapper);

LayoutWrapper.propTypes = {
    menuOverride: PropTypes.array,
    topMenu: PropTypes.array,
    skipContentArea: PropTypes.bool,
    // labelA: PropTypes.oneOfType([
    //             PropTypes.string,
    //             PropTypes.object
    //         ]).isRequired,
}
