import React, { Component, Image } from 'react'
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import { Row, Col, Modal, message, Divider } from 'antd';
import { connect } from "react-redux";
import { Icon, Button, IconButton, Heading, Loader, DevBlock, CircleSpinner } from 'Common/components'
import { FormComponent, FormField, FormFieldGroup, rules, composeValidators } from 'Common/components/Form'
import { __error, __blue, __yellow } from 'Common/scripts/consoleHelper'
import { GoogleMap, useJsApiLoader, Autocomplete, LoadScript, useLoadScript, LoadScriptNext, useGoogleMap, Marker } from '@react-google-maps/api';
import { default_map_center, serviceCities, GOOGLE_API_KEY } from 'configs/constants';


// const GEO_ZONE = loader('src/graphqls/geo_zone/geoZone.graphql');
const GET_ZONES = loader('src/graphqls/geo_zone/geoZoneByCity.graphql');

const containerStyle = {
    width:'100%',
    maxWidth: '600px',
    height: '400px'
};

const librariesArray = ["places", "geometry"] //drawing,geometry,localContext,places,visualization

const placeStyle = {
    boxSizing: `border-box`,
    border: `1px solid transparent`,
    width: `240px`,
    height: `32px`,
    padding: `0 12px`,
    borderRadius: `3px`,
    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
    fontSize: `14px`,
    outline: `none`,
    textOverflow: `ellipses`,
    position: "absolute",
    left: "50%",
    marginLeft: "-120px"
}

const MyLocationButton = ({ onClick, loading }) => {
    return (
        <div onClick={onClick} className="bt-my-location" style={{ bottom: 110, right: 10 }}>
            {!loading && <span style={{ border: "0px solid black", paddingTop:"5px" }}><Icon icon="my-location" /></span>}
            {loading && <CircleSpinner loading={true} />}
        </div>
    )
}

const default_city = serviceCities.find(o => o._id == 'sahiwal');
const default_field_values = {
    center: default_city.coordinates, //serviceCities[0].coordinates, //{ lat: default_map_center.lat, lng: default_map_center.lng },
    city: default_city._id,
    label: 'home',
}


class LocationForm extends Component {
    theShape = null;
    polyArray = [];

    constructor(props) {
        super(props);
        this.autocomplete = null

        this.onAutoCompleteLoad = this.onAutoCompleteLoad.bind(this)
        this.onPlaceChanged = this.onPlaceChanged.bind(this)
        this.onMapUnmount = this.onMapUnmount.bind(this)
        this.onMapLoad = this.onMapLoad.bind(this)
        this.onCityChange = this.onCityChange.bind(this)
        this.moveToCity = this.moveToCity.bind(this)
        this.setCenter = this.setCenter.bind(this)
        this.checkLocationBounds = this.checkLocationBounds.bind(this)
        this.onDragEnd = this.onDragEnd.bind(this)
        this.onUserLocationReceived = this.onUserLocationReceived.bind(this)
        this.getUserGPSLocation = this.getUserGPSLocation.bind(this)

        var city = { ...default_city }
        const fields = this.props.userAddress ? { ...this.props.userAddress } : { ...default_field_values }

        const _state = {
            loading: this.props.loading,
            map: null, google: null,
            isInBounds: false,
            locationConfirmed: fields?._id ? true : false,
            fields,
            loadingUserGPS: false,
            // userGps: false,
            city,
            currentGeoLocation: null,
            options: {
                zoom: 12,
                fullscreenControl: true,
                mapContainerStyle: containerStyle,
                clickableIcons: false,
                mapTypeControl: false,
                panControl: false,
                streetViewControl: false,
            },
        }

        if (fields._id){
            Object.assign(_state, {
                city: {
                    title: fields.city,
                    _id: fields.city,
                    coordinates: { lat: fields.geo_point.coordinates[0].lat, lng: fields.geo_point.coordinates[0].lng }
                },
                fields: {
                    ...fields,
                    center: {
                        lat: fields.geo_point.coordinates[0],
                        lng: fields.geo_point.coordinates[1],
                    },
                }
            })
        }

        // console.log("_state: ", _state)

        // if (fields._id) {
        //     city = {
        //         title: fields.location.title,
        //         _id: fields.location.code,
        //         coordinates: { lat: fields.location.coordinates[0].lat, lng: fields.location.coordinates[0].lng }
        //     }
        //     Object.assign(fields, {
        //         center: {
        //             lat: fields.geo_point.coordinates[0],
        //             lng: fields.geo_point.coordinates[1],
        //         },
        //     })
        // }

        this.state = { ..._state }
    }

    componentWillUnmount() {
        console.log(__error("componentWillUnmount()"))
        // const { fields } = this.props;
        // if (fields && fields.geoCoords) this.setState({ center: fields.geoCoords, locationConfirmed:true })
        this.onMapUnmount()
    }

    componentDidMount(){
        // only fetch user GPS location if not an edit form
        // if (this.props.userAddress || this.state.loadUserLocation) return;

        // if (this.state.loadUserLocation) this.getUserGPSLocation();

        // sleep(100).then(r=>{
        //     this.getUserGPSLocation();
        // })
    }

    onMapUnmount() {
        // console.log(__blue(`onMapUnmount()`));

        this.autocomplete = null;
        this.setState({ map: null })
    }

    updateOptions(opts) {
        // console.log(__blue(`updateOptions()`));

        const { options } = this.state;
        const _options = Object.assign({ ...options }, { ...opts });
        this.setState({ options: _options })
    }

    onDragEnd = args => {
        console.log(__blue(`onDragEnd()`), args);

        const { map, google } = this.state;
        const _center = map.getCenter();

        let centerpoz = { lat: _center.lat(), lng: _center.lng() }
        console.log("centerpoz: ", centerpoz)

        
        this.setState({
            fields: {
                ...this.state.fields,
                center: centerpoz,
                geo_point: {
                    type: 'Point',
                    coordinates: [centerpoz.lat, centerpoz.lng]
                }
            },
            locationConfirmed: true
        }, () => {
            this.checkLocationBounds()
        })

        
        // this.updateOptions({ center: { lat: _center.lat(), lng: _center.lng() } })

        // const fields = { ...this.state.fields };
        // // console.log("fields: ", fields)

        // const point = { lat: _center.lat(), lng: _center.lng() }
        // // console.log("point: ", point);

        // const __fields = Object.assign({}, {...fields}, {
        //     center: { lat: point.lat, lng: point.lng },
        //     geo_point:{
        //         type: 'Point',
        //         coordinates: [point.lat, point.lng]
        //     }
        // });
        // // console.log("__fields: ", __fields)

        // this.setState({
        //     fields: { 
        //         ...this.state.fields,
        //         center: { lat: point.lat, lng: point.lng },
        //         geo_point: {
        //             type: 'Point',
        //             coordinates: [point.lat, point.lng]
        //         }
        //     },
        //     locationConfirmed: true
        // }, ()=>{
        //     this.checkLocationBounds()
        // })


        // // return;

        // // this.setState({ fields: __fields, locationConfirmed: true }, ()=>{
        // //     this.checkLocationBounds()
        // // })
        // // this.setState({ locationConfirmed: true}, ()=>{
        // //     this.checkLocationBounds()
        // // })
    }

    onAutoCompleteLoad = (_autocomplete) => {
        // console.log(__blue(`_autocomplete()`), _autocomplete);

        this.autocomplete = _autocomplete
    }

    onPlaceChanged = () => {
        // console.log(__blue(`onPlaceChanged()`));

        if (this.autocomplete !== null) {
            const _places = this.autocomplete.getPlace();
            if (!_places || !_places.geometry) return;
            // console.log("======== autocomplete ========");
            // console.log(_places)
            // console.log("================");

            // this.updateOptions({ center: { lat: _places.geometry.location.lat(), lng: _places.geometry.location.lng() } })
            this.setState({ fields: { ...this.state.fields, center: { lat: _places.geometry.location.lat(), lng: _places.geometry.location.lng() }} })
        } else {
            console.log('Autocomplete is not loaded yet!')
        }
    }

    onMapLoad(map) {
        // console.log(__yellow("onMapLoad()"));

        this.setState({ google: window.google, map }, ()=>{
            if (!this.state?.fields?._id) this.getUserGPSLocation()
            else this.onCityChange(this.state.city);
        })
    }

    resetPolygon() {
        // console.log(__blue(`resetPolygon()`));

        this.polyArray.forEach(poly => {
            poly.setMap(null);
        })
        // if (this.theShape) {
        //     this.theShape.setMap(null);
        //     this.setState({ polygon: null })
        // }
    }

    setShape(paths) {
        // console.log(__blue(`setShape()`), paths);

        if (!paths) return;
        const { google, map } = this.state;

        // this.resetPolygon()
        // this.theShape = new google.maps.Polyline({
        //     paths,
        //     strokeColor: "#FF0000",
        //     strokeOpacity: 1.0,
        //     strokeWeight: 3,
        // });

        const theShape = new google.maps.Polygon({
            paths,
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.05,
        });
        theShape.setMap(map);

        this.polyArray.push(theShape);

        // this.theShape = new google.maps.Polygon({
        //     paths,
        //     strokeColor: "#FF0000",
        //     strokeOpacity: 0.8,
        //     strokeWeight: 2,
        //     fillColor: "#FF0000",
        //     fillOpacity: 0.01,
        // });
        // this.theShape.setMap(map);
    }

    checkLocationBounds() {
        // console.log(__blue("checkLocationBounds()"));

        const { google, map, options, fields } = this.state;
        if (!google) return;

        if (!fields?.center?.lat && !fields?.center?.lng){
            console.log(__error("Center not found"));
            alert("Center not found!");
            return;
        }

        const point = new google.maps.LatLng(fields.center.lat, fields.center.lng);
        var isInBounds = false;

        this.polyArray.forEach(poly => {
            // poly.latLngs.getArray()
            var bounds = new google.maps.LatLngBounds();
            var city_bounds = new google.maps.LatLngBounds();
            poly.latLngs.getArray().forEach(function (path) {
                path.getArray().forEach(function (latLng) {
                    bounds.extend(latLng);
                    city_bounds.extend(latLng);
                })
            });

            let _isInBounds = google.maps.geometry.poly.containsLocation(
                point,
                poly
            )

            // map.panToBounds(city_bounds)
            // map.fitBounds(city_bounds)

            if (_isInBounds) isInBounds = _isInBounds;

        })

        this.setState({ isInBounds })

    }

    // getBounds() {
    //     // var bounds = new google.maps.LatLngBounds();
    //     // map.data.forEach(function (feature) {
    //     //     if (feature.getGeometry().getType() === 'Polygon') {
    //     //         feature.getGeometry().forEachLatLng(function (latlng) {
    //     //             bounds.extend(latlng);
    //     //         });
    //     //     }
    //     // });
    //     // return bounds;
    // }

    async loadRelatedZones(city){
        // console.log(__blue(`loadRelatedZones()`), city);

        const city_zones = await this.props.client.query({
            query: GET_ZONES,
            variables: { city: city._id, filter: JSON.stringify({ status: "published", category: 'service-area' }) },
            // reducer: (previousResult, action, variables) => {
            //     console.log('reducer!!!!!', previousResult, action, variables);
            // }
        }).then(data => {
            // console.log("data.data.geoZoneByCity: ", data.data.geoZoneByCity)
            return data.data.geoZoneByCity;
        }).catch(err => {
            // this.setState({ busy: false })
            return false;
        });

        // set new city zones
        if (!city_zones || city_zones.error || city_zones.length < 1) {
            alert(`Sorry! We are not surving in city (${city._id}) yet`)
        }
        else {
            city_zones.forEach(zone => {
                let coords = zone?.polygon?.coordinates[0];
                let _shape = coords.map(item => {
                    return new this.state.google.maps.LatLng(item[0], item[1])
                });
                this.setShape(_shape);
            })
        }

        // this.fitBounds();

        // check if user location is with in bounds
        this.checkLocationBounds()

    }

    async onCityChange(city) {
        // city: { title: v.raw.title, _id: v.raw.code, coordinates: {lat: v.raw.coordinates[0].lat, lng: v.raw.coordinates[0].lng } }
        // console.log(__blue(`onCityChange()`), city);

        this.setState({ loading: true, city }, ()=>{
            this.moveToCity(city);
            this.resetPolygon();
            this.loadRelatedZones(city);
    
            this.setState({ loading: false });
        });

    }

    fitBounds() {
        // console.log(__blue(`fitBounds()`));
        if (this.state?.fields?._id) return;

        const { google, map, options } = this.state;

        this.polyArray.forEach(poly => {
            var city_bounds = new google.maps.LatLngBounds();
            poly.latLngs.getArray().forEach(function (path) {
                path.getArray().forEach(function (latLng) {
                    city_bounds.extend(latLng);
                })
            });

            // map.panToBounds(city_bounds)
            map.fitBounds(city_bounds)
        })
    }

    moveToCity(_city, center) {
        // console.log(__blue(`moveToCity()`), { _city, center });

        this.setState({
            fields: {
                ...this.state.fields,
                center: center || (this.state?.fields?._id ? this.state.fields.center : _city.coordinates),
                city: _city._id,
            }
        }, () => {
            this.setCenter(this.state.fields.center)
            // this.setCenter(_city.coordinates);
        })

    }

    setCenter(centerPoint) {
        // console.log(__blue(`setCenter(${centerPoint.toString()})`));

        if (this.state.map) this.state.map.setCenter(centerPoint);
        else console.log(__error("map not found"))
    }

    onSubmit = values => {
        const { fields, locationConfirmed, isInBounds } = this.state;
        const { user } = this.props;
        if (!isInBounds){
            alert("Sorry you are out of service area!")
            return;
        }

        if (!locationConfirmed) {
            alert("Please confirm your location on the map!")
            return;
        }

        const data = {
            // user_id: user._id,
            full_address: values.full_address,
            city: values.city,
            label: values.label,
            // geoCoords: {
            //     lat: center.lat,
            //     lng: center.lng,
            //     latitudeDelta: center.latitudeDelta || 0.001,
            //     longitudeDelta: center.longitudeDelta || 0.001,
            // },
            geo_point: {
                type: 'Point',
                coordinates: [fields.center.lat, fields.center.lng]
            },
            delivery_instructions: values.delivery_instructions,
        }
        if (user?._id) Object.assign(data, { user_id: user._id })
        if (fields?._id) Object.assign(data, { _id: fields._id })

        this.props.onSubmit(data).then(r=>{
            if (!r) this.setState({ loading : false });
        }).catch(err=>{
            message.error("Unexpected error!")
            this.setState({ loading : false });
            console.log(__error("ERROR"), err)
        })

    }

    onMarkerPositionChanged = e => {
        const pointCenter = {
            lat: e.latLng.lat(),
            lng: e.latLng.lng()
        }

        this.setState({
            fields: {
                ...this.state.fields,
                center: pointCenter,
                geo_point: {
                    type: 'Point',
                    coordinates: [pointCenter.lat, pointCenter.lng]
                }
            },
            locationConfirmed: true
        }, () => {
            this.checkLocationBounds()
        })
    }

    // Get User GPS Location
    getUserGPSLocation = async () => {
        // console.log(__yellow("getUserGPSLocation()"), navigator.geolocation.getCurrentPosition);

        // if navigator?.geolocation not available then set the city location
        if (!navigator || !navigator.geolocation){
            this.onCityChange(this.state.city);
            return;
        }

        this.setState({ loadingUserGPS: true });

        // if user have no permissions to read GPS, load city location
        const havePermisions = await navigator.permissions.query({ name: 'geolocation' })
        // console.log("havePermisions: ", havePermisions)
        if (havePermisions.status == 'denied'){
            this.setState({ loadingUserGPS: false })
            message.error("No permissions to read GPS location")
            this.onCityChange(this.state.city);
            return;
        }

        // handel gps request
        navigator.geolocation.getCurrentPosition(
            position => {
                // this.setState({ loadingUserGPS: true });
                this.onUserLocationReceived(position)
            },
            error => {
                this.setState({ loadingUserGPS: false })
                console.log(__error("ERROR: "), error);
                message.error("Unable to read your GPS location!");
                this.onCityChange(this.state.city);
            },
            { maximumAge: Infinity, timeout: 3000 }
        );

        // if (navigator.geolocation && navigator.geolocation.getCurrentPosition){
        //     this.setState({ loadingUserGPS:true });
        //     navigator.geolocation.getCurrentPosition(this.onUserLocationReceived);
        // }
        // else {
        //     console.log(__error("Geolocation is not supported by this browser."))
        //     this.onCityChange(this.state.city);
        // }

    }

    onUserLocationReceived(position){
        // console.log(__yellow('onUserLocationReceived()'), position)

        const { city } = this.state;
        const pointCenter = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
        }
        this.setState({ loadingUserGPS:false })

        this.setState({ loading: true, city }, () => {
            this.moveToCity(city, pointCenter);
            this.resetPolygon();
            this.loadRelatedZones(city);

            this.setState({ loading: false });
        });


    }




    render() {
        const { loading, options, map, locationConfirmed, loadingUserGPS, fields } = this.state;
        // if (loadUserLocation) return <Loader loading={true}><div style={{ padding: "20px" }}>Checking your location...</div></Loader>

        return (<div className="h-center" style={{ marginBottom: "50px" }}>
            <div style={{ width: "100%", maxWidth: containerStyle.maxWidth, }}>
                {loading && <div style={{width:"100%", height:"100%", backgroundColor:"rgba(255, 255, 255, 0.5)", position:'absolute', top:0, left:0}}><Loader loading={true} /></div>}
                {/* <DevBlock obj={this.state.fields.center} /> */}

                <FormComponent onSubmit={this.onSubmit} id={`UserLocationForm_${fields && fields._id}`} debug={true} fields={fields}
                    form_render={(formprops)=>{
                        const { values, errors, dirty, submitFailed } = formprops;
                        return(<>
                            <h3 style={{ textAlign: "center", padding:"10px 0 0 0" }}>{fields && fields._id ? 'Edit' : 'Select'} your location ({values.city})</h3>
                            {!this.state.isInBounds && <p align="center"><span style={{ color: "red", fontSize: "16px" }}>Sorry! You are out of service area</span></p>}

                            {/* <LocationSelection label="City" preload={{ type: 'city' }} name="city" filter={{ type: 'city' }} validate={composeValidators(rules.required)}
                                onChange={(v)=>this.onCityChange({ title: v.raw.title, _id: v.raw.code, coordinates: { lat: v.raw.coordinates[0].lat, lng: v.raw.coordinates[0].lng } })}
                            /> */}

                            <div style={{ border: "1px solid #EEE", height: containerStyle.height, position: "relative" }}>
                                <LoadScript googleMapsApiKey={GOOGLE_API_KEY} libraries={librariesArray}><>
                                    <GoogleMap
                                        {...options}
                                        options={options}
                                        center={fields.center}
                                        mapTypeControl={false}
                                        panControl={false}
                                        streetViewControl={false}
                                        id="address_map"
                                        onCenterChanged={console.log}
                                        // onClick={(e) => console.log("onClick()", e)}
                                        onDragEnd={this.onDragEnd}
                                        onDrag={console.log}
                                        // onDragStart={() => console.log("onDragStart()")}
                                        onLoad={this.onMapLoad}
                                        onUnmount={this.onMapUnmount}
                                    ><>
                                        <Marker position={this.state.fields.center} />
                                        {/* <Marker onDragEnd={this.onMarkerPositionChanged} position={fields.center} draggable={true} /> */}
                                        {/* <Marker onLoad={() => console.log("onLoad()")} position={{ centerlat: 31.53812216736883, lng: 74.39549816111118 }} /> */}
                                        {/* <Marker onLoad={() => console.log("onLoad()")} position={{ ...fields.geoCoords }} /> */}
                                        <Autocomplete
                                            onLoad={this.onAutoCompleteLoad}
                                            onPlaceChanged={this.onPlaceChanged}
                                            // {
                                            //     "DD": {"lat":31.54972,"lng":74.34361},
                                            //     "DMS":{"lat":"31º32'58.99\" N","lng":"74º20'37\" E"},
                                            //     "geohash":"ttsg7xrq7w",
                                            //     "UTM":"43R 437698.75744558 3490714.05265869"
                                            // }

                                            // types={["address"]}
                                            // bounds={{ lat: 31.54972, lng: 74.34361 }}
                                            options={{
                                                strictBounds: true,
                                                // bounds: { lat: 31.54972, lng: 74.34361 },
                                                componentRestrictions: { country: "pk" }
                                            }}
                                        >
                                            <input style={{ marginTop: "100px" }} placeholder="Search Your Location" type="text" style={placeStyle} />
                                        </Autocomplete>

                                        <MyLocationButton loading={loadingUserGPS} onClick={() => this.getUserGPSLocation()} />
                                    </></GoogleMap>
                                </></LoadScript>
                            </div>

                            <Row>
                                <Col flex="auto">
                                    <FormField type="text" name="full_address" label={"Full Address"} placeholder="Full Address" validate={composeValidators(rules.required, rules.minChar(10))} />
                                </Col>
                            </Row>

                            <FormField type="text" name="delivery_instructions" label={"Notes"} placeholder="extra noted" />

                            {errors && errors.label && dirty && submitFailed && <div style={{color:"red"}}>Please select an option from below!</div>}
                            <FormField type="radio-button-field" name="label" data={[
                                { title: "Home", _id: 'home' },
                                { title: "Office", _id: 'office' },
                                { title: "Other", _id: 'other' }
                            ]}
                                validate={composeValidators(rules.required)}
                            />

                            <div style={{ margin: "15px 10px" }}><Button disabled={!locationConfirmed || !this.state.isInBounds} type="primary" htmlType="submit" block>{this.props.buttonLabel || "Save"}</Button></div>
                        </>)

                    }}
                >

                </FormComponent>

            </div>
        </div>)

    }


}

const mapStateToAvatarPopProps = state => {
    return ({ user: state.user ? state.user.user : {} });
}
export const WithRedux = connect(mapStateToAvatarPopProps)(LocationForm);

export default withApollo(WithRedux);

// export default WithApollo;
