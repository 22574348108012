import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Button, Icon, Loader, Table } from 'Common/components';
import { Row, Col, DatePicker, Space } from 'antd' 
import moment from 'moment'
import MediaQuery, { useMediaQuery } from 'react-responsive'
import { mongoToDate, parseDeliverySlotData } from 'Common/scripts/Functions'
import { connect } from "react-redux";
import { default_timeslot_days_to_show } from 'configs'
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import aClient from 'apollo/aClient'


import 'Layout_v1/styles/timeslot-selector.scss'


const GET_QTY = loader('src/graphqls/delivery_slot/getTotalOrdersForSlot.graphql');
const GET_QTYS = loader('src/graphqls/delivery_slot/getTotalOrdersForSlots.graphql');


const RenderSlot = props => {
    // console.log("RenderSlot: ", props);
    const { data, date,  box_storefront, maxDate, onSlotClick, selectedSlot } = props;
    // const { date } = data;
    // console.log("user: ", props.user.user._id)

    const dayAbbr = moment(date).format('ddd').toLowerCase();
    const thisDayData = data[dayAbbr];

    // const checkQty = async args => {
    //     // console.log("data: ", props)

    //     let slotData = parseDeliverySlotData({
    //         date: props.date,
    //         time_range_utc: props.time_range_utc
    //     })

    //     // console.log("slotData: ", slotData)

    //     let _date = slotData.start_date.format("YYYY-MM-DD HH:mm")

    //     const qtys = await aClient.query({
    //         query: GET_QTY,
    //         variables: { start_time: _date },
    //         // reducer: (previousResult, action, variables) => {
    //         //     console.log('reducer!!!!!', previousResult, action, variables);
    //         // }
    //     }).then(data => {
    //         console.log("data: ", data.data.getTotalOrdersForSlot)
    //         return data;
    //         // return data.data.getTotalOrdersForSlot;
    //     }).catch(err => {
    //         // this.setState({ busy: false })
    //         return false;
    //     });

    //     return;

    //     // getTotalOrdersForSlot({ _id: props._id, time_range_utc_0: JSON.parse(props.time_range_utc)[0], date:props.date }).then(r=>{
    //     //     console.log("r: ", r);
    //     //     return ;
    //     // })

    //     // const qtys = await aClient.query({
    //     //     query: GET_QTY,
    //     //     variables: { input: { _id: props._id, time_range_utc_0: JSON.parse(props.time_range_utc)[0], date: props.date } },
    //     //     // reducer: (previousResult, action, variables) => {
    //     //     //     console.log('reducer!!!!!', previousResult, action, variables);
    //     //     // }
    //     // }).then(data => {
    //     //     console.log("data: ", data.data.getTotalOrdersForSlot)
    //     //     return data;
    //     //     // return data.data.getTotalOrdersForSlot;
    //     // }).catch(err => {
    //     //     // this.setState({ busy: false })
    //     //     return false;
    //     // });

    // }

    let notAvailable = false;
    let expired = false;
    let available = true; // max_order_limit>0 && (max_order_limit < 1 || max_order_limit > totalOrders);
    let selected = false;


    const isTabletOrMobileDevice = useMediaQuery({ query: '(max-device-width: 1000px)' })
    // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

    let info = [];

    let blocked_dates = (!props.blocked_dates || props.blocked_dates == 'null' || props.blocked_dates == null) ? null : props.blocked_dates.split(",");
    
    let max_order_limit = thisDayData && thisDayData.max_order_limit ? thisDayData.max_order_limit : 0; // Number(props.max_order_limit || 0);
    let totalOrders = thisDayData && thisDayData.totalOrders ? thisDayData.totalOrders : 0; //(props.totalOrders || 0);
    let thisDay = moment(date);//.format('DD-MM-YYYY');
    
    if (available) available = blocked_dates ? blocked_dates.indexOf(thisDay.format('DD-MM-YYYY'))<0 : true;

    const _onSlotClick = args => {
        let _props = { ...props }
        delete _props.onSlotClick;
        delete _props.selectedSlot;
        delete _props.__typename;
        delete _props.onSlotClick;
        onSlotClick(_props)
    }

    if (date && selectedSlot) {
        selected = moment(selectedSlot.date).format("DD-MM-YYYY") == moment(date).format("DD-MM-YYYY");
        if (selected) selected = selectedSlot.end_time == props.end_time;
        if (selected) selected = selectedSlot.start_time == props.start_time;
        if (selected) selected = selectedSlot._id == props._id;
    }

    if (props.time_range_utc){
        let time_range_utc = JSON.parse(props.time_range_utc);

        let utc_t1 = mongoToDate(time_range_utc[0]);
        let utc_t2 = mongoToDate(time_range_utc[1]);

        // set thisDay with slot times
        let new_t1 = thisDay.clone().set({ hour: utc_t1.format("HH"), minute: utc_t1.format("mm")});
        let new_t2 = thisDay.clone().set({ hour: utc_t2.format("HH"), minute: utc_t2.format("mm")});

        // expired = new_t1.isBefore(mongoToDate().add(1, 'hr'));
        // add time gap
        expired = new_t1.isBefore(mongoToDate().add(box_storefront.timeslot_pre_gap || 5, 'm'));

        // check if slot is after the max date
        if (props.maxDate){
            let _d1 = new_t1.set({ hour: '00', minutes: '00', seconds: '00' });
            let _d2 = props.maxDate.set({ hour: '00', minutes: '00', seconds: '00' });
            notAvailable = _d2.isBefore(_d1);
            // console.log(`${_d1.format('DD-MM-YYYY HH:mm')} > ${_d2.format('DD-MM-YYYY HH:mm')} = ${_d2.isBefore(_d1)}`)
            // // let before_maxDate = props.maxDate.isBefore(new_t1);
            // notAvailable = new_t1.set({ hour: '00', minutes: '00', seconds: '00' }).isAfter(props.maxDate.set({ hour: '00', minutes: '00', seconds: '00' }));
            // // console.log(`after_maxDate: ${props.maxDate.format('DD-MM-YYYY HH:mm')} < ${new_t1.format('DD-MM-YYYY HH:mm')}`, notAvailable)
        }
    }

    // console.log("box_storefront: ", box_storefront)

    
    let bookingClsoed = (max_order_limit > 0 && max_order_limit <= totalOrders) ? true : false;

    let alt_info = "";// thisDayData ? `${totalOrders | '0'} / ${thisDayData.max_order_limit | '0'}` : undefined;

    // notAvailable = props.user.user._id != 5;

    
    if (bookingClsoed) {
        return <>{info}<Button title={alt_info} size={isTabletOrMobileDevice ? "medium" : "large"} theme="gray" block disabled>Clsoed.</Button></>
    }
    if (notAvailable) {
        return <>{info}<Button title={alt_info} size={isTabletOrMobileDevice ? "medium" : "large"} theme="gray" block disabled>Not Available</Button></>
    }
    if (expired || !props._id || notAvailable) {
        return <>{info}<Button title={alt_info} size={isTabletOrMobileDevice ? "medium" : "large"} theme="gray" block disabled>Closed</Button></>
    }
    else {
        return <>{info}
            <Button title={alt_info} size={isTabletOrMobileDevice ? "medium" : "large"} block theme={selected ? "blue" : "green"} onClick={selected ? null : _onSlotClick} disabled={!available}>
                <>
                    {available && <><Icon icon={['far', selected ? "check-square" : "square"]} /> </>}
                    {available ? (selected ? "Selected" : "Available") : "Booked"}
                </>
            </Button>
            {/* <Button onClick={() => checkQty()}>Check Qty</Button> */}
            </>
    }

}




export class TimeSlotSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: this.props.defaultDate || moment(), 
            columns: this.props.columns, 
            data: this.props.data, 
            selectedSlot: null,
            week: 1,
            dataColumns: props.dataColumns, 
            dataRows: props.dataRows,
            busy:true,
        };
        this.onPrevClick = this.onPrevClick.bind(this);
        this.onNextClick = this.onNextClick.bind(this);
    }

    // static getDerivedStateFromProps(props, state) {
    //     // console.log("getDerivedStateFromProps().props: ", props);
    //     const weekData = props.getWeek(state.week);

    //     return {
    //         ...state,
    //         // data: props.data,
    //         // columns: props.columns,
    //         loading: props.loading,
    //         ...weekData,
    //         // dataColumns: props.dataColumns,
    //         // dataRows: props.dataRows
    //         // ...weekData
    //     };
    //     // if (props.loadingEditNode !== state.loadingEditNode) {
    //     //     return {
    //     //         ...state,
    //     //         data: props.data,
    //     //         columns: props.columns,
    //     //     };
    //     // }

    // }

    componentDidMount(){
        this.setState({ busy:true })
        this.props.getWeek(this.state.week).then(r=>{
            this.setState({ ...r, busy: false })
        });
    }

    onSlotClick = args => {
        // this.setState({ selectedSlot:args})
        this.props.onSlotClick(args);
    }

    onPrevClick = args => {
        if (this.state.week <= 1) return;
        // this.onDateChange(this.state.date.subtract(1, 'months'))
        this.setState({ busy:true })
        this.props.getWeek(this.state.week - 1).then(weekData=>{
            this.setState({ week: this.state.week - 1, ...weekData, busy: false })
        });
    }

    onNextClick() {
        if (this.state.week >= 4) return;
        // this.onDateChange(this.state.date.add(1, 'months'))
        // this.setState({ week: this.state.week + 1 })
        this.setState({ busy: true })
        this.props.getWeek(this.state.week + 1).then(weekData=>{
            this.setState({ week: this.state.week + 1, ...weekData, busy: false })
        });
    }
    
    // onDateChange = date => {
    //     console.log("onDateChange()", date);
        
    //     this.setState({ date });
    //     this.props.onDateChange(date);
    // }

    render() {
        const { dataColumns, dataRows, loading, week, busy } = this.state;
        const { selectedSlot } = this.props;

        if (busy) return <Loader loading={true} />


        // console.log("dataRows: ", dataRows)

        // timeslot_days_to_show

        // let maxDate = mongoToDate().add(this.props.box_storefront.timeslot_days_to_show || 3, 'd');
        let maxDate = mongoToDate().add(this.props.box_storefront.timeslot_days_to_show || default_timeslot_days_to_show, 'd');
        // console.log("today: ", mongoToDate().format())
        // console.log("maxDate: ", maxDate.format())
        
        const _columns = dataColumns.map((item, i) => {
            // console.log("item: ", item);

            /// header column
            if (item.dataIndex == "time_range") {
                return {
                    ...item, width: 80, fixed: true, align:'right', className: "time_col-",
                    // render: (text, rec) => (rec.time_range.text)
                }
            }

            return { 
                ...item, 
                // width:300,
                render: (text, rec) => {
                    return <RenderSlot 
                        {...text} 
                        user={this.props.user}
                        box_storefront={this.props.box_storefront} 
                        selectedSlot={selectedSlot} 
                        onSlotClick={this.onSlotClick} 
                        data={rec} 
                        date={item.date} 
                        maxDate={maxDate} 
                        />
                }
            }
            // return { ...item, width: 300, render: (text, rec) => <RenderSlot selectedSlot={selectedSlot} onSlotClick={this.onSlotClick} {...rec[item.dataIndex]} />}
        })
        
        return (
            <Loader loading={loading}>
                <div className="timeslot-selector">
                    <Row className="header">
                        <Col>
                            <MediaQuery minDeviceWidth={1000}>
                                {(matches) => matches ? 
                                    <Button size="large" className="gray" disabled={(week <= 1)} onClick={this.onPrevClick} style={{ width: "170px" }}><Icon style={{marginRight:"10px"}} icon="angle-left" /> Previous Slots</Button>
                                    :
                                    <Button size="large" className="gray" disabled={(week <= 1)} onClick={this.onPrevClick} style={{ width: "50px" }}><Icon icon="angle-left" /></Button> 
                                }
                            </MediaQuery>
                        </Col>
                        <Col flex="auto" align="center">
                            <div style={{ fontSize: "18px", color:"#999"}}>
                                <Icon size="1x" color="#999" icon="calendar-alt" /> {moment().add(week-1, 'weeks').format("MMM-YYYY")}
                            </div>
                        </Col>
                        <Col>
                            <MediaQuery minDeviceWidth={1000}>
                                {(matches) => matches ?
                                    <Button size="large" className="gray" disabled={week >= 2} onClick={this.onNextClick} style={{ width: "170px" }}>Next Slots <Icon style={{ marginLeft: "10px" }} icon="angle-right" /></Button>
                                    :
                                    <Button size="large" className="gray" disabled={week >= 2} onClick={this.onNextClick} style={{ width: "50px" }}><Icon icon="angle-right" /></Button>
                                }
                            </MediaQuery>
                        </Col>
                    </Row>
                    
                    <Table
                        // components={Loader}
                        className="timeslotTable"
                        columns={_columns}
                        dataSource={dataRows}
                        rowKey={record => record.key}
                        pagination={false}
                        scroll={{ x: 'auto' }}
                        bordered={false}
                    />
                    
                    {/* <p style={{margin:"50px"}}> </p> */}

                </div>
            </Loader>
        )
    }
}

TimeSlotSelector.propTypes = {
    // topBanner: PropTypes.string,
    // dataColumns: PropTypes.array.isRequired,
    // dataRows: PropTypes.array,
    timeslot: PropTypes.object,
    defaultDate: PropTypes.object,
    // onNextClick: PropTypes.func.isRequired,
    // onPrevClick: PropTypes.func.isRequired,
    // onDateChange: PropTypes.func.isRequired,
    // labelA: PropTypes.oneOfType([
    //             PropTypes.string,
    //             PropTypes.object
    //         ]).isRequired,
}

const mapStateToProps = state => {
    return ({ 
        box_storefront: state.box_storefront,
        user: state.user
    });
}
// const mapDispatchToProps = (dispatch, ownProps) => ({
//     timeslotSelect: (payload) => dispatch(timeslotSelect(payload)),
// })
export default connect(mapStateToProps)(TimeSlotSelector);

// export default TimeSlotSelector;