import React from 'react'
import { Layout, Row, Col, Collapse } from 'antd';
import { Link } from 'react-router-dom';
import { Icon } from 'Common/components'
import { useMediaQuery } from 'react-responsive'
import { appText } from 'configs'

const { Panel } = Collapse;



export const Footer = props => {
    const isTabletOrMobileDevice = useMediaQuery({ query: '(max-device-width: 1000px)' })

    return (
            <Layout.Footer className={`site-footer ${isTabletOrMobileDevice ? 'isMobile' : ''}`}>
            <div className={`footer-inner ${isTabletOrMobileDevice ? 'isMobile' : ''}`}>
                <Row style={{ padding: "10px 0" }}>
                    <Col flex="auto">
                        <img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/logo-combined-centered.png`} style={{ width: "120px" }} />
                    </Col>
                    <Col><div style={{opacity:0.3}}>
                        <img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/icon-facebook.png`} width="30" />
                        <img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/icon-twitter.png`} width="30" style={{margin:"5px"}} />
                        <img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/icon-instagram.png`} width="30" />
                    </div></Col>
                </Row>
                <hr />

                {isTabletOrMobileDevice && <>
                    <Collapse ghost style={{border:'none', marginBottom:"20px"}}>
                        <Panel header={appText.about_app} key="1">
                            <ul>
                                <li><Link to="/page/about-us">About Us</Link></li>
                                <li><Link to="/page/privacy-policy">Privacy policy</Link></li>
                                <li><Link to="/page/terms-and-conditions">Terms & Conditions</Link></li>
                            </ul>
                        </Panel>
                        <Panel header="Help Center" key="2">
                            <ul>
                                <li><Link to="/page/help">Help</Link></li>
                                <li><Link to="/page/faqs">FAQs</Link></li>
                                <li><Link to="/page/returns-refunds">Returns & Refunds</Link></li>
                                {/* <li><Link to="/page/cash-on-delivery">Cash on delivery</Link></li> */}
                            </ul>
                        </Panel>
                        <Panel header="Contact Us" key="3">
                            <ul>
                            <li style={{ padding: "5px 0" }}><Icon style={{ fontSize: "12px", marginRight: "5px" }} icon="phone-alt" /> 03111 660 666</li>
                            <li><Link to="/page/feedback"><Icon style={{ fontSize: "14px", marginRight: "5px" }} icon="comment" /> Feedback</Link></li>
                            <li><Link to="/page/contact-us"><Icon style={{ fontSize: "16px", marginRight: "5px" }} icon="at" /> Contact us</Link></li>
                            </ul>
                        </Panel>
                    </Collapse>

                <div style={{ textAlign: "center" }}>
                    {/* <img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/box-url-qrcode.png`} width="100px" /> */}
                    <div>Download App</div>
                    <a href="https://play.google.com/store/apps/details?id=com.box_app" target='_blank'><img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/googleplay.png`} /></a>
                    {/* <img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/appstore.png`} /> */}
                </div>

                </>}

                {!isTabletOrMobileDevice && <>
                    <Row style={{ justifyContent:"space-between", margin:"20px 0 40px 0"}}>
                        <Col>
                            <h3>{appText.about_app}</h3>
                            <Link to="/page/about-us">About Us</Link><br />
                            <Link to="/page/privacy-policy">Privacy policy</Link><br />
                            <Link to="/page/terms-and-conditions">Terms & Conditions</Link><br />
                        </Col>
                        <Col>
                            <h3>Help Center</h3>
                            <Link to="/page/help">Help</Link><br />
                            <Link to="/page/faqs">FAQs</Link><br />
                            <Link to="/page/returns-refunds">Returns & Refunds</Link>
                            {/* <Link to="/page/cash-on-delivery">Cash on delivery</Link><br /> */}
                        </Col>
                        <Col>
                            <h3>Contact Us</h3>
                            <div><Icon icon="phone-alt" /> 03111 660 666</div>
                            <Link to="/page/feedback"><Icon icon="comment" /> Feedback</Link><br />
                            <Link to="/page/contact-us"><Icon icon="at" /> Contact us</Link><br />
                        </Col>
                        <Col><div style={{textAlign:"center"}}>
                            {/* <img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/box-url-qrcode.png`} width="100px" /> */}
                            <div>Download App</div>
                            <a href="https://play.google.com/store/apps/details?id=com.box_app" target='_blank'><img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/googleplay.png`} /></a>
                            {/* <img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/appstore.png`} /> */}
                        </div></Col>
                    </Row>
                </>}

                <div style={{ textAlign: "center", color: "#999" }}>{appText.footer_text}</div>

            </div>
            </Layout.Footer>
        )
}

export default Footer;