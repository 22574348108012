import React from 'react'
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { Menu, Dropdown, Row, Col, Divider, Affix, message, Badge } from 'antd';
import { __error } from 'Common/scripts/consoleHelper';
import { constructCategoryArray } from 'Common/scripts/Functions';
import { Icon, Button, Loader } from 'Common/components';


const _LayoutTopMenu = props => { 
    // const [subMenus, setSubMenus] = React.useState([]);
    const [parents, setParents] = React.useState(["root"]);
    // const [affixed, setAffix] = React.useState(false);

    var { loading, productCats, settings, cart, visible } = props;
    // const totalItems = cart && cart.items ? cart.items.length : 0

    const onMouseOver = node => {
        let p = parents.slice();
        if (node.children && node.children.length > 0) {
            let index = p.findIndex(o => o == node._id);
            if (index == -1) {
                // remove same level
                p.splice(node.level + 1);
                // add new level
                p.push(node._id);
                setParents(p);
            }
        } else {
            // remove child nodes after this level when there are no more children
            if (parents.length > node.level) {
                p.splice(node.level + 1);
                setParents(p);
            }
        }

    }
    
    const RenderViewAll = ({parentIds}) => {
        if (parents.length < 1 || !parentIds) return null;
        let pNode = productCats.filter(o => o._id == parentIds)[0];
        if (!pNode || !pNode._id) return null;

        return (<>
            <Divider />
            <Link to={`/category/${pNode.slug}`} className={`dd-menu-item`}>View All {pNode.title}</Link>
        </>)
    }


    const RenderMenus = () => {
        if (loading || !productCats) return null;

        return parents.map((parentIds, i1) => {
            let row = constructCategoryArray(productCats, parentIds == "root" ? null : parentIds);

            return (
                <Col className="dd-menu-col" key={i1}>
                    {row.map((item, i) => {
                        let _item = { ...item, level: i1}
                        return <Link to={`/category/${item.slug}`} className={`dd-menu-item level_${i1} ${parents.indexOf(item._id) > -1 ? 'selected' : ''}`}
                            onMouseOver={() => onMouseOver({ ..._item })}
                            key={i}>{`${item.title}`} {item.children && item.children.length > 0 && <span style={{fontSize:"12px"}}><Icon icon="chevron-right" /></span>}</Link>
                    })}
                    <RenderViewAll parentIds={parentIds} />
                </Col>
            )
        })

    }

    const ddMenu = () => (<div className="dropdown-menu">
            <Loader loading={props.loading} style={{padding: "25px",}} />
            {!loading && productCats &&
                <Row className="dd-menu-row">
                    {RenderMenus()}
                </Row>
            }
        </div>)

    var total = (!cart || isNaN(Number(cart.total).toFixed(2))) ? 0 : Number(cart.total).toFixed(2);
    
    // if (props.affixed) return !visible ? null : (
    //     <div className="site-layout-topmenu-affixed">
    //         <div className="shadow"></div>
    //         <div className="inner-wrapper">
    //             <div className="row">
    //                 <Link to="/"><img style={{ width: "135px", height: "51px", margin: "5px 0" }} src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/logo-190.png`} /></Link>
    //                 <div style={{ flex: "auto", margin: "0 30px" }}>
    //                     <SearchField name="keywords" size="large"
    //                         placeholder="Search for products..."
    //                         onSearch={(txt) => window.location = `/search/${txt}`}
    //                         style={{ width: "100%" }} enterButton />
    //                 </div>
    //                 <Button size="large" onClick={() => {
    //                     document.body.scrollTop = 0;
    //                     document.documentElement.scrollTop = 0;
    //                 }} className="gray-button" style={{ margin: "0 20px 0 0px" }}>Menu <Icon icon="angle-up" /></Button>

    //                 {!loading && <>
    //                     <Button className='shoppingcartButton' theme="light-gray" size="large" onClick={props.onCartClick}>
    //                         <Badge count={totalItems}><Icon style={{ fontSize: '1.5em' }} icon="shopping-cart" /></Badge>
    //                         <span className="txt"> {settings.currency} {total}</span> <span style={{ fontWeight: 100, fontSize: "12px" }}><Icon icon="chevron-down" /></span>
    //                     </Button>
    //                 </>}
    //             </div>
    //         </div>
    //     </div>
    // )

    return (
        <div className="site-layout-topmenu-wrapper">
            <div className="site-layout-topmenu">
                <Dropdown overlay={ddMenu()} overlayClassName="dropdown-menu-wrapper" trigger={['click']}>
                    {/* <Button type='link' className='menu-item gray-link'>Groceries <Icon icon="angle-down" /></Button> */}
                        <div className='menu-item'><div className='gray-link'>Categories <Icon className="_icon" icon="chevron-down" /></div></div>
                </Dropdown>
                {/* <Divider type="vertical" /> */}
                {/* <div className='menu-item'><Link className='gray-link' to="/category">Recipes</Link></div> */}
                {/* <Button type='link' to="/category" className='gray-link'>Recipes</Button> */}
                {/* <Divider type="vertical" />
                <div className='menu-item'><Link to="/offers" className='gray-link'>Offers</Link></div>
                <Divider type="vertical" />
                <div className='menu-item'><Link to="/list" className='gray-link'>Fruites & Lists</Link></div> */}
            </div>

        </div>
    )
}
const mapStateToProps = state => {
    return ({ settings: state.box_storefront, cart: state.cart });
}
export const LayoutTopMenu = connect(mapStateToProps)(_LayoutTopMenu);




// const WithApollo = compose(

//     graphql(LIST_DATA, {
//         options: props => {
//             // return { variables: { filter: JSON.stringify({ parent_cat_id:null }) } };
//             return {
//                 variables: { filter: JSON.stringify({ status: "enabled" }) },
//                 fetchPolicy: "cache-first",
//             };
//         },
//         props: ({ ownProps, data }) => {
//             const { loading, productCats, error, fetchMore, subscribeToMore } = data;

//             if (error) {
//                 console.log(__error("error"), error);
//                 // message.error(`${error.networkError.name} :: ${error.networkError.response.statusText} : ${error.networkError.response.status}`);
//                 // error.networkError.name
//                 // error.networkError.response.status
//                 // error.networkError.response.statusText
//                 // console.log(__error("ERROR DETAILS"), error.networkError.result.errors);

//             }

//             const loadMoreRows = (filter, others) => {

//                 let vars = { filter: filter, others: others };

//                 let updateQuery = (previousResult, { fetchMoreResult }) => {
//                     return fetchMoreResult.productCats
//                 }

//                 return fetchMore({ variables: vars, updateQuery: updateQuery, fetchPolicy: "no-cache" });
//             }

//             return { loading, productCats, queryErrors: error, subscribeToMore, loadMoreRows }
//         },
//     }),

// )(LayoutTopMenu);

export default LayoutTopMenu;