import React from 'react'

export default function AppSuspended() {
    return (<div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", flex: 1 }}>
        <div style={{ maxWidth:"700px" }}>
            <div align="center"><img src={'/logo-combined.png'} alt="BOX Online" width="200px" /></div>
            <div style={{ fontSize: "24px" }}>
                <div style={{ fontSize:"42px" }} align="center">Good News!</div>
                <div align="center">
                    We have almost resolved the problem, and we will be resuming our operation in few hours.
                    <br />
                    Sorry for inconvenience
                </div>
                {/* <div style={{ fontSize:"42px" }} align="center">We'll be back soon!</div>
                <div>Sorry for the inconvenience. We're updating our system to improve user experience.
                    If you need to you can always follow us on Facebook for updates, otherwise we'll be back up shortly!
                </div> */}
                <br />
                <div>The Box Team</div>
            </div>
        </div>
    </div>)
}
