import React from 'react'
import PropTypes from 'prop-types';
import { Row, Col, message, Alert } from 'antd'
import { connect } from "react-redux";
// import axios from 'axios';
// import { loader } from 'graphql.macro';
// import compose from 'lodash.flowright';
import moment from 'moment'
// import debounce from 'lodash/debounce';
// import { graphql } from '@apollo/client/react/hoc';
// import { graphql, withApollo } from 'react-apollo';

import { __error } from 'Common/scripts/consoleHelper';
import { Input } from 'Common/components/Form'
import { Icon, Button, Avatar, IconButton } from 'Common/components'
import AddToCartButton from 'Layout_v1/AddToCartButton'
import { couponSelect, orderNote } from '../redux/actions'
import CouponDiscountForm from './CouponDiscountForm'
import { convertTo12Hrs, mongoToDate } from 'Common/scripts/Functions'

// import "Layout_v1/styles/checkout.scss";


const Checkout = props => {
    const { cart, user, order, settings: { currency } } = props;
    const [busy, setBusy] = React.useState(false);
    // const [note, setNote] = React.useState(order.note);

    // const { currency } = settings;
    const { timeslot, address, coupon, invoice } = order;

    if (!cart || !cart.items || cart.items.length < 1) return <div>Oops! there is noting to order!</div>

    var grandTotal = cart.grandTotal;//cart.grandTotal;
    var discount_value = 0;

    const onNoteUpdate = txt => {
        props.orderNote(txt);
        // setNote(txt);
    }
    // const onNoteUpdate = debounce(_onNoteUpdate, 1000);

    const onCheckoutPress = async () => {
        // console.log("onCheckoutPress()");
        // console.log("note: ", note);
        // props.orderNote(note);

        setBusy(true);

        const result = await props.onCheckoutPress()

        setBusy(false);

        // .then(r => {
        //     console.log("==========", r)
        //     if(r) setBusy(false);
        // });
    }

    const renderAttributes = (item) => {
        if (!item.attributes) return null;
        return item.attributes.map((o, i)=>{
            if (o.value && o.show_in_store==1) return <span key={i}>{o.title} {o.value}</span>
            return null;
        })
    }

    // collect tax sub
    // let tax_total = 0;
    // cart.items.forEach(item => {
    //     tax_total += item.tax_total;
    // });
    // grandTotal = Number(grandTotal) + Number(tax_total);

    // calculate coupon discount
    if (coupon) {
        if (coupon.discount_type == 'fix') {
            discount_value = coupon.discount_value;
        }
        if (coupon.discount_type == '%') {
            discount_value = (Number(cart.totalPrice) / 100) * Number(coupon.discount_value);
        }
        // discount_value = Number(discount_value);
        grandTotal = Number(grandTotal) - Number(discount_value);
    }

    // console.log(">>>>>> order: ", order);
    let time_range_utc = JSON.parse(order.timeslot.time_range_utc);
    time_range_utc = time_range_utc.map(itm => mongoToDate(itm));

    return (<>
            <h3>Invoice</h3>

            <Row gutter={[20, 0]}>
                <Col flex="auto">
                    <table className="invoice-table">
                        <thead>
                            <tr>
                                <th colSpan={2}>Products</th>
                                <th>Qty</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={4}>Total {cart.items.length} items</td>
                            </tr>

                            {cart.items.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td className="img-holder" width="84"><Avatar src={process.env.REACT_APP_DATA_URL + "/" + item.picture_thumb} size={62} shape="square" /></td>
                                        <td>
                                            <strong>{item.title}</strong>
                                            <div>{renderAttributes(item)}</div>
                                            <span style={{ fontSize: "0.7em", textAlign: "center", display: "inline-block" }}>
                                                {/* <AddToCartButton prod={item} size="small" /> */}
                                                <div style={{ fontSize: "1.5em" }}><b>{item.qty}</b> piece(s) in your cart</div>
                                            </span>
                                        </td>
                                        <td align="center" width="100">{item.qty} x {item.price}</td>
                                        <td align="right" width="100">{currency} {item.price * item.qty}
                                            {/* <br /><div>tax_total {item.tax_total}</div> */}
                                        </td>
                                    </tr>
                                )
                            })}


                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={3} align="right">Sub Total</td>
                                <td align="right">{currency} {cart.totalPrice}</td>
                            </tr>
                        </tfoot>
                    </table>

                    {cart.savedTotal > 0 &&
                        <p style={{ color: "green", textAlign: "center", padding: "10px", fontSize: "18px" }}>You saved <b>{currency} {cart.savedTotal}</b> on this order </p>
                    }



                </Col>

                <Col flex="370px">

                    <CouponDiscountForm />

                    {/* {coupon && coupon.picture_thumb && <img src={coupon.picture_thumb} className="discount-cad-picture" />}
                    {coupon && !coupon.picture_thumb && <div className="discount-cad">
                        <div className="title">{coupon.title}</div>
                        <div className="details">{coupon.details}</div>
                        <div className="discount">
                            {coupon.discount_type == 'fix' && 'RS'}
                            {coupon.discount_value}
                            {coupon.discount_type == '%' && '%'}
                        </div>
                        <div className="controls" onClick={() => props.couponSelect(null)}><IconButton icon="times" /></div>
                    </div>} */}

                    <table className="invoice-total">
                        <tbody>
                            <tr>
                                <td>Tax</td>
                                <td>{currency} {Number(cart.totalTax).toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td>Sub Total</td>
                                <td>{currency} {Number(cart.totalPrice).toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td>Delivery Charges</td>
                                <td>+ {currency} {Number(cart.deliveryCharges).toFixed(2)}</td>
                            </tr>
                            {coupon &&
                                <tr>
                                    <td>Coupon discount
                                        ({coupon.discount_type == 'fix' && currency} {coupon.discount_value} {coupon.discount_type == '%' && '%'})
                                    </td>
                                    <td>- {currency} {Number(discount_value).toFixed(2)}</td>
                                </tr>
                            }
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>Total Payable</td>
                                <td>{currency} {Number(grandTotal).toFixed(2)}</td>
                            </tr>
                        </tfoot>
                    </table>

                    <div style={{ fontSize: "16px", border: "1px solid #EEE", margin: "10px 0", padding: "2px 8px", textAlign: "center" }}>
                        Scheduled for
                        {/* <div style={{fontWeight:"bold"}}>{moment(order.timeslot.date).format("ddd, MMM Do YYYY")} - {convertTo12Hrs(order.timeslot.start_time)} to {convertTo12Hrs(order.timeslot.end_time)}</div> */}
                        <div style={{ fontWeight: "bold" }}>{moment(order.timeslot.date).format("ddd, MMM Do YYYY")} - {time_range_utc[0].format("hh:mm A")} to {time_range_utc[1].format("hh:mm A")}</div>
                    </div>

                    <div>
                        <Input type="textarea" defaultValue={props.order.note} value={props.order.note || undefined}
                            onChange={e => onNoteUpdate(e.target.value)}
                            label="Notes" placeholder="Any additional notes you would like to attach with your order!" name="note" rows={2} />
                    </div>

                    <div style={{ padding: "20px", display: "flex", justifyContent: "space-between" }}>
                        <Button type="default" className="back-button" size="large" onClick={() => props.goBack(2)}><Icon icon="angle-left" /> Back</Button>
                        <Button onClick={onCheckoutPress} loading={busy} size="large" className="blue">Place Order <Icon icon="angle-right" /></Button>
                    </div>

                </Col>
            </Row>

    </>)
}

Checkout.propTypes = {
    onCheckoutPress: PropTypes.func.isRequired,
    // AddToCartButton: PropTypes.number,
    // prod: PropTypes.shape({
    //     _id: PropTypes.string.isRequired,
    //     title: PropTypes.string.isRequired,
    //     price: PropTypes.number.isRequired,
    //     price_was: PropTypes.number,
    //     tax_value: PropTypes.number,
    //     tax_unit: PropTypes.string,
    //     tax_amount: PropTypes.number,
    //     limit_max_qty_in_cart: PropTypes.number,
    //     picture_thumb: PropTypes.string,
    //     qty: PropTypes.number,

    //     attributes: PropTypes.arrayOf(
    //         PropTypes.shape({
    //             _id: PropTypes.string,
    //             title: PropTypes.string,
    //             show_in_store: PropTypes.number,
    //             value: PropTypes.string,
    //             displayAs: PropTypes.string,
    //         })
    //     ),
    // }).isRequired,
    // prod :PropTypes.shape({
    //         _id: PropTypes.string.isRequired,
    //         title: PropTypes.string.isRequired,
    //         price: PropTypes.number.isRequired,
    //         qty: PropTypes.number
    //     }).isRequired

    // onAddClick: PropTypes.func.isRequired,
    // onRemoveClick: PropTypes.func.isRequired,
    // prop: PropTypes.type.isRequired
    // onEditRecord: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    couponSelect: (payload) => dispatch(couponSelect(payload)),
    orderNote: (payload) => dispatch(orderNote(payload)),
})
const mapStateToProps = state => {
    return ({
        settings: state.box_storefront,
        order: state.order,
        cart: state.cart,
        user: (state.user && state.user.user) || false
    });
}
export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
