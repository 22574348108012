import React from 'react'
import PropTypes from 'prop-types';
import { message, Tooltip } from 'antd'
import { connect } from "react-redux";
// import { graphql, withApollo } from '@apollo/client/react/hoc';
import { graphql } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { Icon, Button, Loader } from 'Common/components';
import { __error } from 'Common/scripts/consoleHelper';
import { updateSettings } from 'Store/box_storefront.actions';

const ADD_TO_CART = loader('src/graphqls/cart/addCartItem.graphql');
const REMOVE_FROM_CART = loader('src/graphqls/cart/deleteCartItem.graphql');


const _AddToCartButton = props => {
    const [loading, setLoading] = React.useState(false);
    const { prod, cart } = props;
    var disabled = loading;

    // React.useEffect(() => {
    //     return function cleanup() {
    //         console.log("cleanup");
    //     };
    // });

    if (!prod) return <Button className="btn" disabled>No Product</Button>

    let cart_item = cart.items.find(o => o._id == prod._id)

    const onAddClick = async (args) => {
        // update redux only if user is not logged in 
        if (!props.user || !props.user._id) { 
            document.dispatchEvent(global.SigninPopEvent);
            // props.updateSettings({ showSigninPop: true, showRegistrationPop:false })
            // message.error("Please log in first!")
            // props.addProduct(prod);
            return;
        }
        
        setLoading(true);

        // update db records if user is logged in
        if (props.user && props.user._id) {
            let data = {
                customer_id: props.user._id,
                product_id: prod._id,
                qty: 1
                // customer_id: props.user._id,
                // product_id: prod._id,
                // qty: 1,
            }
            
            let added = await props.addCartItem(data)
                .then(r => (r) )
                .catch(err => {
                    console.log(__error("ERROR"), err);
                    message.error("Unable to complete your request at the moment");
                    return false;
                })
            
            if (added.data.addCartItem.error) {
                setLoading(false);
                message.error(added.data.addCartItem.error.message);
            } else if (!added || !added.data || !added.data.addCartItem || !added.data.addCartItem._id){
                setLoading(false);
                message.error("Unable to process your request at the moment.");
                return;
            } else {
                setLoading(false);
                // props.updateCart(added.data.addCartItem);
                // props.addProduct(added.data.addCartItem);
            }
        }

    }

    const onRemoveClick = async (args) => {
        // update redux only if user is not logged in 
        if (!props.user || !props.user._id) {
            // message.error("Please log in first!")
            props.updateSettings({ showSigninPop: true, showRegistrationPop: false })
            // props.removeProduct(prod);
            return;
        }

        setLoading(true);

        if (props.user && props.user._id) {
            let data = { customer_id: props.user._id, product_id: prod._id };
            // props.deleteCartItem(customer_id: ID!, prod_id: ID!, delete_all: String)
            let removed = await props.deleteCartItem(data)
                .then(r => (r) )
                .catch(err => {
                    console.log(__error("ERROR"), err);
                    message.error("Unable to complete your request at the moment")
                    // return { error: { message:"Unable to complete your request at the moment"}};
                })

            if (removed.data.deleteCartItem.error) {
                setLoading(false);
                message.error(removed.data.deleteCartItem.error.message);
            }
            else {
                setLoading(false);
                // props.updateCart(removed.data.deleteCartItem);
                // props.updateCart(removed.data.deleteCartItem);
            }
        }

    }

    if (!disabled && cart_item) {
        disabled = prod.available_qty <= cart_item.qty;// console.log("OUT OF STOCK");
        if (!disabled && prod.limit_max_qty_in_cart>0) disabled = (cart_item.qty >= prod.limit_max_qty_in_cart);
    }
    if (props.disabled) disabled = true;

    const hasInCart = cart_item ? true : false;
    const size = props.size || "meduim";

    if (prod.available_qty<1) return null;

    return (
        <span style={{ display: "inline-flex", ...props.style }}><Loader loading={loading} size="20"><div className="cart-button">

            {!hasInCart && 
                <div className="add-button">
                    <Button size={size} disabled={disabled} className={`btn ${props.className}`} onClick={onAddClick}>Add</Button>
                    {/* <Button size={size} className="btn" onClick={props.onAddClick}>ADD</Button> */}
                </div>
            }

            {hasInCart && 
                <div className="qty-button">
                    <Button size={size} onClick={onRemoveClick} className="btn minus" disabled={loading}><Icon className="icon" icon="minus" /></Button>
                    <div className="qty-holder">{cart_item && cart_item.qty || 0}</div>
                    <Tooltip trigger={'hover'} title={disabled ? "Sorry, you have reached maximum purchase limit of this item" : undefined}>
                        {disabled && <div style={{display:"inline-block", position:"absolute", width:"35px", height:"100%", backgroundColor:'#FF0000', opacity:0, zIndex:999, marginLeft:"-18px"}} />}
                        <Button size={size} onClick={onAddClick} disabled={disabled}
                            // disabled={loading || (prod.limit_max_qty_in_cart>0 && cart_item && (cart_item.qty >= prod.limit_max_qty_in_cart))}
                            className="btn plus"><Icon className="icon" icon="plus" /></Button>
                    </Tooltip>
                </div>
            }

        </div></Loader></span>
    )
}

_AddToCartButton.propTypes = {
    // AddToCartButton: PropTypes.number,
    disabled: PropTypes.bool,
    size: PropTypes.string,
    prod: PropTypes.shape({
        _id: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
        title: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        price_was: PropTypes.number,
        // tax_value: PropTypes.number,
        // tax_unit: PropTypes.string,
        tax_total: PropTypes.number,
        limit_max_qty_in_cart: PropTypes.number,
        picture_thumb: PropTypes.string,
        qty: PropTypes.number,

        attributes: PropTypes.arrayOf(
            PropTypes.shape({
                _id: PropTypes.string,
                title: PropTypes.string,
                show_in_store: PropTypes.number,
                value: PropTypes.string,
                displayAs: PropTypes.string,
            })
        ),


    }).isRequired,
    // prod :PropTypes.shape({
    //         _id: PropTypes.string.isRequired,
    //         title: PropTypes.string.isRequired,
    //         price: PropTypes.number.isRequired,
    //         qty: PropTypes.number
    //     }).isRequired

// onAddClick: PropTypes.func.isRequired,
    // onRemoveClick: PropTypes.func.isRequired,
    // prop: PropTypes.type.isRequired
    // onEditRecord: PropTypes.func.isRequired
}
// export default AddToCartButton;



const mapStateToProps = state => {
    return ({ cart: state.cart, user: (state.user && state.user.user) || null });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    // addProduct: (prod) => dispatch(addProduct(prod)),
    // removeProduct: (prod) => dispatch(removeProduct(prod)),
    // updateCart: (cart) => dispatch(updateCart(cart)),
    updateSettings: (payload) => dispatch(updateSettings(payload)),
})
const __AddToCartButton = connect(mapStateToProps, mapDispatchToProps)(_AddToCartButton);


const AddToCartButton = compose(

    graphql(ADD_TO_CART, {
        props: ({ mutate }) => ({
            addCartItem: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),

    graphql(REMOVE_FROM_CART, {
        props: ({ mutate }) => ({
            deleteCartItem: (args) => mutate({
                variables: { input:{...args} }
            }),
        })
    }),

)(__AddToCartButton);

export default AddToCartButton;