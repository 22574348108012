import React, { Component } from 'react'
// import { graphql, withApollo } from '@apollo/client/react/hoc';
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { message, Alert, Row, Col } from 'antd';
import { connect } from "react-redux";
import { Redirect, Link } from 'react-router-dom';
import { Button } from 'Common/components'
import { FormComponent, FormField, rules, composeValidators } from 'Common/components/Form'
import { __error } from 'Common/scripts/consoleHelper'
import { updateUser } from '../redux/actions'
import { mongoToDate, dateToMongo } from 'Common/scripts/Functions';

const SEND_REQUEST = loader('src/graphqls/user/sendPasswordResetCode.graphql');
const VERIFY_REQUEST = loader('src/graphqls/user/verifyPasswordResetCode.graphql');
const RESET_PWD = loader('src/graphqls/user/resetPassword.graphql');

const SendCodeForm = props => {
    return(<>
        <FormComponent onSubmit={props.onSubmit} id='SendCodeForm' hideDevBlock={true} style={{ width: "100%", margin: "0px" }} fields={{}}><>
            <h4>Forgot Password?</h4>
            <p>Eneter your registered email address below</p>
            <Row gutter={[12, 12]}>
                <Col flex="auto"><FormField wrapperStyle={{ margin: "0px" }} type="email" name="email" placeholder="Your email address" validate={rules.isEmail} /></Col>
                <Col><Button type="primary" htmlType="submit" theme="blue" block size="large" style={{ margin: "0" }}>Next</Button> </Col>
            </Row>
        </></FormComponent>
    </>)
}

const VerifyCodeForm = props => {
    return(<>
        <h4>Check your messages</h4>
        <p>We've sent a message to email with a 6 digit code in it.</p>
        {/* {vreificationError && <Alert showIcon type="error" message={vreificationError} description={null} />} */}

        <FormComponent onSubmit={props.onSubmit} id='VerifecationForm' hideDevBlock={true} style={{ margin: "0px" }} fields={{}}><>
            <Row gutter={[12, 12]}>
                <Col flex="auto"><FormField wrapperStyle={{ margin: "0px" }} type="text" name="code" placeholder="Enter Code" validate={rules.required} /></Col>
                <Col><Button type="primary" htmlType="submit" theme="blue" block size="large" style={{ margin: "0" }}>Next</Button> </Col>
            </Row>
        </></FormComponent>

        <p>Not got a code? <Link to="/user/forgot-password"><b>Request a new code</b></Link><br />
            Your code may take a few moments to arrive. For email, check your Junk folder. Did you use the email address you use for your Asda account?
        </p>

    </>)
}

const ResetForm = props => {
    return(<>
        <h4>Reset Password</h4>
        <p>Please enter your new password below.</p>

        <FormComponent onSubmit={props.onSubmit} id='ResetPAsswordForm' hideDevBlock={true} style={{ margin: "0px" }} fields={{}}><>
            <FormField type="password" name="pwd" placeholder="Password" validate={rules.required} />
            <FormField type="password" name="confirm_pwd" placeholder="Confirm Password" validate={composeValidators(rules.required)} />
            <p align="center">
                <Button type="primary" htmlType="submit" theme="blue" style={{width:"200px", margin:"20px", alignSelf:"center"}} size="large">Reset</Button>
            </p>
        </></FormComponent>
    </>)
}


const ForgotPassword = props => {
    // console.log("ForgotPassword()", props);
    const [response, setResponse] = React.useState(null);
    const [code, setCode] = React.useState(null);
    const [requestCompleted, setRequestCompleted] = React.useState(false);
    const step = props.match.params.step;

    const onEmailSubmit = ({ email }) => {
        // console.log("onEmailSubmit()", email);
        setResponse(null)
        // props.history.push('/user/forgot-password/verify');
        props.sendPasswordResetCode(email).then(r=>{
            if (r.data.sendPasswordResetCode.error){
                setResponse({ error: r.data.sendPasswordResetCode.error.message })
                // message.error(r.data.sendPasswordResetCode.error.message);
                return;
            }
            message.success(r.data.sendPasswordResetCode.success.message);
            props.history.push('/user/forgot-password/verify');
        }).catch(err=>{
            setResponse({ error: "Unable to process your request!" })
            // message.error("Unable to process your request!");
        })
    }

    const onVerificatonCodeSubmit = ({code}) => {
        // console.log("onVerificatonCodeSubmit()", code);
        setResponse(null)
        props.verifyPasswordResetCode(code).then(r => {
            if (r.data.verifyPasswordResetCode.error) {
                setResponse({ error: r.data.verifyPasswordResetCode.error.message })
                // message.error(r.data.verifyPasswordResetCode.error.message);
                return;
            }
            message.success(r.data.verifyPasswordResetCode.success.message);
            setCode(code);
            // props.history.push('/user/forgot-password/reset')
        }).catch(err => {
            setResponse({ error:"Unable to process your request!"})
            // message.error("Unable to process your request!");
        })
    }

    const onResetPassword = ({ pwd, confirm_pwd }) => {
        // console.log(`onResetPassword(${pwd}, ${confirm_pwd})`);
        setResponse(null)

        if (pwd!==confirm_pwd){
            // message.error("Password missmatch");
            setResponse({ error: "Password missmatch" })
            return;
        }

        props.resetPassword(pwd, code).then(r => {
            if (r.data.resetPassword.error) {
                setResponse({ error: r.data.resetPassword.error.message })
                // message.error(r.data.resetPassword.error.message);
                return;
            }
            message.success(r.data.resetPassword.success.message);
            setRequestCompleted(true);
            // props.history.push('/user/forgot-password/reset')
        }).catch(err => {
            setResponse({ error: "Unable to process your request!" })
            // message.error("Unable to process your request!");
        })
        
    }
    
    return(<Row>
        <Col flex="auto"></Col>
        <Col flex="400px">
            <div style={{ width:"100%", border: "1px solid #EEE", padding:"20px 20px 10px 20px", marginTop:"50px" }}>
                {requestCompleted && <Alert message={<h3>Password Reset Successfull</h3>} description={<>
                    <div>You can now <Link to="/user/sign-in">log in</Link> using your new password</div>
                </>} type="success" showIcon />}

                {!requestCompleted && <>
                    {response && response.error && <Alert message={response.error} type="error" showIcon />}
                    {response && response.success && <Alert message={response.success} type="success" showIcon />}
                    
                    {!step && <SendCodeForm onSubmit={onEmailSubmit} />}
                    {!code && step == 'verify' && <VerifyCodeForm onSubmit={onVerificatonCodeSubmit} />}
                    {code && <ResetForm onSubmit={onResetPassword} />}
                </>}
            </div>
        </Col>
        <Col flex="auto"></Col>
    </Row>)
}

const WithApollo = compose(
    
    graphql(SEND_REQUEST, {
        props: ({ mutate }) => ({
            sendPasswordResetCode: (email) => mutate({
                variables: { email }
            }),
        })
    }),

    graphql(VERIFY_REQUEST, {
        props: ({ mutate }) => ({
            verifyPasswordResetCode: (code) => mutate({
                variables: { code }
            }),
        })
    }),

    graphql(RESET_PWD, {
        props: ({ mutate }) => ({
            resetPassword: (pwd, code) => mutate({
                variables: { pwd, code }
            }),
        })
    }),

)(ForgotPassword);
// const WithApollo = withApollo(PhoneVerification)

const mapStateToAvatarPopProps = state => {
    return ({ user: state.user ? state.user.user : false });
}
const mapAvatarPopDispatchToProps = (dispatch, ownProps) => ({
    updateUser: (payload) => dispatch(updateUser(payload)),
})
export default connect(mapStateToAvatarPopProps, mapAvatarPopDispatchToProps)(WithApollo);
// export default WithApollo;
